import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Divider, Icon, List, Message, Segment, Step } from 'semantic-ui-react'


import { INSURED_FORM_ID, NEW_RECORD_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { areFilesEqual } from 'assets/utils/common'
import InsuredForm from 'components/InsuredForm/InsuredForm'
import RecordForm from 'components/RecordForm/RecordForm'
import FileSelector from 'components/UI/FileSelector/FileSelector'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import SelectedFiles from 'components/UI/SelectedFiles/SelectedFiles'
import businessService from 'services/business'
import recordsService from 'services/records'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import styles from './NewRecord.module.scss'


const NewRecord = () => {
    const [activeStep, setActiveStep] = useState(1)
    const [recordData, setRecordData] = useState({})
    const [files, setFiles] = useState(null)
    const [createdRecord, setCreatedRecord] = useState(null)
    const [recordFormErrors, setRecordFormErrors] = useState(null)
    const [insuredFormErrors, setInsuredFormErrors] = useState(null)
    const {
        closeDialog,
        openBasicMessageDialog,
        openWarningQuestionDialog,
        openErrorDialog,
        openSuccessDialog,
        setConfirming,
        refreshData,
    } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const navigate = useNavigate()
    const recordDataRef = useRef()
    recordDataRef.current = recordData

    const steps = [
        { number: 1, icon: 'file alternate', title: 'Formulario', description: 'Completa el formulario con los datos del expediente' },
        { number: 2, icon: 'upload', title: 'Subida de archivos', description: 'Sube documentos relevantes para el expediente' },
    ]

    const handleCancelButtonClick = () => {
        openWarningQuestionDialog({
            title: 'Cancelar alta',
            content: `¿Seguro que quieres cancelar el alta del nuevo expediente?
                      Todos los datos introducidos se perderán.`,
            size: 'mini',
            onConfirm: () => {
                navigate(paths.RECORDS)
                closeDialog()
            }
        })
    }

    const showUnregisteredInsuredWarning = (taxId) => {
        openWarningQuestionDialog({
            title: 'Asegurado no registrado',
            content: (
                <>
                    <p>
                        No se ha encontrado información en la Base de Datos sobre
                        el tomador/asegurado con el NIF/CIF indicado (<strong><i>{taxId}</i></strong>).
                    </p>
                    <p>
                        Para continuar con el alta del expediente es necesario indicar
                        algunos datos adicionales del mismo. ¿Quieres continuar?
                    </p>
                </>
            ),
            size: 'tiny',
            onConfirm: () => {
                closeDialog()
                showInsuredForm(taxId)
            }
        })
    }

    const handleInsuredCreation = (formData) => {
        setConfirming(true)

        businessService.createInsured(formData)
            .then(() => {
                setInsuredFormErrors(null)
                closeDialog()

                const updatedRecordData = {
                    ...recordDataRef.current,
                    insured: formData.tax_id,
                }
                
                setRecordData(updatedRecordData)
                handleRecordCreation(updatedRecordData)
            })
            .catch((error) => {
                if (error.response) {
                    setInsuredFormErrors(error.response.data)
                }
            })
            .finally(() => setConfirming(false))
    }

    const showInsuredForm = (taxId) => {
        openBasicMessageDialog({
            title: 'Datos de asegurado',
            render: InsuredForm,
            renderProps: {
                initialValues: { tax_id: taxId },
                onSubmit: handleInsuredCreation,
                errors: insuredFormErrors,
            },
            confirmSubmitsForm: INSURED_FORM_ID,
            onDeny: () => {
                setInsuredFormErrors(null)
                closeDialog()
            }
        })
    }

    const handleRecordCreation = (formData) => {
        showLoader('Creando expediente...')
        setRecordFormErrors(null)
        
        recordsService.createRecord(formData)
            .then((res) => {
                hideLoader()
                setCreatedRecord(res.data)
                setActiveStep(2)
                openSuccessDialog({
                    title: 'Expediente creado',
                    content: (
                        <>
                            <p>
                                El expediente ha sido creado correctamente; su número de
                                registro es el
                                <strong className={styles.RecordNumber}>
                                    <i>{res.data.number}</i>
                                </strong>.
                            </p>
                            <p>
                                Ahora podrás, por último, subir los documentos relevantes
                                para completar la información.
                            </p>
                        </>
                    ),
                    size: 'tiny',
                })
            })
            .catch((error) => {
                hideLoader()

                if (error.response) {
                    setRecordFormErrors(error.response.data)
                }
            })
    }

    const handleFormSubmit = (formData) => {
        setRecordData(formData)

        const { insured } = formData

        if (insured && insured.trim()) {
            showLoader('Comprobando datos...')

            businessService.getInsuredExists(insured)
                .then((res) => {
                    hideLoader()

                    if (res.data?.exists) {
                        handleRecordCreation(formData)
                    } else {
                        showUnregisteredInsuredWarning(insured)
                    }
                })
                .catch((error) => {
                    hideLoader()

                    let errorMessage = error?.response?.data?.error

                    if (!errorMessage) {
                        errorMessage = `Ha tenido lugar un error al comprobar los datos.
                                        Por favor, inténtalo de nuevo más tarde o
                                        contacta con el administrador de la plataforma.`
                    }

                    openErrorDialog({
                        title: 'Error al comprobar datos',
                        content: errorMessage,
                        size: 'tiny',
                        onConfirm: () => {
                            navigate(paths.RECORDS)
                            closeDialog()
                        }
                    })
                })
        } else {
            handleRecordCreation(formData)
        }
    }

    const handleFileDeletion = (file) => {
        const newFiles = files?.filter((oldFile) => !areFilesEqual(oldFile, file))
        setFiles(newFiles)
    }

    const handleFilesUpload = () => {
        if (!createdRecord?.id) {
            return
        }

        showLoader('Subiendo archivos...')

        const formData = new FormData()
        files?.forEach((file) => (
            formData.append('files', file, file.name)
        ))

        recordsService.uploadRecordFiles(createdRecord.id, formData)
            .then((res) => {
                hideLoader()
                openSuccessDialog({
                    title: 'Subida de archivos correcta',
                    content: 'La subida de archivos se ha completado correctamente.',
                    size: 'mini',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
            .catch((error) => {
                hideLoader()

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al subir los archivos.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al subir archivos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
    }

    useEffect(() => {
        refreshData({ errors: insuredFormErrors })
    }, [insuredFormErrors])

    return (
        <>
            <Helmet>
                <title>Nuevo expediente - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='plus square outline'
                header='Nuevo expediente'
                subheader='Completa los campos del siguiente formulario'
            />
            <article className={styles.Content}>
                <Step.Group attached='top' widths={2} className={styles.CreationSteps}>
                    {steps.map(({ number, icon, title, description }) => (
                        <Step 
                            key={number}
                            active={activeStep === number}
                            disabled={activeStep !== number}
                            className={styles.CreationStep}
                        >
                            <Icon name={icon} />
                            <Step.Content>
                                <Step.Title className={styles.CreationStepTitle}>{title}</Step.Title>
                                <Step.Description>{description}</Step.Description>
                            </Step.Content>
                        </Step>
                    ))}
                </Step.Group>
                <Segment attached='bottom' className={styles.CreationSegment}>
                    {
                        (activeStep === 1) &&
                        <>
                            <RecordForm
                                onSubmit={handleFormSubmit}
                                initialValues={recordData}
                                errors={recordFormErrors}
                            />
                            <div className={styles.Actions}>
                                <Divider />
                                <div className={styles.ButtonsWrapper}>
                                    <SecondaryButton onClick={handleCancelButtonClick}>
                                        Cancelar
                                    </SecondaryButton>
                                    <PrimaryButton type='submit' form={NEW_RECORD_FORM_ID}>
                                        Crear expediente
                                    </PrimaryButton>
                                </div>
                            </div>
                        </>
                    }
                    {
                        (activeStep === 2) &&
                        <>
                            {
                                (createdRecord?.type?.document_types?.length > 0) &&
                                <Message
                                    info
                                    header='Documentos solicitados para este expediente'
                                    icon={<Icon name='info circle' className={styles.MessageIcon} />}
                                    size='large'
                                    content={
                                        <List bulleted className={styles.RequestedDocumentsList}>
                                            {createdRecord?.type?.document_types?.map(({ name }) => (
                                                <List.Item key={name}>{name}</List.Item>
                                            ))}
                                        </List>
                                    }
                                />
                            }
                            {
                                !!files?.length &&
                                <SelectedFiles
                                    className={styles.SelectedFiles}
                                    files={files}
                                    onFileDelete={handleFileDeletion}
                                />
                            }
                            <FileSelector
                                className={styles.FileSelector}
                                initialFiles={files}
                                onChange={(files) => setFiles(files)}
                            />
                            <div className={styles.Actions}>
                                <Divider />
                                <div className={styles.ButtonsWrapper}>
                                    <SecondaryButton onClick={() => navigate(paths.RECORDS)}>
                                        Omitir
                                    </SecondaryButton>
                                    <PrimaryButton disabled={!files?.length} onClick={handleFilesUpload}>
                                        Subir archivos
                                    </PrimaryButton>
                                </div>
                            </div>
                        </>
                    }
                </Segment>
            </article>
        </>
    )
}

export default NewRecord
