import React, { createContext, useReducer } from 'react'

import { AuthReducer } from './reducer'


const accessToken = JSON.parse(localStorage.getItem('CenterIurisAccessToken'))

const initialState = {
    user: null,
    accessToken: accessToken || null,
    tokenRefreshTimeoutId: null,
    logoutTimeoutId: null,
    loginLoading: false,
    loginFailed: false
}

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const [authData, dispatch] = useReducer(AuthReducer, initialState)
    const value = { authData, dispatch }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export {
    AuthContext,
    AuthProvider
}