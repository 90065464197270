import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react'

import { paths } from 'assets/constants/navigation'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import authService from 'services/auth'
import styles from './NewPasswordPanel.module.scss'


const NewPasswordPanel = (props) => {
    const [isKeyChecked, setKeyChecked] = useState(false)
    const [isKeyCorrect, setKeyCorrect] = useState(false)
    const [isVisiblePassword, setVisiblePassword] = useState(false)
    const [isVisibleRepeatPassword, setVisibleRepeatPassword] = useState(false)
    const [isResetComplete, setResetComplete] = useState(false)
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { resetKey } = useParams()

    const handleSubmit = event => {
        event.preventDefault()

        setLoading(true)

        authService.resetPassword(resetKey, formData)
            .then(res => {
                setResetComplete(true)
            })
            .catch(error => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        authService.checkResetKey(resetKey)
            .then(res => setKeyCorrect(true))
            .catch(() => setKeyCorrect(false))
            .finally(() => setKeyChecked(true))
    }, [])

    return (
        <>
            {
                isKeyChecked &&
                <Segment className={styles.NewPasswordPanel}>
                    {
                        !isKeyCorrect &&
                        <Message
                            error
                            icon='times circle'
                            className={styles.Message}
                            header='Clave incorrecta'
                            content='La clave proporcionada por correo electrónico para
                                     llevar a cabo la recuperación de contraseña ya ha
                                     caducado o no existe. Por favor, vuelve a realizar
                                     la solicitud.'
                        />
                    }
                    {
                        (isKeyCorrect && isResetComplete) &&
                        <Message
                            positive
                            icon='check'
                            className={styles.Message}
                            header='Contraseña restaurada'
                            content='La contraseña ha sido restaurada correctamente.
                                     Inicia sesión con la nueva contraseña para poder
                                     acceder nuevamente a la plataforma.'
                        />
                    }
                    {
                        (isKeyCorrect && !isResetComplete) &&
                        <Form
                            id='new-password-form'
                            className={styles.NewPasswordForm}
                            onSubmit={handleSubmit}
                            error={!!formErrors}
                        >
                            <Header dividing size='medium' className={styles.Header}>
                                Restablecer contraseña
                            </Header>
                            <Message
                                info
                                icon='info circle'
                                content='Por favor, introduce una nueva contraseña.'
                            />
                            <Form.Input
                                error={!!formErrors?.new_password || !!formErrors?.non_field_errors}
                                label='Nueva contraseña'
                                placeholder='Nueva contraseña'
                                icon='lock'
                                iconPosition='left'
                                name='new_password'
                                type={isVisiblePassword ? 'text' : 'password'}
                                action={{
                                    icon: 'eye',
                                    className: styles.ShowPasswordButton,
                                    type: 'button',
                                    onClick: () => setVisiblePassword(!isVisiblePassword)
                                }}
                                onChange={handleChange}
                            />
                            <Form.Input
                                error={!!formErrors?.repeat_password || !!formErrors?.non_field_errors}
                                label='Repetir contraseña'
                                placeholder='Repetir contraseña'
                                icon='lock'
                                iconPosition='left'
                                name='repeat_password'
                                type={isVisibleRepeatPassword ? 'text' : 'password'}
                                action={{
                                    icon: 'eye',
                                    className: styles.ShowPasswordButton,
                                    type: 'button',
                                    onClick: () => setVisibleRepeatPassword(!isVisibleRepeatPassword)
                                }}
                                onChange={handleChange}
                            />
                            {
                                !!formErrors &&
                                <Message error>
                                    <Message.List>
                                    {
                                        Object.entries(formErrors).map(
                                            ([key, value], index) => (
                                                <Message.Item key={index}>
                                                    {value[0]}
                                                </Message.Item>
                                            )
                                        )
                                    }
                                    </Message.List>
                                </Message>
                            }
                        </Form>
                    }
                    <Divider/>
                    {
                        (isResetComplete || !isKeyCorrect) &&
                        <SecondaryButton fluid className={styles.Actions} onClick={() => navigate(paths.LOGIN)}>
                            Aceptar
                        </SecondaryButton>
                    }
                    {
                        (!isResetComplete && isKeyCorrect) &&
                        <Button.Group fluid className={styles.Actions} disabled={isLoading}>
                            <PrimaryButton onClick={() => navigate(paths.LOGIN)}>
                              Cancelar
                            </PrimaryButton>
                            <SecondaryButton form='new-password-form' type='submit' loading={isLoading} disabled={isLoading}>
                              Cambiar contraseña
                            </SecondaryButton>
                        </Button.Group>
                    }
                </Segment>
            }
        </>
    )
}

export default NewPasswordPanel