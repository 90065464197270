const LoaderActions = {
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER'
}

const LoaderReducer = (initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case LoaderActions.SHOW_LOADER:
            return {
                ...initialState,
                ...payload,
                visible: true
            }
        case LoaderActions.HIDE_LOADER:
            return {
                ...initialState,
                text: null,
                indeterminate: false,
                visible: false
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }
}


export {
    LoaderActions,
    LoaderReducer
}