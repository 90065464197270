import React from 'react'

import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { AuthProvider } from 'contexts/Auth'
import { DialogProvider } from 'contexts/Dialog'
import { LoaderProvider } from 'contexts/Loader'
import { NotificationsProvider } from 'contexts/Notifications'
import App from './App'

// TODO: Uncomment next line when Semantic UI CSS is fixed:

// import 'semantic-ui-css/semantic.min.css'
import './index.scss'

ReactDOM.render(
    <Router>
        <React.StrictMode>
            <HelmetProvider>
                <AuthProvider>
                    <NotificationsProvider>
                        <LoaderProvider>
                            <DialogProvider>
                                <App />
                            </DialogProvider>
                        </LoaderProvider>
                    </NotificationsProvider>
                </AuthProvider>
            </HelmetProvider>
        </React.StrictMode>
    </Router>,
    document.getElementById('root')
)
