import { useReducer } from 'react'


const usePagination = () => {
    const initialState = {
        currentPage: null,
        lastPage: null,
        nextPage: null,
        previousPage: null,
        results: null,
        total: null,
    }

    const parseState = (state) => {
        const parsedState = {}
        const keyMap = {
            current_page: 'currentPage',
            last_page: 'lastPage',
            next_page: 'nextPage',
            previous_page: 'previousPage',
        }

        for (const key in state) {
            const newKey = keyMap.hasOwnProperty(key) ? keyMap[key] : key
            const value = state[key]
            parsedState[newKey] = value
        }

        if ((parsedState.currentPage > 1) && (parsedState.currentPage === parsedState.lastPage)) {
            parsedState.currentPage = 'last'
        }

        return parsedState
    }

    const mergeData = (oldState, newState) => (
        {
            ...oldState,
            ...parseState(newState)
        }
    )

    return useReducer(mergeData, initialState)
}

export default usePagination