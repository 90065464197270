import React, { useEffect, useState } from 'react'

import { Button, Form, Image, Message, Segment } from 'semantic-ui-react'

import { CENTERIURIS_LOGO_URL } from 'assets/constants/images'
import { loginPanels } from 'assets/constants/login'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import useAuth from 'hooks/useAuth'
import styles from './LoginPanel.module.scss'


const LoginPanel = (props) => {
    const { showPanel } = props
    const { ACCESS_REQUEST, RESET_PASSWORD } = loginPanels

    const [isVisiblePassword, setVisiblePassword] = useState(false)
    const [formData, setFormData] = useState({})
    const { login, loginFailed, loginLoading } = useAuth()

    const handleSubmit = event => {
        event.preventDefault()
        login(formData)
    }

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Segment className={styles.LoginPanel}>
            <header className={styles.Header}>
                <Image src={CENTERIURIS_LOGO_URL} className={styles.Logo} />
            </header>
            <div className={styles.MainContent}>
                <Form id='login-form' className={styles.LoginForm} onSubmit={handleSubmit} error={loginFailed}>
                    <Form.Input
                        error={loginFailed}
                        label='Correo electrónico'
                        placeholder='Correo electrónico'
                        icon='envelope'
                        iconPosition='left'
                        name='username'
                        onChange={handleChange}
                    />
                    <Form.Input
                        error={loginFailed}
                        label='Contraseña'
                        placeholder='Contraseña'
                        icon='lock'
                        iconPosition='left'
                        name='password'
                        type={isVisiblePassword ? 'text' : 'password'}
                        action={{
                            icon: 'eye',
                            type: 'button',
                            className: styles.ShowPasswordButton,
                            onClick: () => setVisiblePassword(!isVisiblePassword)
                        }}
                        onChange={handleChange}
                    />
                    {
                        loginFailed &&
                        <Message error content='Las credenciales no son correctas' />
                    }
                </Form>
                <strong
                    className={styles.ForgotPasswordText}
                    onClick={() => showPanel?.(RESET_PASSWORD)}
                >
                    ¿Has olvidado la contraseña?
                </strong>
                <Button.Group className={styles.Buttons}>
                    <SecondaryButton
                        className={styles.Button}
                        onClick={() => showPanel?.(ACCESS_REQUEST)}
                        disabled={loginLoading}
                    >
                        Solicitar acceso
                    </SecondaryButton>
                    <PrimaryButton
                        form='login-form'
                        type='submit'
                        className={styles.Button}
                        disabled={loginLoading}
                        loading={loginLoading}
                    >
                        Entrar
                    </PrimaryButton>
                </Button.Group>
                <div className={styles.Copyright}>
                    CenterBrok {new Date().getFullYear()} – todos los derechos reservados
                </div>
            </div>
        </Segment>
    )
}

export default LoginPanel