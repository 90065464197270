import { useContext, useEffect } from 'react'

import { dialogTypes, dialogStates } from 'assets/constants/dialog'
import { DialogActions, DialogContext } from 'contexts/Dialog'


const useDialog = () => {
    const context = useContext(DialogContext)

    const { INFO, WARNING, ERROR, SUCCESS } = dialogStates
    const { OK, OK_CANCEL } = dialogTypes

    if (context === undefined) {
        throw new Error('useDialog must be used within a DialogProvider')
    }

    const { dialogConfig, dispatch } = context

    const fillMissingConfig = (config) => {
        const finalConfig = {...config}

        for (let key in dialogConfig) {
            if (!finalConfig.hasOwnProperty(key)) {
                finalConfig[key] = null
            }
        }

        return finalConfig
    }

    const openWarningDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK, state: WARNING }
        })
    }

    const openWarningQuestionDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK_CANCEL, state: WARNING }
        })
    }

    const openErrorDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK, state: ERROR }
        })
    }

    const openInfoDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK, state: INFO }
        })
    }

    const openInfoQuestionDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK_CANCEL, state: INFO }
        })
    }

    const openSuccessDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK, state: SUCCESS }
        })
    }

    const openBasicDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK }
        })
    }

    const openBasicMessageDialog = (config) => {
        const newConfig = fillMissingConfig(config)
        dispatch({
            type: DialogActions.OPEN_DIALOG,
            payload: { ...newConfig, type: OK_CANCEL }
        })
    }

    const setConfirming = (isConfirming) => {
        dispatch({
            type: DialogActions.SET_CONFIRMING,
            payload: { isConfirming }
        })
    }

    const refreshContent = (content) => {
        dispatch({
            type: DialogActions.REFRESH_CONTENT,
            payload: { content }
        })
    }

    const refreshData = (data) => {
        dispatch({
            type: DialogActions.REFRESH_DATA,
            payload: { data }
        })
    }

    const closeDialog = () => dispatch({ type: DialogActions.CLOSE_DIALOG })

    return {
        openWarningDialog,
        openWarningQuestionDialog,
        openErrorDialog,
        openInfoDialog,
        openInfoQuestionDialog,
        openSuccessDialog,
        openBasicDialog,
        openBasicMessageDialog,
        setConfirming,
        refreshContent,
        refreshData,
        closeDialog
    }
}

export default useDialog