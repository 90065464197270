import React from 'react'

import { useNavigate } from 'react-router-dom'

import styles from './LinkCard.module.scss'


const LinkCard = (props) => {
    const { className, link, onClick, text } = props

    const navigate = useNavigate()

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick()
        } else if (link) {
            navigate(link)
        }
    }

    return (
        <div
            className={`${styles.LinkCard}${className ? ` ${className}` : ''}`}
            onClick={handleClick}
        >
            {text}
        </div>
    )
}

export default LinkCard