const eventCodes = {
    ACCESS_REQUEST_CREATED: 'ACCESS_REQUEST_CREATED',
    ACCESS_REQUEST_APPROVED: 'ACCESS_REQUEST_APPROVED',
    ACCESS_REQUEST_REJECTED: 'ACCESS_REQUEST_REJECTED',
    INSURANCE_CREATED: 'INSURANCE_CREATED',
    INSURANCE_DELETED: 'INSURANCE_DELETED',
    INSURANCE_UPDATED: 'INSURANCE_UPDATED',
    INSURED_CREATED: 'INSURED_CREATED',
    INSURED_DELETED: 'INSURED_DELETED',
    INSURED_UPDATED: 'INSURED_UPDATED',
    NOTIFICATION_READ: 'NOTIFICATION_READ',
    NOTIFICATION_DELETED: 'NOTIFICATION_DELETED',
    PARTNER_CREATED: 'PARTNER_CREATED',
    PARTNER_DELETED: 'PARTNER_DELETED',
    PARTNER_UPDATED: 'PARTNER_UPDATED',
    USER_UPDATED: 'USER_UPDATED',
    USER_DELETED: 'USER_DELETED',
    USER_PERMISSIONS_UPDATED: 'USER_PERMISSIONS_UPDATED',
    RECORD_TYPE_CREATED: 'RECORD_TYPE_CREATED',
    RECORD_TYPE_DELETED: 'RECORD_TYPE_DELETED',
    RECORD_TYPE_UPDATED: 'RECORD_TYPE_UPDATED',
    RECORD_CREATED: 'RECORD_CREATED',
    RECORD_DELETED: 'RECORD_DELETED',
    RECORD_UPDATED: 'RECORD_UPDATED',
    RECORD_MESSAGE_UPDATED: 'RECORD_MESSAGE_UPDATED',
    RECORD_MESSAGE_DELETED: 'RECORD_MESSAGE_DELETED',
    BROKER_MESSAGE_CREATED: 'BROKER_MESSAGE_CREATED',
    CONSULTANT_MESSAGE_CREATED: 'CONSULTANT_MESSAGE_CREATED',
    ANNOTATION_CREATED: 'ANNOTATION_CREATED',
    NEW_BROKER_UPLOAD: 'NEW_BROKER_UPLOAD',
    NEW_CONSULTANT_UPLOAD: 'NEW_CONSULTANT_UPLOAD',
    CALENDAR_EVENT_CREATED: 'CALENDAR_EVENT_CREATED',
    CALENDAR_EVENT_UPDATED: 'CALENDAR_EVENT_UPDATED',
    CALENDAR_EVENT_DELETED: 'CALENDAR_EVENT_DELETED',
}

const placeholderCodes = {
    NAME: 'name',
    SURNAME: 'surname',
    EMAIL: 'email',
    NUMBER: 'number',
    PARTNER: 'partner',
    SENDER: 'sender',
    FILES: 'files',
}

const messagePlaceholders = {
    [eventCodes.ACCESS_REQUEST_CREATED]: [
        placeholderCodes.NAME,
        placeholderCodes.SURNAME,
        placeholderCodes.EMAIL,
    ],
    [eventCodes.RECORD_CREATED]: [
        placeholderCodes.NUMBER,
        placeholderCodes.PARTNER,
    ],
    [eventCodes.BROKER_MESSAGE_CREATED]: [
        placeholderCodes.SENDER,
        placeholderCodes.NUMBER,
    ],
    [eventCodes.CONSULTANT_MESSAGE_CREATED]: [
        placeholderCodes.SENDER,
        placeholderCodes.NUMBER,
    ],
    [eventCodes.NEW_BROKER_UPLOAD]: [
        placeholderCodes.SENDER,
        placeholderCodes.FILES,
        [
            placeholderCodes.FILES,
            (files) => (files?.length === 1) ? 'documento' : 'documentos'
        ],
        placeholderCodes.NUMBER,
    ],
    [eventCodes.NEW_CONSULTANT_UPLOAD]: [
        placeholderCodes.SENDER,
        placeholderCodes.FILES,
        [
            placeholderCodes.FILES,
            (files) => (files === 1) ? 'documento' : 'documentos'
        ],
        placeholderCodes.NUMBER,
    ],
}

const pushMessages = {
    [eventCodes.ACCESS_REQUEST_CREATED]: 'Nueva solicitud de acceso de {0} {1} ({2})',
    [eventCodes.RECORD_CREATED]: 'Nuevo expediente ({0}) del asociado {1}',
    [eventCodes.BROKER_MESSAGE_CREATED]: 'Nuevo mensaje de {0} en el expediente {1}',
    [eventCodes.CONSULTANT_MESSAGE_CREATED]: 'Nuevo mensaje de {0} en el expediente {1}',
    [eventCodes.NEW_BROKER_UPLOAD]: '{0} ha adjuntado {1} {2} en el expediente {3}',
    [eventCodes.NEW_CONSULTANT_UPLOAD]: '{0} ha adjuntado {1} {2} en el expediente {3}',

    build(code, data) {
        const placeholders = messagePlaceholders[code] || []
        let message = this[code] || ''

        placeholders.forEach((placeholder, index) => {
            let placeholderContent

            if (Array.isArray(placeholder)) {
                const [auxPlaceholder, contentBuilder] = placeholder
                placeholderContent = contentBuilder(data?.[auxPlaceholder])
            } else {
                placeholderContent = data?.[placeholder]
            }
            message = message.replace(`{${index}}`, placeholderContent || '')
        })

        return message
    }
}

export {
    eventCodes,
    placeholderCodes,
    pushMessages,
    messagePlaceholders,
}
