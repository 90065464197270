import React from 'react'

import { Helmet } from 'react-helmet-async'

import { ERROR_IMAGE_URL } from 'assets/constants/images'
import { paths } from 'assets/constants/navigation'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import styles from './NotFound.module.scss'


const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>No encontrado - CenterIuris</title>
            </Helmet>
            <article className={styles.Content}>
                <img src={ERROR_IMAGE_URL} alt='Error'/>
                <div>
                    <h1>¡Vaya!</h1>
                    <h2><i>Parece que la página que buscas no existe</i></h2>
                    <PrimaryButton
                        size='massive'
                        className={styles.GoHomeButton}
                        onClick={() => (
                            // Changing window location is needed to recreate
                            // WebSocket connection
                            window.location.href = paths.HOME
                        )}
                    >
                        Volver al Inicio
                    </PrimaryButton>
                </div>
            </article>
        </>
    )
}

export default NotFound