const DialogActions = {
    OPEN_DIALOG: 'OPEN_DIALOG',
    SET_CONFIRMING: 'SET_CONFIRMING',
    REFRESH_CONTENT: 'REFRESH_CONTENT',
    REFRESH_DATA: 'REFRESH_DATA',
    CLOSE_DIALOG: 'CLOSE_DIALOG',
}

const DialogReducer = (initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case DialogActions.OPEN_DIALOG:
            return {
                ...initialState,
                ...payload,
                visible: true
            }
        case DialogActions.SET_CONFIRMING:
            return {
                ...initialState,
                isConfirming: payload.isConfirming
            }
        case DialogActions.REFRESH_CONTENT:
            return {
                ...initialState,
                content: payload.content
            }
        case DialogActions.REFRESH_DATA:
            return {
                ...initialState,
                renderProps: {
                    ...initialState.renderProps,
                    ...payload.data
                }
            }
        case DialogActions.CLOSE_DIALOG:
            return {
                ...initialState,
                type: null,
                state: null,
                scrolling: null,
                size: null,
                title: null,
                content: null,
                render: null,
                renderProps: null,
                onConfirm: null,
                onDeny: null,
                closable: false,
                visible: false,
                isConfirming: false,
                secondaryActions: null,
                confirmSubmitsForm: null,
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }
}


export {
    DialogActions,
    DialogReducer
}