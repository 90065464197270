import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import PreviewCards from 'components/UI/PreviewCards/PreviewCards'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import accountsService from 'services/accounts'
import styles from './UsersPerPartner.module.scss'


const UsersPerPartner = () => {
    const [items, setItems] = useState(null)
    const { openErrorDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const { receivedEvent, eventNumber } = useNotifications()
    const navigate = useNavigate()

    const fetchUsersPerBrokerage = (params) => {
        const { avoidLoader } = params || {}
        
        !avoidLoader && showLoader('Cargando datos...')

        accountsService.getUsersPerPartner()
            .then((res) => {
                const partners = res.data?.map(({ id, name, users_amount }) => ({
                    title: name,
                    content: `${users_amount} usuario${(users_amount !== 1) ? 's' : ''}`,
                    link: paths.PARTNER_USERS.replace(':partnerId', id)
                }))
                
                setItems(partners)

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los datos.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.ACCOUNTS_MANAGEMENT)
                        closeDialog()
                    }
                })
            })
    }

    useEffect(() => {
        fetchUsersPerBrokerage()
    }, [])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.ACCESS_REQUEST_APPROVED,
            eventCodes.USER_UPDATED,
            eventCodes.USER_DELETED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchUsersPerBrokerage({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Usuarios por asociado - CenterIuris</title>
            </Helmet>
            <PageTitle
                hasWideIcon
                icon='sitemap'
                header='Vista previa de usuarios por asociado'
            />
            {
                items &&
                <article className={styles.Content}>
                    <PreviewCards
                        searchPlaceholder='Buscar nombre de asociado'
                        className={styles.PreviewCards}
                        itemsPerRow={4}
                        items={items}
                    />
                </article>
            }
        </>
    )
}

export default UsersPerPartner 