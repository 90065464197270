import { useContext, useEffect } from 'react'

import { LoaderActions, LoaderContext } from 'contexts/Loader'


const useLoader = () => {
    const context = useContext(LoaderContext)

    if (context === undefined) {
        throw new Error('useLoader must be used within a LoaderProvider')
    }

    const { loaderConfig, dispatch } = context

    const showLoader = (text, indeterminate) => {
        const payload = { text, indeterminate }

        dispatch({ type: LoaderActions.SHOW_LOADER, payload })
    }

    const hideLoader = () => {
        dispatch({ type: LoaderActions.HIDE_LOADER })
    }

    return {
        showLoader,
        hideLoader
    }
}

export default useLoader