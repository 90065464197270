import React from 'react'

import { List } from 'semantic-ui-react'

import styles from './LastUpdateData.module.scss'


const LastUpdateData = (props) => {
    const { className, date, author } = props

    return (
        <div className={`${styles.LastUpdateData}${className ? ` ${className}` : ''}`}>
            <div className={styles.Title}>
                Última actualización
            </div>
            <div className={styles.Data}>
                <List>
                    <List.Item>
                        <List.Icon name='calendar alternate outline' />
                        <List.Content>
                            <i>{date}</i>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='user outline' />
                        <List.Content>
                            <i>{author}</i>
                        </List.Content>
                    </List.Item>
                </List>
            </div>
        </div>
    )
}

export default LastUpdateData