import React, { useEffect, useState } from 'react'

import { Divider, Form, Message } from 'semantic-ui-react'

import { datetimeToStr } from 'assets/utils/common'
import LastUpdateData from 'components/UI/LastUpdateData/LastUpdateData'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import recordsService from 'services/records'
import styles from './RecordDescriptionPane.module.scss'


const RecordDescriptionPane = (props) => {
    const { record, onDataUpdate } = props
    const { id, description } = record
    const { text, updated_at, updated_by } = description

    const { openErrorDialog, openSuccessDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [formData, setFormData] = useState(null)
    const [formErrors, setFormErrors] = useState(null)

    const handleFormSubmit = () => {
        showLoader('Actualizando descripción...')
        setFormErrors(null)
        
        recordsService.updateRecordDescription(id, formData)
            .then((res) => {
                hideLoader()
                onDataUpdate(res.data)
                openSuccessDialog({
                    title: 'Descripción actualizada',
                    content: 'La descripción del expediente ha sido actualizada correctamente.',
                    size: 'mini',
                })
            })
            .catch((error) => {
                hideLoader()

                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
    }

    useEffect(() => {
        setFormData({ description: text })
    }, [description])

    return (
        <div className={styles.RecordDescriptionPane}>
            <LastUpdateData
                date={datetimeToStr(updated_at)}
                author={`${updated_by.first_name} ${updated_by.last_name}`}
            />
            <Divider hidden />
            <Form error={!!formErrors} onSubmit={handleFormSubmit} >
                <Form.TextArea
                    required
                    error={!!formErrors?.description}
                    label='Descripción del expediente'
                    placeholder='Descripción del expediente'
                    value={formData?.description || ''}
                    rows={10}
                    onChange={(e, { value }) => setFormData({ description: value })}
                />
                {
                    formErrors &&
                    <Message error>
                        <Message.List>
                        {
                            Object.entries(formErrors).map(([key, value], index) => (
                                <Message.Item key={index}>
                                    {Array.isArray(value) ? value[0] : value}
                                </Message.Item>
                            ))
                        }
                        </Message.List>
                    </Message>
                }
                <div className={styles.Actions}>
                    <Divider />
                    <div className={styles.ButtonsWrapper}>
                        <PrimaryButton compact type='submit'>
                            Actualizar descripción
                        </PrimaryButton>
                    </div>
                </div>
            </Form>
        </div>
        
    )
}

export default RecordDescriptionPane