import React, { useEffect, useState } from 'react'

import { Checkbox, Form, Header, Icon, Message } from 'semantic-ui-react'

import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import authService from 'services/auth'
import styles from './UserPermissionsPanel.module.scss'


const UserPermissionsPanel = (props) => {
    const { className, user } = props

    const { openErrorDialog, openSuccessDialog } = useDialog()
    const [isChief, setIsChief] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()

        setLoading(true)

        authService.updateUserPermissions(user.id, { is_chief: isChief })
            .then(res => {
                openSuccessDialog({
                    title: 'Permisos actualizados',
                    content: 'Los permisos han sido actualizados correctamente.',
                    size: 'mini',
                })
            })
            .catch(error => {
                openErrorDialog({
                    title: 'Error al actualizar',
                    content: 'Ha tenido lugar un error al actualizar los permisos del usuario.',
                    size: 'mini',
                })
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setIsChief(user?.is_chief || false)
    }, [user?.is_chief])

    return (
        <article className={`${styles.UserPermissionsPanel}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.PanelHeader}>
                Permisos
            </Header>
            <p>A continuación, se indica la configuración de permisos del usuario:</p>
            <Form
                className={styles.UserPermissionsForm}
                onSubmit={handleSubmit}
            >
                <Checkbox
                    className={styles.IsChiefCheckbox}
                    label={
                        <label>
                            <strong>
                                El usuario es el administrador de la correduría
                            </strong>
                        </label>
                    }
                    onChange={(e, { checked }) => setIsChief(checked)}
                    checked={isChief}
                />
                <div className={styles.Actions}>
                    <PrimaryButton
                        compact
                        icon
                        type='submit'
                        labelPosition='right'
                        disabled={isLoading}
                        loading={isLoading}
                        className={styles.ActionButton}
                    >
                        Guardar cambios
                        <Icon name='save' />
                    </PrimaryButton>
                </div>
            </Form>
        </article>
    )
}

export default UserPermissionsPanel

