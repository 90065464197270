import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Divider, Form, Icon } from 'semantic-ui-react'

import PageTitle from 'components/UI/PageTitle/PageTitle'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import recordsService from 'services/records'
import styles from './Reports.module.scss'


const Reports = () => {
    const { openErrorDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [fromDate, setFromDate] = useState('')
    const [untilDate, setUntilDate] = useState('')

    const cleanDateSelection = () => {
        setFromDate('')
        setUntilDate('')
    }

    const generateReport = () => {
        showLoader('Generando informe...')

        recordsService.exportRecordsXlsx(fromDate, untilDate)
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Informe de expedientes.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
                hideLoader()
            })
            .catch(() => {
                hideLoader()
                openErrorDialog({
                    title: 'Error al descargar informe',
                    content: `Ha tenido lugar un error al descargar el informe de expedientes.
                              Por favor, inténtalo de nuevo más tarde o contacta con el
                              administrador de la plataforma.`,
                    size: 'tiny'
                })
            })
    }

    return (
        <>
            <Helmet>
                <title>Informes - CenterIuris</title>
            </Helmet>
            <PageTitle
                hasWideIcon
                icon='chart pie'
                header='Informes'
                subheader={<>Los filtros de fecha limitarán los resultados por su <strong>fecha de entrada</strong></>}
            />
            <article className={styles.Content}>
                <div className={styles.MainPanel}>
                    <Form>
                        <Form.Group widths='equal' className={styles.DateFields}>
                            <Form.Input
                                type='date'
                                label='Desde fecha'
                                placeholder='Desde fecha'
                                className={styles.DateField}
                                value={fromDate || ''}
                                onChange={(e, { value }) => setFromDate(value)}
                            />
                            <Form.Input
                                type='date'
                                label='Hasta fecha'
                                placeholder='Hasta fecha'
                                className={styles.DateField}
                                value={untilDate || ''}
                                onChange={(e, { value }) => setUntilDate(value)}
                            />
                        </Form.Group>
                    </Form>
                    <div className={styles.Actions}>
                        <Divider />
                        <div className={styles.ButtonsWrapper}>
                            <SecondaryButton
                                compact
                                icon
                                labelPosition='left'
                                className={styles.ActionButton}
                                onClick={cleanDateSelection}
                            >
                                Limpiar fechas
                                <Icon name='trash' />
                            </SecondaryButton>
                            <PrimaryButton
                                compact
                                icon
                                labelPosition='right'
                                className={styles.ActionButton}
                                onClick={generateReport}
                            >
                                Generar informe
                                <Icon name='excel file outline' />
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}

export default Reports
