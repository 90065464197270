import React from 'react'

import { Header, Icon } from 'semantic-ui-react'

import styles from './PageTitle.module.scss'


const PageTitle = (props) => {
    const { hasWideIcon, header, icon, subheader } = props

    return (
        <Header className={styles.PageTitle}>
            <div className={styles.Header}>
                {
                    icon &&
                    <Icon
                        name={icon}
                        className={`${styles.Icon}${hasWideIcon ? ` ${styles.BigIcon}` : ''}`}
                    />
                }
                {header && header}
            </div>
            {
                subheader &&
                <Header.Subheader className={styles.SubHeader}>
                    {subheader}
                </Header.Subheader>
            }
        </Header>
    )
}

export default PageTitle