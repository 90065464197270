const AuthActions = {
    LOGIN_LOADING: 'LOGIN_LOADING',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
    RECOVER_USER_ERROR: 'RECOVER_USER_ERROR',
    UPDATE_USER: 'UPDATE_USER',
    REFRESH_SCHEDULING_SUCCESS: 'REFRESH_SCHEDULING_SUCCESS',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR'
}

const AuthReducer = (initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case AuthActions.LOGIN_LOADING:
            return {
                ...initialState,
                loginLoading: true,
                loginFailed: false
            }
        case AuthActions.LOGIN_SUCCESS:
            return {
                ...initialState,
                user: payload.user,
                accessToken: payload.accessToken,
                logoutTimeoutId: null,
                loginLoading: false,
                loginFailed: false
            }
        case AuthActions.LOGIN_ERROR:
            return {
                ...initialState,
                loginLoading: false,
                loginFailed: true
            }
        case AuthActions.LOGOUT:
            return {
                ...initialState,
                user: null,
                accessToken: null,
                tokenRefreshTimeoutId: null,
                logoutTimeoutId: null
            }
        case AuthActions.FETCH_USER_DATA_SUCCESS:
            return {
                ...initialState,
                user: payload.user
            }
        case AuthActions.RECOVER_USER_ERROR:
            return {
                ...initialState,
                user: null,
                accessToken: null,
                tokenRefreshTimeoutId: null,
                logoutTimeoutId: null
            }
        case AuthActions.UPDATE_USER:
            return {
                ...initialState,
                user: payload.user
            }
        case AuthActions.REFRESH_SCHEDULING_SUCCESS:
            return {
                ...initialState,
                tokenRefreshTimeoutId: payload.timeoutId
            }
        case AuthActions.REFRESH_TOKEN_SUCCESS:
            return {
                ...initialState,
                user: payload.user,
                accessToken: payload.accessToken
            }
        case AuthActions.REFRESH_TOKEN_ERROR:
            return {
                ...initialState,
                tokenRefreshTimeoutId: null,
                logoutTimeoutId: payload.timeoutId
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }
}


export {
    AuthActions,
    AuthReducer
}