import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Card, Header } from 'semantic-ui-react'

import InfoMessage from 'components/UI/InfoMessage/InfoMessage'
import SearchPanel from 'components/UI/SearchPanel/SearchPanel'
import styles from './PreviewCards.module.scss'


const PreviewCard = (props) => {
    const { title, content, link } = props
    const navigate = useNavigate()

    const handleClick = () => {
        if (link) {
            navigate(link)
        }
    }

    return (
        <Card
            link
            color='green'
            className={styles.PreviewCard}
            onClick={handleClick}
        >
            <Card.Content textAlign='center'>
                <Header size='small' className={styles.PreviewTitle}>
                    {title}
                </Header>
            </Card.Content>
            <Card.Content textAlign='center'>
                <Header size='large' className={styles.PreviewContent}>
                    {content}
                </Header>
            </Card.Content>
        </Card>
    )
}


const PreviewCards = (props) => {
    const { className, items, itemsPerRow, searchPlaceholder } = props
    const [filteredItems, setFilteredItems] = useState(null)
    const [searchString, setSearchString] = useState('')

    const handleSearchChange = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    useEffect(() => {
        if (!searchString.trim()) {
            setFilteredItems(items)
        } else {
            const filtered = items.filter(({ title, content }) => {
                const matches = [title?.toLowerCase(), content?.toLowerCase()].filter((text) => (
                    text.includes(searchString.toLowerCase())
                ))

                return (matches.length > 0)
            })
            setFilteredItems(filtered)
        }
    }, [items, searchString])

    return (
        <SearchPanel
            className={`${styles.SearchPanel}${className ? ` ${className}` : ''}`}
            onSearchChange={handleSearchChange}
            searchPlaceholder={searchPlaceholder}
        >
            <div className={styles.PanelContent}>
                {
                    (filteredItems?.length > 0) &&
                    <Card.Group
                        stackable
                        itemsPerRow={itemsPerRow || 5}
                        className={styles.PreviewCards}
                    >
                        {
                            filteredItems.map(({ title, content, link }) => (
                                <PreviewCard
                                    key={`${title}-${content}`}
                                    title={title}
                                    content={content}
                                    link={link}
                                />
                            ))
                        }
                    </Card.Group>
                }
                {
                    (filteredItems?.length === 0) &&
                    <InfoMessage
                        className={styles.NoItemsMessage}
                        title={
                            items?.length === 0
                                ? 'No hay usuarios registrados'
                                : 'Sin coincidencias'
                        }
                        content={
                            items?.length === 0
                                ? 'Actualmente no hay ningún usuario registrado'
                                : (
                                    <>
                                        No se han encontrado coincidencias para {' '}
                                        <strong className={styles.SearchString}>
                                            <i>
                                                {searchString}
                                            </i>
                                        </strong>
                                    </>
                                )
                        }
                    />
                }
            </div>
        </SearchPanel>
    )
}

export default PreviewCards