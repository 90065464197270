import React, { useEffect, useRef, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Divider, Icon, Item, Label, Message } from 'semantic-ui-react'

import { monthName } from 'assets/constants/dates'
import { CALENDAR_EVENT_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { dateToStr, datetimeToStr, isSameDay } from 'assets/utils/common'
import CalendarEventForm from 'components/CalendarEventForm/CalendarEventForm'
import BasicButton from 'components/UI/BasicButton/BasicButton'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import SecondaryActionButton from 'components/UI/SecondaryActionButton/SecondaryActionButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import agendaService from 'services/agenda'
import styles from './CalendarDayEvents.module.scss'


const CalendarDayEvents = () => {
    const { user, userGroups } = useAuth()
    const {
        openBasicDialog,
        openBasicMessageDialog,
        openWarningQuestionDialog,
        openErrorDialog,
        closeDialog,
        setConfirming,
        refreshData
    } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [formErrors, setFormErrors] = useState(null)
    const [dayEvents, setDayEvents] = useState(null)
    const [avoidSignalUpdate, setAvoidSignalUpdate] = useState(false)
    const { receivedEvent, eventNumber } = useNotifications()
    const avoidSignalUpdateRef = useRef()
    const { year, month, day } = useParams()
    const navigate = useNavigate()
    avoidSignalUpdateRef.current = avoidSignalUpdate

    const isUserConsultant = userGroups?.consultants || userGroups?.consultant_interns

    const fetchDayEvents = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && showLoader('Cargando eventos...')

        agendaService.getDayEvents(year, month, day)
            .then((res) => {
                setDayEvents(res.data)
                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error cargando los eventos.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }

    const updateOrGoToDay = (createdEvent) => {
        const pageDate = new Date(`${year}-${month}-${day}`)
        const eventDate = new Date(createdEvent.datetime)
        if (isSameDay(pageDate, eventDate)) {
            fetchDayEvents()
        } else {
            const eventDay = eventDate.getDate()
            const eventMonth = eventDate.getMonth() + 1
            const eventYear = eventDate.getFullYear()

            navigate(
                paths.CALENDAR_DAY_EVENTS.replace(':year', eventYear).replace(':month', eventMonth).replace(':day', eventDay)
            )
        }
    }

    const sanitizeDatetime = (datetime) => (
        datetime.split(':').slice(0, 2).join(':')
    )

    const handleEventCreation = (data) => {
        setConfirming(true)
        setAvoidSignalUpdate(true)

        agendaService.createEvent(data)
            .then((res) => {
                closeDialog()
                setFormErrors(null)
                updateOrGoToDay(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => {
                setConfirming(false)
                setAvoidSignalUpdate(false)
            })
    }

    const handleEventUpdate = (event, data) => {
        setConfirming(true)
        setAvoidSignalUpdate(true)

        let updateEventData

        if (event.created_by.id === user?.id) {
            updateEventData = agendaService.updateEvent
        } else {
            updateEventData = agendaService.updateEventReminders
            data = { reminders: data.reminders }
        }

        updateEventData(event.id, data)
            .then((res) => {
                closeDialog()
                setFormErrors(null)
                updateOrGoToDay(res.data)
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => {
                setConfirming(false)
                setAvoidSignalUpdate(false)
            })
    }

    const handleEventDeletion = (id) => {
        setConfirming(true)
        setAvoidSignalUpdate(true)

        agendaService.deleteEvent(id)
            .then((res) => {
                fetchDayEvents()
                closeDialog()
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al eliminar el evento.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al eliminar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => {
                setConfirming(false)
                setAvoidSignalUpdate(false)
            })
    }

    const handleCreateButtonClick = () => (
        openBasicMessageDialog({
            title: 'Nuevo evento',
            render: CalendarEventForm,
            renderProps: {
                onSubmit: handleEventCreation,
                errors: formErrors,
                initialValues: {
                    date: dateToStr(`${year}-${month}-${day}`, true)
                }
            },
            confirmSubmitsForm: CALENDAR_EVENT_FORM_ID,
            scrolling: false,
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    )

    const handleDeleteButtonClick = (id) => (
        openWarningQuestionDialog({
            title: 'Eliminar evento',
            content: '¿Seguro que quieres eliminar este evento?',
            size: 'mini',
            onConfirm: () => handleEventDeletion(id)
        })
    )

    const handleEventClick = (event) => {
        const eventDate = new Date(event.datetime)
        const currentDate = new Date()
        const isPastEvent = (eventDate <= currentDate)
        const isFullyEditable = (event.created_by.id === user?.id)
        const isDeletable = (isFullyEditable && !isPastEvent)
        const title = !isPastEvent ? 'Editar evento' : 'Datos del evento'
        const dialogOpener = !isPastEvent ? openBasicMessageDialog : openBasicDialog

        dialogOpener({
            title,
            render: CalendarEventForm,
            renderProps: {
                onSubmit: (formData) => handleEventUpdate(event, formData),
                errors: formErrors,
                isPastEvent,
                isFullyEditable,
                initialValues: {
                    ...event,
                    guests: event.guests?.map((guest) => guest.id) || [],
                    date: dateToStr(event.datetime, true),
                    time: datetimeToStr(event.datetime, true),
                    reminders: event.reminders?.map((reminder) => sanitizeDatetime(reminder.datetime)),
                }
            },
            secondaryActions: (
                isDeletable
                    ? (
                        <SecondaryActionButton icon='trash' onClick={() => handleDeleteButtonClick(event.id)}>
                            Eliminar
                        </SecondaryActionButton>
                    )
                    : undefined
            ),
            confirmSubmitsForm: !isPastEvent ? CALENDAR_EVENT_FORM_ID : null,
            scrolling: false,
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    }

    useEffect(() => {
        refreshData({ errors: formErrors })
    }, [formErrors])

    useEffect(() => {
        const date = new Date(`${year}-${month}-${day}`)

        // Check if it is not valid date, or if URL specifies
        // something like '31st of February', which JavaScript
        // converts a valid March day
        if (isNaN(date) || (date.getMonth() !== (parseInt(month) - 1))) {
            navigate(paths.RECORDS)
            return
        }

        fetchDayEvents()
    }, [year, month, day])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.CALENDAR_EVENT_CREATED,
            eventCodes.CALENDAR_EVENT_UPDATED,
            eventCodes.CALENDAR_EVENT_DELETED
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            if (!avoidSignalUpdateRef.current) {
                fetchDayEvents({ avoidLoader: true })
            } else {
                setAvoidSignalUpdate(false)
            }
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Eventos {day.padStart(2, '0')}/{month.padStart(2, '0')}/{year} - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='calendar alternate outline'
                header={
                    <>
                        Eventos del {' '}
                        <span>
                            {parseInt(day)} de {monthName[parseInt(month) - 1]?.toLowerCase()} de {parseInt(year)}
                        </span>
                    </>
                }
            />
            <article className={styles.Content}>
                <header className={styles.OptionsBar}>
                    <BasicButton
                        compact
                        icon
                        color='darkgreen'
                        labelPosition='right'
                        onClick={handleCreateButtonClick}
                    >
                        <Icon name='plus circle' />
                        Crear nuevo
                    </BasicButton>
                    <Divider />
                </header>
                <div className={styles.EventsWrapper}>
                    {
                        (dayEvents && !dayEvents.length) &&
                        <Message
                            info
                            header='Todavía no hay eventos'
                            icon={<Icon name='info circle' className={styles.MessageIcon} />}
                            size='large'
                            content={
                                <>
                                    No tienes ningún evento para este día. Puedes crear uno nuevo pulsando sobre
                                    el botón <strong><i>Crear nuevo</i></strong> de la parte superior.
                                </>
                            }
                        />
                    }
                    {
                        (dayEvents && !!dayEvents.length) &&
                        <Item.Group link>
                            {dayEvents.map((event) => {
                                const { id, datetime, title, description, created_by } = event
                                const eventDate = new Date(datetime)
                                const currentDate = new Date()
                                const isPastEvent = (eventDate <= currentDate)

                                return (
                                    <Item
                                        key={id}
                                        className={`${styles.Event}${isPastEvent ? ` ${styles.Expired}` : ''}`}
                                        onClick={() => handleEventClick(event)}
                                    >
                                        <Item.Content>
                                            <Item.Header className={styles.EventHeader}>
                                                <span className={styles.EventTime}>
                                                    {datetimeToStr(datetime, true)}
                                                </span>
                                                <span className={styles.HeaderSeparator} />
                                                <span className={styles.EventTitle}>
                                                    {title}
                                                </span>
                                            </Item.Header>
                                            {
                                                description &&
                                                <Item.Meta className={styles.EventDescription}>
                                                    {description}
                                                </Item.Meta>
                                            }
                                            {
                                                (user?.id !== created_by.id) &&
                                                <Item.Extra>
                                                    <Label className={styles.InvitationInfoLabel}>
                                                        Invitado por:
                                                        <span>{created_by.first_name} {created_by.last_name}</span>
                                                        {
                                                            (isUserConsultant && created_by.partner) &&
                                                            <span>({created_by.partner.name})</span>
                                                        }
                                                    </Label>
                                                </Item.Extra>
                                            }
                                        </Item.Content>
                                    </Item>
                                )
                            })}
                        </Item.Group>
                    }
                </div>
            </article>
        </>
    )
}

export default CalendarDayEvents
