import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button, Divider, Form, Icon, Loader, Message, TextArea } from 'semantic-ui-react'

import { messageType } from 'assets/constants/communication'
import { eventCodes } from 'assets/constants/notifications'
import { dateToLongStr, isSameDay } from 'assets/utils/common'
import SelectedFiles from 'components/UI/SelectedFiles/SelectedFiles'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import communicationService from 'services/communication'
import recordsService from 'services/records'
import MessageItem from './MessageItem'
import styles from './RecordCommunicationsPane.module.scss'


const RecordCommunicationsPane = (props) => {
    const { record, onFilesUpload, isVisible } = props
    const { id } = record

    const { userGroups } = useAuth()
    const { openWarningQuestionDialog, openErrorDialog, closeDialog } = useDialog()
    const { receivedEvent, eventNumber } = useNotifications()
    const { showLoader, hideLoader } = useLoader()
    const [messages, setMessages] = useState(null)
    const [messageText, setMessageText] = useState('')
    const [emptyFileList, setEmptyFileList] = useState(null)
    const [sendError, setSendError] = useState(null)
    const [isLoadError, setLoadError] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isSendingMessage, setSendingMessage] = useState(false)
    const [isSendingAnnotation, setSendingAnnotation] = useState(false)
    const [isUploading, setUploading] = useState(false)
    const inputRef = useRef(null)
    const messagesEndRef = useRef(null)

    const isNoMessages = (messages?.length === 0)
    const isSending = (isSendingMessage || isSendingAnnotation || isUploading)
    const isUserConsultant = userGroups?.consultants || userGroups?.consultant_interns

    const fetchConversation = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && setLoading(true)

        return communicationService.getConversation(id)
            .then((res) => setMessages(res.data))
            .catch(() => setLoadError(true))
            .finally(() => !avoidLoader && setLoading(false))
    }

    const generateConversationContent = () => {
        const content = []
        let lastDate
        let lastSender
        let lastType

        messages?.forEach((message, index) => {
            const { created_at, type, sender } = message
            const isNewDay = lastDate ? !isSameDay(lastDate, created_at) : true

            if (!lastDate || isNewDay) {
                content.push((
                    <div key={created_at} className={styles.DateLabel}>
                        <span>
                            {dateToLongStr(created_at)}
                        </span>
                    </div>
                ))
            }

            let hideSender = (
                (type !== messageType.CHAT) ||
                    ((lastSender === sender.id) &&
                    !isNewDay &&
                    (lastType === messageType.CHAT))
            )

            content.push((
                <MessageItem
                    key={message.id}
                    message={message}
                    hideSender={hideSender}
                    addTopMargin={!hideSender && !isNewDay}
                    onFileNameClick={downloadFile}
                    onDataUpdate={onDataUpdate}
                    isLast={index === (messages.length - 1)}
                />
            ))

            lastDate = created_at
            lastSender = sender.id
            lastType = type
        })

        return content
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView()
    }

    const onDataUpdate = () => (
        fetchConversation({ avoidLoader: true })
    )

    const uploadFiles = (files) => {
        showLoader('Subiendo archivos...')
        setUploading(true)

        const formData = new FormData()
        files?.forEach((file) => (
            formData.append('files', file, file.name)
        ))

        recordsService.uploadRecordFiles(id, formData)
            .then((res) => (
                communicationService.sendMessage(id, {
                    type: messageType.UPLOAD,
                    metadata: {
                        files: res.data.uploaded
                    },
                })
            ))
            .then(() => {
                onFilesUpload()
                hideLoader()
                fetchConversation({ avoidLoader: true })
            })
            .catch((error) => {
                hideLoader()

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al subir los archivos.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al subir archivos',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => setUploading(false))
    }

    const downloadFile = (fileName) => {
        recordsService.downloadRecordFile(id, fileName)
            .then((res) => {
                const tempAnchor = document.createElement('a')

                if (fileName.endsWith('.pdf')) {
                    tempAnchor.setAttribute('rel', 'noopener')
                    tempAnchor.setAttribute('target', '_blank')
                } else {
                    tempAnchor.setAttribute('download', true)
                }

                tempAnchor.setAttribute('href', res.data.url)
                tempAnchor.style.display = 'none'
                
                document.body.appendChild(tempAnchor)
                tempAnchor.click()
                document.body.removeChild(tempAnchor)
            })
            .catch(() => {
                openErrorDialog({
                    title: 'Error al descargar archivo',
                    content: `Ha tenido lugar un error al descargar el archivo.
                              Por favor, inténtalo de nuevo más tarde o contacta
                              con el administrador de la plataforma.`,
                    size: 'tiny',
                })
            })
    }

    const handleFileSelection = (event) => {
        const selectedFiles = Array.from(event.target.files)

        openWarningQuestionDialog({
            title: 'Subida de archivos',
            content: (
                <>
                    <p>¿Seguro que quieres subir estos archivos?</p>
                    <Divider hidden />
                    <SelectedFiles
                        className={styles.SelectedFiles}
                        files={selectedFiles}
                        readOnly
                        inModal
                    />
                </>
            ),
            onConfirm: () => {
                closeDialog()
                uploadFiles(selectedFiles)
            }
        })

        // We set the empty FileList to the input so we do
        // not have problems with the input's value
        inputRef.current.files = emptyFileList
    }

    const sendMessage = (isAnnotation) => {
        if (isAnnotation) {
            setSendingAnnotation(true)
        } else {
            setSendingMessage(true)    
        }

        const data = {
            type: isAnnotation ? messageType.ANNOTATION : messageType.CHAT,
            text: messageText
        }

        communicationService.sendMessage(id, data)
            .then((res) => {
                fetchConversation({ avoidLoader: true })
                setSendError(null)
                setMessageText('')
            })
            .catch((err) => {
                const { text, error } = err?.response?.data || {}
                let errorMessage = text || error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al enviar el mensaje'
                }

                setSendError(errorMessage)
            })
            .finally(() => {
                if (isAnnotation) {
                    setSendingAnnotation(false)
                } else {
                    setSendingMessage(false)    
                }
            })
    }

    useEffect(() => {
        fetchConversation()
    }, [id])

    useEffect(() => {
        if (!isLoading && !isNoMessages && isVisible) {
            scrollToBottom()
        }
    }, [isLoading, isNoMessages, isVisible, messages?.length])

    useEffect(() => {
        if (inputRef.current) {
            setEmptyFileList(inputRef.current.files)
        }
    }, [inputRef])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.RECORD_MESSAGE_UPDATED,
            eventCodes.RECORD_MESSAGE_DELETED,
            eventCodes.BROKER_MESSAGE_CREATED,
            eventCodes.CONSULTANT_MESSAGE_CREATED,
            eventCodes.ANNOTATION_CREATED,
            eventCodes.NEW_BROKER_UPLOAD,
            eventCodes.NEW_CONSULTANT_UPLOAD,
        ]

        if (relevantEvents.includes(receivedEvent?.code) && (receivedEvent?.context?.record_id === id)) {
            fetchConversation({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <div className={styles.RecordCommunicationsPane}>
            {
                isLoading &&
                <div className={styles.LoadingMessage}>
                    <Loader inverted size='small' active inline />
                    <span>
                        Cargando conversación...
                    </span>
                </div>
            }
            {
                (!isLoading && isLoadError) &&
                <Message
                    error
                    header='Error al cargar'
                    icon={<Icon name='times circle outline' className={styles.MessageIcon} />}
                    size='large'
                    content={
                        `Ha tenido lugar un error al cargar la conversación del expediente.
                         Por favor, inténtalo de nuevo más tarde o contacta con el administrador
                         de la plataforma.`
                    }
                />
            }
            {
                (!isLoading && isNoMessages) &&
                <div className={styles.NoMessages}>
                    Todavía no hay mensajes
                </div>
            }
            {
                (!isLoading && !isNoMessages) &&
                <div className={styles.MessageList}>
                    {generateConversationContent()}
                    <div ref={messagesEndRef} />
                </div>
            }
            {
                (!isLoading && !isLoadError) &&
                <div className={styles.InteractionArea}>
                    <Form>
                        <TextArea
                            placeholder='Escribe tu mensaje...'
                            onInput={(event, { value }) => setMessageText(value)}
                            value={messageText}
                            rows={3}
                        />
                        <input
                            multiple
                            type='file'
                            ref={inputRef}
                            onChange={handleFileSelection}
                            className={styles.FileInput}
                        />
                        <div className={`${styles.Errors}${sendError ? ` ${styles.Visible}` : ''}`}>
                            {
                                sendError &&
                                <>
                                    <Icon name='times circle' />
                                    <span>
                                        {sendError}
                                    </span>
                                </>
                            }
                        </div>
                    </Form>
                    <div className={styles.Actions}>
                        <Button
                            icon
                            disabled={isSending}
                            loading={isUploading}
                            labelPosition='right'
                            className={styles.ActionButton}
                            onClick={() => inputRef.current?.click()}
                        >
                            Subir archivos
                            <Icon name='upload' />
                        </Button>
                        {
                            isUserConsultant &&
                            <Button
                                icon
                                disabled={isSending}
                                loading={isSendingAnnotation}
                                labelPosition='right'
                                className={styles.ActionButton}
                                onClick={() => sendMessage(true)}
                            >
                                Enviar anotación
                                <Icon name='sticky note outline' />
                            </Button>
                        }
                        <Button
                            icon
                            disabled={isSending}
                            loading={isSendingMessage}
                            labelPosition='right'
                            className={styles.ActionButton}
                            onClick={() => sendMessage(false)}
                        >
                            Enviar mensaje
                            <Icon name='envelope' />
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default RecordCommunicationsPane
