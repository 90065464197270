import React, { useEffect, useState } from 'react'

import { Icon } from 'semantic-ui-react'

import { CENTERIURIS_LOGO_URL } from 'assets/constants/images'
import { sideBarLinks } from 'assets/constants/navigation'
import Calendar from 'components/Calendar/Calendar'
import SideBarLink from 'components/SideBarLink/SideBarLink'
import useAuth from 'hooks/useAuth'
import styles from './SideBar.module.scss'


const SideBar = (props) => {
    const { className, hideSideBar } = props
    const { isUserInAnyGroup } = useAuth()

    return (
        <nav className={`${styles.SideBar}${className ? ` ${className}` : ''}`}>
            <div className={styles.MainContentWrapper}>
                <header>
                    <Icon
                        inverted
                        name='times'
                        className={styles.Icon}
                        onClick={hideSideBar}
                    />
                    <img src={CENTERIURIS_LOGO_URL} alt='CenterIuris logo' />
                </header>
                {sideBarLinks.map(({ url, name, icon, activeForRegex, allowedGroups }, index) => (
                    isUserInAnyGroup(allowedGroups) &&
                    <SideBarLink
                        key={index}
                        url={url}
                        name={name}
                        icon={icon}
                        activeForRegex={activeForRegex}
                    />
                ))}
            </div>
            <Calendar className={styles.Calendar} />
        </nav>
    )
}

export default SideBar