import React from 'react'

import { Icon, Label } from 'semantic-ui-react'

import styles from './RoundIconButton.module.scss'


const RoundIconButton = (props) => {
    const { className, icon, label, labelColor, size, onClick, ...rest } = props

    return (
        <div
            className={`${styles.RoundIconButton}${size === 'small' ? ` ${styles.Small}` : ''}${className ? ` ${className}` : ''}`}
            onClick={onClick}>
            <Icon
                {...rest}
                name={icon}
                className={styles.Icon}
            />
            {
                (label !== undefined) &&
                <Label
                    floating
                    circular
                    size='tiny'
                    color={labelColor || 'red'}
                    className={styles.Label}
                >
                    {label}
                </Label>
            }
        </div>
    )
}

export default RoundIconButton