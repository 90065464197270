import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button, Header, Icon } from 'semantic-ui-react'

import { paths } from 'assets/constants/navigation'
import BasicButton from 'components/UI/BasicButton/BasicButton'
import useDialog from 'hooks/useDialog'
import authService from 'services/auth'
import styles from './UserActionsPanel.module.scss'


const UserActionsPanel = (props) => {
    const { className, user } = props

    const {
        closeDialog,
        openErrorDialog,
        openSuccessDialog,
        openWarningQuestionDialog,
        setConfirming,
    } = useDialog()
    const navigate = useNavigate()

    const handleDelete = () => {
        setConfirming(true)

        authService.deleteUser(user?.id)
            .then(() => {
                navigate(paths.PARTNER_USERS.replace(':partnerId', user?.partner?.id))
                closeDialog()
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al dar de baja al usuario.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al dar de baja',
                    content: errorMessage,
                    size: 'tiny'
                })
            })
            .finally(() => setConfirming(false))
    }

    const handleSendEmail = () => {
        setConfirming(true)

        authService.requestPasswordReset({ email: user?.email })
            .then(() => {
                openSuccessDialog({
                    title: 'Correo enviado',
                    content: 'El correo de recuperación de contraseña ha sido enviado correctamente.',
                    size: 'mini',
                })
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al enviar el correo de
                                    recuperación de contraseña. Por favor, inténtalo
                                    de nuevo más tarde o contacta con el administrador
                                    de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al enviar',
                    content: errorMessage,
                    size: 'tiny'
                })
            })
            .finally(() => setConfirming(false))
    }

    const handleDeleteButtonClick = () => {
        openWarningQuestionDialog({
            title: 'Dar de baja',
            content: '¿Seguro que quieres dar de baja a este usuario?',
            size: 'mini',
            onConfirm: handleDelete,
        })
    }

    const handleSendEmailButtonClick = () => {
        openWarningQuestionDialog({
            title: 'Enviar recuperación de contraseña',
            content: '¿Seguro que quieres enviar un correo de recuperación de contraseña a este usuario?',
            size: 'mini',
            onConfirm: handleSendEmail,
        })
    }

    return (
        <article className={`${styles.UserActionsPanel}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.PanelHeader}>
                Acciones
            </Header>
            <p>A continuación, se muestran las distintas acciones administrativas disponibles:</p>
            <div className={styles.Actions}>
                <BasicButton
                    compact
                    fluid
                    icon
                    color='red'
                    labelPosition='left'
                    className={styles.DeleteButton}
                    onClick={handleDeleteButtonClick}
                >
                    <Icon name='trash' />
                    Dar de baja
                </BasicButton>
                <BasicButton
                    compact
                    fluid
                    icon
                    color='gray'
                    labelPosition='left'
                    className={styles.ResetPasswordButton}
                    onClick={handleSendEmailButtonClick}
                >
                    <Icon name='envelope' />
                    Enviar recuperación de contraseña
                </BasicButton>
            </div>
        </article>
    )
}

export default UserActionsPanel

