import React from 'react'

import { Input } from 'semantic-ui-react'

import styles from './SearchPanel.module.scss'


const SearchPanel = (props) => {
    const { className, children, onSearchChange, searchPlaceholder } = props

    return (
        <div className={`${styles.SearchPanel}${className ? ` ${className}` : ''}`}>
            <header className={styles.SearchBar}>
                <Input
                    autoFocus
                    icon='search'
                    iconPosition='left'
                    placeholder={searchPlaceholder || 'Buscar...'}
                    className={styles.Input}
                    onChange={onSearchChange}
                />
            </header>
            {children}
        </div>
    )
}

export default SearchPanel