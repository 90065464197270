import React, { useEffect, useState } from 'react'

import { Button, Form, Icon, Message } from 'semantic-ui-react'
import uuid from 'uuid'

import { CALENDAR_EVENT_FORM_ID } from 'assets/constants/forms'
import { eventCodes } from 'assets/constants/notifications'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useNotifications from 'hooks/useNotifications'
import agendaService from 'services/agenda'
import styles from './CalendarEventForm.module.scss'


const CalendarEventForm = (props) => {
    const { initialValues, errors, onSubmit, isPastEvent = false, isFullyEditable = true } = props

    const [data, setData] = useState({
        title: initialValues?.title || '',
        description: initialValues?.description || '',
        date: initialValues?.date || '',
        time: initialValues?.time || '',
        guests: initialValues?.guests || [],
        reminders: initialValues?.reminders || [],
    })
    const [availableGuests, setAvailableGuests] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [reminderFields, setReminderFields] = useState(null)
    const { user, userGroups } = useAuth()
    const { openErrorDialog } = useDialog()
    const { receivedEvent, eventNumber } = useNotifications()

    const allLoaded = !!availableGuests

    const isUserConsultant = userGroups?.consultants || userGroups?.consultant_interns

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const { date, time, ...restData } = data
        onSubmit({
            ...restData,
            datetime: `${date}T${time}`,
            reminders: data.reminders.filter((reminder) => !!reminder)
        })
    }

    const fetchAvailableGuests = () => {
        agendaService.getAvailableGuests()
            .then((res) => {
                let options = res.data.map((elem) => {
                    const option = {
                        key: elem.id,
                        value: elem.id,
                    }

                    let partnerInfo = ''

                    if (isUserConsultant && elem.partner) {
                        partnerInfo = `(${elem.partner.name})`
                    }

                    const text = `${elem.first_name} ${elem.last_name}${partnerInfo ? ` ${partnerInfo}` : ''}`

                    return {
                        ...option,
                        text
                    }
                })

                if (isFullyEditable) {
                    options = options.filter((option) => option.key !== user?.id)
                }

                setAvailableGuests(options)
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los usuarios.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }

    useEffect(() => {
        if (!userGroups) {
            return
        }
        
        fetchAvailableGuests()
    }, [userGroups])

    useEffect(() => {
        if (!data?.reminders?.length || reminderFields) {
            return
        }

        const fieldsData = {}

        data.reminders.forEach((reminder) => {
            fieldsData[uuid.v4()] = reminder
        })

        setReminderFields(fieldsData)
    }, [data?.reminders])

    useEffect(() => {
        if (!reminderFields) {
            return
        }

        const newReminders = Object.entries(reminderFields).map(([key, val]) => val)
        setData({
            ...data,
            reminders: newReminders,
        })
    }, [reminderFields])

    useEffect(() => {
        const relevantUserEvents = [eventCodes.ACCESS_REQUEST_APPROVED, eventCodes.USER_UPDATED, eventCodes.USER_DELETED]

        if (relevantUserEvents.includes(receivedEvent?.code)) {
            fetchAvailableGuests()
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <Form
            id={CALENDAR_EVENT_FORM_ID}
            className={styles.CalendarEventForm}
            onSubmit={handleSubmit}
            error={!!errors}
        >
            <Form.Group>
                <Form.Input
                    required={!isPastEvent && isFullyEditable}
                    readOnly={isPastEvent || !isFullyEditable}
                    className={styles.SeparatedField}
                    error={!!errors?.title}
                    disabled={!allLoaded}
                    label='Título'
                    placeholder='Título'
                    name='title'
                    value={data.title || ''}
                    width={8}
                    onChange={handleChange}
                />
                <Form.Input
                    required={!isPastEvent && isFullyEditable}
                    readOnly={isPastEvent || !isFullyEditable}
                    className={styles.SeparatedField}
                    type='date'
                    error={!!errors?.datetime}
                    disabled={!allLoaded}
                    label='Fecha'
                    placeholder='Fecha'
                    name='date'
                    value={data.date || ''}
                    width={4}
                    onChange={handleChange}
                />
                <Form.Input
                    required={!isPastEvent && isFullyEditable}
                    readOnly={isPastEvent || !isFullyEditable}
                    className={styles.SeparatedField}
                    type='time'
                    error={!!errors?.datetime}
                    disabled={!allLoaded}
                    label='Hora'
                    placeholder='Hora'
                    name='time'
                    value={data.time || ''}
                    width={4}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.TextArea
                readOnly={isPastEvent || !isFullyEditable}
                label='Descripción del evento'
                placeholder='Descripción del evento'
                name='description'
                error={!!errors?.description}
                disabled={!allLoaded}
                value={data.description || ''}
                rows={5}
                onChange={handleChange}
            />
            <Form.Select
                multiple
                search
                disabled={!allLoaded}
                loading={!availableGuests?.length}
                options={availableGuests || []}
                label='Invitados al evento'
                placeholder='Seleccionar invitados'
                name='guests'
                value={data.guests || []}
                className={(isPastEvent || !isFullyEditable) ? styles.DisabledDropdown : undefined}
                onChange={handleChange}
            />
            {
                (!isPastEvent || (Object.keys(reminderFields || {}).length > 0)) &&
                <>
                    <Form.Field disabled={!allLoaded} className={styles.MarginlessField}>
                        <label>Recordatorios</label>
                    </Form.Field>
                    <div className={styles.Reminders}>
                        {Object.entries(reminderFields || {}).map(([key, val]) => (
                            <div className={styles.Reminder} key={key}>
                                <Form.Input
                                    readOnly={isPastEvent}
                                    name={key}
                                    className={styles.ReminderField}
                                    type='datetime-local'
                                    disabled={!allLoaded}
                                    value={val || ''}
                                    onChange={(e, { value }) => (
                                        setReminderFields({
                                            ...(reminderFields || {}),
                                            [key]: value
                                        })
                                    )}
                                />
                                {
                                    !isPastEvent &&
                                    <Icon
                                        link
                                        name='trash'
                                        className={styles.DeleteReminderIcon}
                                        onClick={() => (
                                            setReminderFields(
                                                Object.fromEntries(
                                                    Object.entries(reminderFields).filter(([k, v]) => k !== key)
                                                )
                                            )
                                        )}
                                    />
                                }
                            </div>
                        ))}
                        {
                            !isPastEvent &&
                            <Button
                                basic
                                fluid
                                type='button'
                                disabled={!allLoaded}
                                onClick={() => (
                                    setReminderFields({
                                        ...reminderFields,
                                        [uuid.v4()]: '',
                                    })
                                )}
                            >
                                <Icon name='plus' />
                                Añadir recordatorio
                            </Button>
                        }
                    </div>
                </>
            }
            {
                errors &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(errors).map(([key, value], index) => (
                            <Message.Item key={index}>
                                {Array.isArray(value) ? value[0] : value}
                            </Message.Item>
                        ))
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default CalendarEventForm