import React, { useState } from 'react'

import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { LOGIN_BACKGROUND_URL } from 'assets/constants/images'
import { loginPanels } from 'assets/constants/login'
import LoginPanel from 'components/LoginPanel/LoginPanel'
import AccessRequestPanel from 'components/AccessRequestPanel/AccessRequestPanel'
import PasswordResetPanel from 'components/PasswordResetPanel/PasswordResetPanel'
import styles from './Login.module.scss'


const Login = () => {
    const { LOGIN, ACCESS_REQUEST, RESET_PASSWORD } = loginPanels

    const { state } = useLocation()
    const [currentPanel, setCurrentPanel] = useState(state?.initialPanel || LOGIN)

    return (
        <>
            <Helmet>
                <title>Login - CenterIuris</title>
                <style>
                    {
                        `.${styles.Container} {
                            background-image: linear-gradient(
                                rgba(0, 0, 0, 0.1),
                                rgba(0, 0, 0, 0.1)),
                                url(${LOGIN_BACKGROUND_URL});
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }`
                    }
                </style>
            </Helmet>
            <section className={styles.Container}>
                {currentPanel === LOGIN && <LoginPanel showPanel={setCurrentPanel} />}
                {currentPanel === ACCESS_REQUEST && <AccessRequestPanel showPanel={setCurrentPanel} />}
                {currentPanel === RESET_PASSWORD && <PasswordResetPanel showPanel={setCurrentPanel} />}
            </section>
        </>
    )
}

export default Login
