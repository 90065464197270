const AWS_S3_BASE_URL = process.env.REACT_APP_AWS_BASE_URL

// CenterBrok
const CENTERBROK_ICON_URL = `${AWS_S3_BASE_URL}/public/img/centerbrok/centerbrok-icon.png`
const CENTERBROK_LOGO_URL = `${AWS_S3_BASE_URL}/public/img/centerbrok/centerbrok-logo.png`
const CENTERIURIS_LOGO_URL = `${AWS_S3_BASE_URL}/public/img/centerbrok/centeriuris-logo.svg`

// States
const ERROR_IMAGE_URL = `${AWS_S3_BASE_URL}/public/img/status/sorry.png`
const SUCCESS_IMAGE_URL = `${AWS_S3_BASE_URL}/public/img/status/success.png`

// Login Background
const LOGIN_BACKGROUND_URL = `${AWS_S3_BASE_URL}/public/img/background/login-background.jpg`

// Miscellaneous
const USER_ICON_URL = `${AWS_S3_BASE_URL}/public/img/misc/user-icon.png`
const WIREFRAME_URL = `${AWS_S3_BASE_URL}/public/img/misc/wireframe.png`

// Other constants
const MEGABYTE = 1048576  // 1MB = 1,048,576 bytes
const MAX_IMAGE_SIZE = 2 * MEGABYTE

export {
    CENTERBROK_ICON_URL,
    CENTERBROK_LOGO_URL,
    CENTERIURIS_LOGO_URL,
    ERROR_IMAGE_URL,
    SUCCESS_IMAGE_URL,
    LOGIN_BACKGROUND_URL,
    USER_ICON_URL,
    WIREFRAME_URL,
    MEGABYTE,
    MAX_IMAGE_SIZE,
}