import React from 'react'

import { Button } from 'semantic-ui-react'

import styles from './SecondaryButton.module.scss'


const SecondaryButton = (props) => {
    const { children, className } = props

    return (
        <Button {...props} className={`${styles.SecondaryButton}${className ? ` ${className}` : ''}`}>
            {children}
        </Button>
    )
}

export default SecondaryButton