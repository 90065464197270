import React, { useEffect, useState } from 'react'

import { Button, Divider, Form, Header, Icon, Message, Segment } from 'semantic-ui-react'

import { loginPanels } from 'assets/constants/login'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import authService from 'services/auth'
import styles from './PasswordResetPanel.module.scss'


const PasswordResetPanel = (props) => {
    const { showPanel } = props
    const { LOGIN } = loginPanels

    const [formData, setFormData] = useState({})
    const [formError, setFormError] = useState(null)
    const [successData, setSuccessData] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()

        setLoading(true)

        authService.requestPasswordReset(formData)
            .then(res => {
                setFormError(null)
                setSuccessData(res.data)
            })
            .catch(error => {
                setFormError(error.response?.data?.email || 'Ha tenido lugar un error')
            })
            .finally(() => setLoading(false))
    }

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Segment className={styles.PasswordResetPanel}>
            {
                successData &&
                <Message positive icon className={styles.InstructionsSentMessage}>
                    <Icon name='envelope' />
                    <Message.Content>
                        <Message.Header>Instrucciones enviadas</Message.Header>
                        <p>
                            Se ha enviado un email a tu dirección de correo electrónico
                            con las instrucciones para restaurar la contraseña. Por favor,
                            revisa tu bandeja de entrada. La solicitud caducará dentro
                            de {successData.hours_left} horas.
                        </p>
                    </Message.Content>
                </Message>
            }
            {
                !successData &&
                <Form
                    id='reset-password-form'
                    className={styles.PasswordResetForm}
                    onSubmit={handleSubmit}
                    error={!!formError}
                >
                    <Header size='medium' className={styles.Header} dividing>
                        Restablecer contraseña
                    </Header>
                    <Message
                        info
                        icon='info circle'
                        content='Por favor, introduce tu dirección de correo electrónico.
                                 Recibirás un email con instrucciones sobre cómo
                                 restablecer tu contraseña.'
                    />
                    <Form.Input
                        error={!!formError}
                        label='Correo electrónico'
                        placeholder='Correo electrónico'
                        icon='envelope'
                        iconPosition='left'
                        name='email'
                        onChange={handleChange}
                    />
                    {
                        formError &&
                        <Message error content={formError} />
                    }
                </Form>
            }
            <Divider />
            {
                successData &&
                <SecondaryButton fluid className={styles.Actions} onClick={() => showPanel?.(LOGIN)}>
                    Aceptar
                </SecondaryButton>
            }
            {
                !successData &&
                <Button.Group fluid className={styles.Actions}>
                    <PrimaryButton onClick={() => showPanel?.(LOGIN)} disabled={isLoading}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton form='reset-password-form' type='submit' disabled={isLoading} loading={isLoading}>
                        Obtener contraseña
                    </SecondaryButton>
                </Button.Group>
            }
        </Segment>
    )
}

export default PasswordResetPanel