import React, { createContext, useReducer } from 'react'

import { NotificationsActions, NotificationsReducer } from './reducer'


const initialState = {
    isConnectionActive: false,
    socket: null,
    receivedEvent: null,
    eventNumber: null,
    pushNotifications: null,
}

const NotificationsContext = createContext()

const NotificationsProvider = ({ children }) => {
    const [notificationsConfig, dispatch] = useReducer(NotificationsReducer, initialState)
    const value = { notificationsConfig, dispatch }

    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    )
}

export {
    NotificationsContext,
    NotificationsProvider
}