import React from 'react'

import { Header, Icon } from 'semantic-ui-react'

import { getFileIconProps } from 'assets/utils/common'
import styles from './SelectedFiles.module.scss'

const FileItem = (props) => {
    const { name, onDelete, readOnly } = props

    return (
        <div className={styles.FileItem}>
            <div>
                <div>
                    <Icon {...getFileIconProps(name)} size='large' className={styles.FileIcon} />
                    <i className={styles.FileName}>{name}</i>
                </div>
                {
                    !readOnly &&
                    <Icon
                        link
                        name='times circle'
                        color='red'
                        className={styles.DeleteFileButton}
                        onClick={onDelete}
                    />
                }
            </div>
        </div>
    )
}


const SelectedFiles = (props) => {
    const { className, files, onFileDelete, readOnly, inModal } = props

    return (
        <div className={`${styles.SelectedFiles}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.Header} size={inModal ? 'small' : undefined}>
                Archivos seleccionados
                {
                    !!files?.length &&
                    <span className={styles.FilesAmount}>({files?.length})</span>
                }
            </Header>
            <div className={styles.FilesList}>
                {
                    !files?.length &&
                    <i>Aún no se han seleccionado archivos</i>
                }
                {
                    !!files?.length &&
                    files.map((file, index) => (
                        <FileItem
                            key={index}
                            name={file.name}
                            readOnly={readOnly}
                            onDelete={() => onFileDelete(file)}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default SelectedFiles