import React, { useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import { eventCodes } from 'assets/constants/notifications'
import { MAX_MOBILE_WIDTH } from 'assets/constants/viewport'
import HeaderBar from 'components/HeaderBar/HeaderBar'
import SideBar from 'components/SideBar/SideBar'
import useAuth from 'hooks/useAuth'
import useNotifications from 'hooks/useNotifications'
import useWindowDimensions from 'hooks/useWindowDimensions'
import styles from './LoggedPage.module.scss'


const LoggedPage = (props) => {
    const [isSideBarVisible, setSideBarVisible] = useState(null)
    const { height, width } = useWindowDimensions()
    const { fetchUserData, logout, user } = useAuth()
    const {
        isConnectionActive,
        openConnection,
        closeConnection,
        receivedEvent,
        eventNumber,
    } = useNotifications()

    useEffect(() => {
        openConnection()
    }, [])

    useEffect(() => {
        if (isConnectionActive) {
            return closeConnection
        }
    }, [isConnectionActive])

    useEffect(() => {
        if ((width <= MAX_MOBILE_WIDTH) && (isSideBarVisible === null)) {
            setSideBarVisible(false)
        } else if ((width > MAX_MOBILE_WIDTH) && (isSideBarVisible === null)) {
            setSideBarVisible(true)
        }
    }, [width])

    // Receiving signals from user management actions
    useEffect(() => {
        const accessRequestEvents = [
            eventCodes.USER_UPDATED,
            eventCodes.USER_DELETED,
            eventCodes.USER_PERMISSIONS_UPDATED,
            eventCodes.PARTNER_DELETED,
        ]

        if (!accessRequestEvents.includes(receivedEvent?.code)) {
            return
        }

        if ([eventCodes.USER_UPDATED, eventCodes.USER_PERMISSIONS_UPDATED].includes(receivedEvent?.code) &&
            (receivedEvent?.context?.user?.id === user.id)) {
            fetchUserData()
        } else if (((eventCodes.USER_DELETED === receivedEvent?.code) && (receivedEvent?.context?.user?.id === user.id)) ||
            ((eventCodes.PARTNER_DELETED === receivedEvent?.code) && (receivedEvent?.context?.company?.id === user.partner?.id))) {
            logout()
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <div className={styles.LoggedPage}>
            <SideBar
                className={`${styles.SideBar}${!isSideBarVisible ? ` ${styles.Hidden}` : ''}`}
                hideSideBar={() => setSideBarVisible(false)}
            />
            <main className={styles.MainContent}>
                <HeaderBar
                    toggleSideBar={() => setSideBarVisible(!isSideBarVisible)}
                />
                <section className={styles.PageContent}>
                    <Outlet />
                </section>
            </main>
        </div>
    )
}

export default LoggedPage