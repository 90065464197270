const NotificationsActions = {
    OPEN_CONNECTION: 'OPEN_CONNECTION',
    RECEIVE_EVENT: 'RECEIVE_EVENT',
    FETCH_PUSH_NOTIFICATIONS: 'FETCH_PUSH_NOTIFICATIONS',
    CLOSE_CONNECTION: 'CLOSE_CONNECTION',
}

const NotificationsReducer = (initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case NotificationsActions.OPEN_CONNECTION:
            return {
                ...initialState,
                isConnectionActive: true,
                socket: payload.socket,
                eventNumber: 0,
            }
        case NotificationsActions.RECEIVE_EVENT:
            return {
                ...initialState,
                receivedEvent: payload.event,
                eventNumber: initialState.eventNumber + 1,
            }
        case NotificationsActions.FETCH_PUSH_NOTIFICATIONS:
            return {
                ...initialState,
                pushNotifications: {
                    unread: payload.unreadAmount,
                    list: payload.notifications,
                    pagination: {
                        currentPage: payload.currentPage,
                        previousPage: payload.previousPage,
                        nextPage: payload.nextPage,
                        lastPage: payload.lastPage,
                    },
                },
            }
        case NotificationsActions.CLOSE_CONNECTION:
            return {
                ...initialState,
                isConnectionActive: false,
                socket: null,
                receivedEvent: null,
                eventNumber: null,
                pushNotifications: null,
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }
}


export {
    NotificationsActions,
    NotificationsReducer
}