import React, { createContext, useReducer } from 'react'

import { Dimmer, Loader } from 'semantic-ui-react'

import { LoaderActions, LoaderReducer } from './reducer'


const initialState = {
    text: null,
    indeterminate: false,
    visible: false
}

const LoaderContext = createContext()

const LoaderProvider = ({ children }) => {
    const [loaderConfig, dispatch] = useReducer(LoaderReducer, initialState)
    const value = { loaderConfig, dispatch }

    return (
        <LoaderContext.Provider value={value}>
            {children}
            {
                loaderConfig &&
                <Dimmer inverted page active={loaderConfig.visible}>
                    <Loader size='big' indeterminate={loaderConfig.indeterminate}>
                        {loaderConfig.text ? loaderConfig.text : null}
                    </Loader>
                </Dimmer>
            }
        </LoaderContext.Provider>
    )
}

export {
    LoaderContext,
    LoaderProvider
}