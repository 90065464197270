import React from 'react'

import { Icon, Message } from 'semantic-ui-react'

import styles from './InfoMessage.module.scss'


const InfoMessage = (props) => {
    const { className, title, content } = props

    return (
        <Message
            info
            icon
            size='large'
            className={`${styles.InfoMessage}${className ? ` ${className}` : ''}`}
        >
            <Icon name='info circle' />
            <Message.Content>
                <Message.Header>
                    {title}
                </Message.Header>
                <span>
                    {content}
                </span>
            </Message.Content>
        </Message>
    )
}

export default InfoMessage