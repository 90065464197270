import React, { useEffect } from 'react'

import RoundIconButton from 'components/UI/RoundIconButton/RoundIconButton'
import NotificationsWidget from 'components/NotificationsWidget/NotificationsWidget'
import UserWidget from 'components/UserWidget/UserWidget'
import styles from './HeaderBar.module.scss'


const HeaderBar = (props) => {
    const { toggleSideBar } = props

    return (
        <header className={styles.HeaderBar}>
            <RoundIconButton
                inverted
                icon='bars'
                className={styles.SideBarButton}
                onClick={() => toggleSideBar?.()}
            />
            <div className={styles.Widgets}>
                <NotificationsWidget className={styles.NotificationsWidget} />
                <UserWidget />
            </div>
        </header>
    )
}

export default HeaderBar