import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab } from 'semantic-ui-react'

import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import RecordCommunicationsPane from 'components/RecordCommunicationsPane/RecordCommunicationsPane'
import RecordDataPane from 'components/RecordDataPane/RecordDataPane'
import RecordDescriptionPane from 'components/RecordDescriptionPane/RecordDescriptionPane'
import RecordDocumentsPane from 'components/RecordDocumentsPane/RecordDocumentsPane'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import recordsService from 'services/records'
import styles from './RecordManagement.module.scss'


const RecordManagement = (props) => {
    const { initialTab } = props

    const [record, setRecord] = useState(null)
    const [activeTab, setActiveTab] = useState(initialTab || 0)
    const [fileUploads, setFileUploads] = useState(0)
    const [refreshCount, setRefreshCount] = useState(0)
    const { user } = useAuth()
    const { openErrorDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const { receivedEvent, eventNumber } = useNotifications()
    const navigate = useNavigate()
    const { recordId } = useParams()

    const generateSubheader = () => (
        <>
            Dado de alta por {' '}
            <strong>
                {
                    (record.created_by.id === user?.id)
                        ? 'ti'
                        : `${record.created_by.first_name} ${record.created_by.last_name}`
                }
            </strong>
            {
                !record.created_by.is_active &&
                ' (dado de baja)'
            }
            {
                (record.created_by.partner && record.created_by.id !== user?.id) &&
                <>
                    , de {' '}
                    <strong>
                        {record.created_by.partner.name}
                    </strong>
                </>
            }
        </>
    )

    const generatePanes = () => {
        const options = [
            {
                name: 'Datos',
                icon: 'wpforms',
                content: (
                    <RecordDataPane
                        record={record}
                        onDataUpdate={(data) => setRecord(data)}
                        refreshCount={refreshCount}
                    />
                )
            },
            {
                name: 'Descripción',
                icon: 'align left',
                content: (
                    <RecordDescriptionPane
                        record={record}
                        onDataUpdate={(data) => setRecord(data)}
                    />
                )
            },
            {
                name: 'Comunicaciones',
                icon: 'comment alternate outline',
                className: styles.RecordCommunicationsPane,
                content: (
                    <RecordCommunicationsPane
                        record={record}
                        onFilesUpload={() => setFileUploads(fileUploads + 1)}
                        isVisible={activeTab === 2}
                    />
                )
            },
            {
                name: 'Documentos',
                icon: 'folder open outline',
                content: (
                    <RecordDocumentsPane
                        record={record}
                        fileUploads={fileUploads}
                    />
                )
            },
        ]

        const panes = options.map(({name, icon, className, content}) => ({
            menuItem: { key: name, icon, content: name, className: styles.MenuItem },
            pane: { key: name, className: `${styles.RecordPane}${className ? ` ${className}` : ''}`, content },
        }))

        return panes
    }

    const fetchRecord = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && showLoader('Cargando expediente...')

        recordsService.getRecord(recordId)
            .then((res) => {
                setRecord(res.data)
                setRefreshCount(refreshCount + 1)
                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                if (error.response.status === 404) {
                    navigate(paths.NOT_FOUND)
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar el expediente.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
    }

    useEffect(() => {
        fetchRecord()
    }, [recordId])

    useEffect(() => {
        if ((receivedEvent?.code === eventCodes.RECORD_UPDATED) && (receivedEvent?.context?.record_id === record?.id)) {
            fetchRecord({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Expediente {record ? record.number : '' } - CenterIuris</title>
            </Helmet>
            {
                record &&
                <>
                    <PageTitle
                        icon='paste'
                        header={<>Expediente <span>{record.number}</span></>}
                        subheader={generateSubheader()}
                    />
                    <article className={styles.Content}>
                        <Tab
                            menu={{
                                attached: 'top',
                                stackable: true,
                                inverted: true,
                                className: styles.ManagementPanelMenu,
                            }}
                            activeIndex={activeTab}
                            panes={generatePanes()}
                            renderActiveOnly={false}
                            className={styles.ManagementPanel}
                            onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}
                        />
                    </article>
                </>
            }
        </>
    )
}

export default RecordManagement
