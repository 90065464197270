import React, { useEffect, useState } from 'react'

import { Form, Message } from 'semantic-ui-react'

import { INSURANCE_FORM_ID } from 'assets/constants/forms'
import styles from './InsuranceForm.module.scss'


const InsuranceForm = (props) => {
    const { initialValues, errors, onSubmit } = props

    const [data, setData] = useState({
        name: initialValues?.name || '',
        tax_id: initialValues?.tax_id || '',
        contact: initialValues?.contact || '',
        address: initialValues?.address || '',
        postcode: initialValues?.postcode || '',
        town: initialValues?.town || '',
        province: initialValues?.province || '',
        autonomous_community: initialValues?.autonomous_community || '',
        main_phone: initialValues?.main_phone || '',
        secondary_phone: initialValues?.secondary_phone || '',
        email: initialValues?.email || '',
    })

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    return (
        <Form
            id={INSURANCE_FORM_ID}
            className={styles.InsuranceForm}
            onSubmit={() => onSubmit(data)}
            error={!!errors}
        >
            <Form.Group widths='equal'>
                <Form.Input
                    required
                    error={!!errors?.name}
                    label='Nombre'
                    placeholder='Nombre'
                    name='name'
                    value={data.name || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    required
                    error={!!errors?.tax_id}
                    label='NIF/CIF'
                    placeholder='NIF/CIF'
                    name='tax_id'
                    value={data.tax_id || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input
                    error={!!errors?.contact}
                    label='Contacto'
                    placeholder='Contacto'
                    name='contact'
                    value={data.contact || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    error={!!errors?.address}
                    label='Dirección'
                    placeholder='Dirección'
                    name='address'
                    value={data.address || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input
                    error={!!errors?.postcode}
                    label='Código postal'
                    placeholder='Código postal'
                    name='postcode'
                    value={data.postcode || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    error={!!errors?.town}
                    label='Población'
                    placeholder='Población'
                    name='town'
                    value={data.town || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input
                    error={!!errors?.province}
                    label='Provincia'
                    placeholder='Provincia'
                    name='province'
                    value={data.province || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    error={!!errors?.autonomous_community}
                    label='Comunidad Autónoma'
                    placeholder='Comunidad Autónoma'
                    name='autonomous_community'
                    value={data.autonomous_community || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input
                    error={!!errors?.main_phone}
                    label='Teléfono principal'
                    placeholder='Teléfono principal'
                    name='main_phone'
                    value={data.main_phone || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    error={!!errors?.secondary_phone}
                    label='Teléfono secundario'
                    placeholder='Teléfono secundario'
                    name='secondary_phone'
                    value={data.secondary_phone || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    error={!!errors?.email}
                    label='Correo electrónico'
                    placeholder='Correo electrónico'
                    name='email'
                    value={data.email || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            {
                errors &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(errors).map(([key, value], index) => (
                            <Message.Item key={index}>
                                {Array.isArray(value) ? value[0] : value}
                            </Message.Item>
                        ))
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default InsuranceForm