import axios from 'axios'


const baseURL = process.env.REACT_APP_API_BASE_URL
const axiosInstance = axios.create({baseURL})

axiosInstance.interceptors.request.use((config) => {
    const tokenData = JSON.parse(localStorage.getItem('CenterIurisAccessToken'))

    if (tokenData) {
        config.headers = {
            ...config.headers,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        }
    }

    return config
})

export {
    axiosInstance,
    baseURL
}