import { useContext, useEffect, useState } from 'react'

import {
    MINUTES_UNTIL_LOGOUT,
    TOKEN_REFRESH_HOURS_THRESHOLD,
    authGroups
} from 'assets/constants/auth'
import { AuthActions, AuthContext } from 'contexts/Auth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import authService from 'services/auth'


const useAuth = () => {
    const context = useContext(AuthContext)

    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    const { authData, dispatch } = context
    const { user, accessToken, tokenRefreshTimeoutId, logoutTimeoutId } = authData

    const { openWarningDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [isCheckInProgress, setCheckInProgress] = useState(null)
    const [userGroups, setUserGroups] = useState(null)

    // Update 'check in progress' state
    useEffect(() => {
        if ((user === null) && (accessToken !== null)) {
            setCheckInProgress(true)
        } else {
            setCheckInProgress(false)
        }
    }, [user, accessToken])

    // Update auth groups from current user
    useEffect(() => {
        const groupMap = {}

        if (user) {
            authGroups.all(false).forEach(authGroup => {
                groupMap[authGroup] = user.groups.includes(authGroup)
            })
        }

        setUserGroups(groupMap)
    }, [user])

    const sanitizeTokenData = token => {
        const accessToken = { ...token }
        const expirationDate = new Date()

        expirationDate.setSeconds(expirationDate.getSeconds() + accessToken.expires_in)
        accessToken.expirationDate = expirationDate

        return accessToken
    }

    const handleAuthentication = ({ username, password, isLogin, isUserRecovery }) => {
        const authData = {}

        if (isLogin) {
            dispatch({ type: AuthActions.LOGIN_LOADING })
        }

        const authResponse = (
            isLogin
                ? authService.getNewAccessToken({ username, password })
                : authService.refreshAccessToken()
        )

        authResponse
            .then(res => {
                const token = sanitizeTokenData(res.data)

                authData.accessToken = token
                localStorage.setItem('CenterIurisAccessToken', JSON.stringify(token))

                return authService.getCurrentUser()
            })
            .then(res => {
                authData.user = res.data
                const type = isLogin ? AuthActions.LOGIN_SUCCESS : AuthActions.REFRESH_TOKEN_SUCCESS
                dispatch({ type, payload: authData })
            })
            .then(() => scheduleTokenRefresh())
            .catch(() => {
                if (isLogin) {
                    dispatch({ type: AuthActions.LOGIN_ERROR })
                } else if (isUserRecovery) {
                    dispatch({ type: AuthActions.RECOVER_USER_ERROR })
                } else {
                    scheduleLogout()
                }
            })
    }

    const login = ({ username, password }) => (
        handleAuthentication({ username, password, isLogin: true })
    )

    const fetchUserData = () => (
        authService.getCurrentUser()
            .then(res => (
                dispatch({
                    type: AuthActions.FETCH_USER_DATA_SUCCESS,
                    payload: { user: res.data }
                })
            ))
    )

    const recoverUser = () => {
        showLoader('Comprobando usuario...')

        fetchUserData()
            .then(scheduleTokenRefresh)
            .catch(() => refreshToken(true))
            .finally(hideLoader)
    }

    const updateUser = userData => {
        dispatch({
            type: AuthActions.UPDATE_USER,
            payload: { user: userData }
        })
    }

    const refreshToken = (isUserRecovery = false) => (
        handleAuthentication({ isUserRecovery })
    )

    const scheduleTokenRefresh = () => {
        const tokenData = JSON.parse(localStorage.getItem('CenterIurisAccessToken'))

        clearTimeout(tokenRefreshTimeoutId)

        const currentDate = new Date()
        const refreshDate = new Date(tokenData.expirationDate)
        refreshDate.setHours(refreshDate.getHours() - TOKEN_REFRESH_HOURS_THRESHOLD)

        let timeout = refreshDate.getTime() - currentDate.getTime()
        timeout = (timeout > 0) ? timeout : 0

        const timeoutId = setTimeout(refreshToken, timeout)

        dispatch({
            type: AuthActions.REFRESH_SCHEDULING_SUCCESS,
            payload: { timeoutId }
        })
    }

    const scheduleLogout = () => {
        const timeout = MINUTES_UNTIL_LOGOUT * 60 * 1000
        const timeoutId = setTimeout(logout, timeout)
        dispatch({
            type: AuthActions.REFRESH_TOKEN_ERROR,
            payload: { timeoutId }
        })
        openWarningDialog({
            title: 'Tu sesión caducará pronto',
            content: `Tu sesión caducará en ${MINUTES_UNTIL_LOGOUT}
                      minutos. Para evitar que la sesión se cierre de manera
                      automática, puedes cerrarla ahora e iniciarla de nuevo
                      y así evitar posibles problemas.`,
            size: 'tiny'
        })
    }

    const logout = () => {
        clearTimeout(tokenRefreshTimeoutId)
        clearTimeout(logoutTimeoutId)
        dispatch({ type: AuthActions.LOGOUT })
        localStorage.removeItem('CenterIurisAccessToken')
    }

    const isUserInAnyGroup = groupList => {
        if (!groupList?.length) {
            return true
        }

        if (!user) {
            return false
        }

        return user.groups.some(group => groupList.includes(group))
    }

    return {
        ...authData,
        login,
        fetchUserData,
        recoverUser,
        updateUser,
        refreshToken,
        scheduleTokenRefresh,
        scheduleLogout,
        logout,
        userGroups,
        isUserInAnyGroup,
        isCheckInProgress
    }
}

export default useAuth