const dialogTypes = {
    OK: 'OK',
    OK_CANCEL: 'OK_CANCEL',
}

const dialogStates = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
}

const defaultTitles = {
    [dialogStates.INFO]: 'Información',
    [dialogStates.WARNING]: 'Advertencia',
    [dialogStates.ERROR]: 'Ha tenido lugar un error',
    [dialogStates.SUCCESS]: 'Operación completada',
}

const defaultMessages = {
    [dialogStates.INFO]: '',
    [dialogStates.WARNING]: '',
    [dialogStates.ERROR]: 'Ha tenido lugar un error inesperado. Por favor, contacta con el administrador de la plataforma.',
    [dialogStates.SUCCESS]: 'La operación ha sido completada correctamente.',
}

export {
    dialogTypes,
    dialogStates,
    defaultTitles,
    defaultMessages,
}