import { buildUrlQuery } from 'assets/utils/common'
import { axiosInstance } from './config'


const recordsEndpoint = '/api/records'

const getDocumentTypesCatalogue = () => (
    axiosInstance.get(`${recordsEndpoint}/types/document-types/catalogue`)
)

const getRecordTypesCatalogue = () => (
    axiosInstance.get(`${recordsEndpoint}/types/catalogue`)
)

const getRecordTypes = (pageNumber, pageSize) => {
    const queryUrl = buildUrlQuery({
        page: pageNumber,
        page_size: pageSize,
    })
    return axiosInstance.get(`${recordsEndpoint}/types${queryUrl}`)
}

const createRecordType = (data) => (
    axiosInstance.post(`${recordsEndpoint}/type`, data)
)

const updateRecordType = (id, data) => (
    axiosInstance.put(`${recordsEndpoint}/types/${id}`, data)
)

const deleteRecordType = (id) => (
    axiosInstance.delete(`${recordsEndpoint}/types/${id}`)
)

const getRecord = (id) => (
    axiosInstance.get(`${recordsEndpoint}/${id}`)
)

const getRecords = (pageNumber, pageSize, filters) => {
    const queryUrl = buildUrlQuery({
        page: pageNumber,
        page_size: pageSize,
        ...filters,
    })
    return axiosInstance.get(`${recordsEndpoint}/${queryUrl}`)
}

const createRecord = (data) => (
    axiosInstance.post(`${recordsEndpoint}/new`, data)
)

const updateRecord = (id, data) => (
    axiosInstance.put(`${recordsEndpoint}/${id}`, data)
)

const updateRecordDescription = (id, data) => (
    axiosInstance.put(`${recordsEndpoint}/${id}/description`, data)
)

const uploadRecordFiles = (id, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CIbyLZZ"'
    }

    return axiosInstance.post(`${recordsEndpoint}/${id}/upload`, data, { headers })
}

const getRecordFiles = (id) => (
    axiosInstance.get(`${recordsEndpoint}/${id}/files`)
)

const downloadRecordFile = (id, fileName) => {
    const queryUrl = buildUrlQuery({
        file_name: encodeURIComponent(fileName),
    })
    return axiosInstance.get(`${recordsEndpoint}/${id}/files/download${queryUrl}`)
}

const downloadRecordFiles = (id) => (
    axiosInstance.get(`${recordsEndpoint}/${id}/files/download-all`, {responseType: 'blob'})
)

const exportRecordsXlsx = (fromDate, untilDate) => {
    const queryUrl = buildUrlQuery({
        from_date: fromDate,
        until_date: untilDate,
    })
    return axiosInstance.get(`${recordsEndpoint}/export${queryUrl}`, {responseType: 'blob'})
}

export default {
    getDocumentTypesCatalogue,
    getRecordTypesCatalogue,
    getRecordTypes,
    createRecordType,
    updateRecordType,
    deleteRecordType,
    getRecord,
    getRecords,
    createRecord,
    updateRecord,
    updateRecordDescription,
    uploadRecordFiles,
    getRecordFiles,
    downloadRecordFile,
    downloadRecordFiles,
    exportRecordsXlsx,
}