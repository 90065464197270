import { axiosInstance } from './config'


const agendaEndpoint = '/api/agenda'

const getOverview = () => (
    axiosInstance.get(`${agendaEndpoint}/overview`)
)

const getDayEvents = (year, month, day) => (
    axiosInstance.get(`${agendaEndpoint}/${year}/${month}/${day}`)
)

const getAvailableGuests = () => (
    axiosInstance.get(`${agendaEndpoint}/available-guests`)
)

const createEvent = (data) => (
    axiosInstance.post(`${agendaEndpoint}/events`, data)
)

const updateEvent = (eventId, data) => (
    axiosInstance.put(`${agendaEndpoint}/events/${eventId}`, data)
)

const updateEventReminders = (eventId, data) => (
    axiosInstance.put(`${agendaEndpoint}/events/${eventId}/reminders`, data)
)

const deleteEvent = (eventId) => (
    axiosInstance.delete(`${agendaEndpoint}/events/${eventId}`)
)

export default {
    getOverview,
    getDayEvents,
    getAvailableGuests,
    createEvent,
    updateEvent,
    updateEventReminders,
    deleteEvent,
}