import React, { useEffect, useRef, useState } from 'react'

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { PICTURE_CROP_FORM_ID } from 'assets/constants/forms'
import useDebounceEffect from 'hooks/useDebounceEffect'
import styles from './ImageCropper.module.scss'


const ImageCropper = (props) => {
    const { className, src, onCropSubmit } = props

    const aspect = 1
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const imgRef = useRef(null)
    const previewCanvasRef = useRef(null)

    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => (
        centerCrop(
            makeAspectCrop({ unit: '%', width: 100 }, aspect, mediaWidth, mediaHeight),
            mediaWidth,
            mediaHeight,
        )
    )

    const onImageLoad = (event) => {
        const { width, height } = event.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
    }

    const canvasPreview = async (image, canvas, crop, scale = 1, rotate = 0) => {
        const TO_RADIANS = Math.PI / 180
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            throw new Error('No 2d context')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const pixelRatio = window.devicePixelRatio

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'

        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY

        const rotateRads = rotate * TO_RADIANS
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2

        ctx.save()

        ctx.translate(-cropX, -cropY)
        ctx.translate(centerX, centerY)
        ctx.rotate(rotateRads)
        ctx.scale(scale, scale)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        )

        ctx.restore()
    }

    const canvasToBlob = () => (
        new Promise((resolve, reject) => {
            previewCanvasRef.current.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'))
                        return
                    }
                    resolve(blob)
                },
                'image/jpeg',
                1
            )
        })
    )

    const handleCropSubmit = (e) => {
        e.preventDefault()
        canvasToBlob()
            .then((res) => {
                const formData = new FormData()
                formData.append('picture', res)
                onCropSubmit(formData)
            })
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
            }
        },
        100,
        [completedCrop],
    )

    return (
        <div className={className || ''}>
            <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                minWidth={150}
                aspect={aspect}
            >
                <img
                    ref={imgRef}
                    src={src}
                    onLoad={onImageLoad}
                />
            </ReactCrop>
            {
                !!completedCrop &&
                <canvas
                    className={styles.PreviewCanvas}
                    ref={previewCanvasRef}
                    style={{
                        width: completedCrop.width,
                        height: completedCrop.height,
                    }}
                />
            }
            <form id={PICTURE_CROP_FORM_ID} className={styles.CropForm} onSubmit={handleCropSubmit} />
        </div>
    )
}

export default ImageCropper