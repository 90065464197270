import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Icon, List } from 'semantic-ui-react'

import { RECORD_TYPE_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { INIT_PAGE_SIZE } from 'assets/constants/pagination'
import BasicButton from 'components/UI/BasicButton/BasicButton'
import CompactTable from 'components/UI/CompactTable/CompactTable'
import InfoMessage from 'components/UI/InfoMessage/InfoMessage'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import SecondaryActionButton from 'components/UI/SecondaryActionButton/SecondaryActionButton'
import TableFooterPagination from 'components/UI/TableFooterPagination/TableFooterPagination'
import RecordTypeForm from 'components/RecordTypeForm/RecordTypeForm'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import usePagination from 'hooks/usePagination'
import recordsService from 'services/records'
import styles from './RecordTypes.module.scss'


const RecordTypes = () => {
    const [formattedRows, setFormattedRows] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [pageSize, setPageSize] = useState(INIT_PAGE_SIZE)
    const { openBasicMessageDialog, openErrorDialog, openWarningQuestionDialog, closeDialog, setConfirming, refreshData } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const navigate = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const [typesData, setTypesData] = usePagination()
    const currentPageRef = useRef()
    const pageSizeRef = useRef()

    const { currentPage, lastPage, nextPage, previousPage, results, total } = typesData
    currentPageRef.current = currentPage
    pageSizeRef.current = pageSize

    const columns = [
        { content: 'Tipo de expediente' },
        { content: 'Documentos requeridos' },
    ]

    const fetchRecordTypes = (params) => {
        const { avoidLoader, page, size } = params || {}

        !avoidLoader && showLoader('Cargando tipos de expediente...')

        recordsService.getRecordTypes((page || currentPageRef.current || 1), (size || pageSizeRef.current || INIT_PAGE_SIZE))
            .then((res) => {
                const rows = res.data.results.map((result) => ({
                    id: result.id,
                    onClick: () => handleRecordClick(result),
                    cells: [
                        { content: <strong>{result.name}</strong> },
                        { content: (
                            (result.document_types?.length > 0)
                            ? (
                                <List as='ol'>
                                    {result.document_types.map((type) => (
                                        <List.Item key={type.name} as='li' value='·'>
                                            {type.name}
                                        </List.Item>
                                    ))}
                                </List>
                            )
                            : <i>Ninguno</i>
                        )},
                    ],
                }))
                setTypesData(res.data)
                setFormattedRows(rows)
                size && setPageSize(size)

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                // 404 can happen due to wrong page number for high page sizes,
                // so we simply show the first page for the given page size, but
                // if we have a 404 after trying this we must show an error
                if ((error.response.status === 404) && (page !== 1)) {
                    fetchRecordTypes({ page: 1, size })
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los tipos de expediente.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
    }

    const handleCreateButtonClick = () => (
        openBasicMessageDialog({
            title: 'Nuevo tipo de expediente',
            render: RecordTypeForm,
            renderProps: {
                onSubmit: handleRecordTypeCreation,
                errors: formErrors,
            },
            size: 'tiny',
            scrolling: false,
            confirmSubmitsForm: RECORD_TYPE_FORM_ID,
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    )

    const handleDeleteButtonClick = (id) => (
        openWarningQuestionDialog({
            title: 'Eliminar tipo de expediente',
            content: '¿Seguro que quieres eliminar este tipo de expediente?',
            size: 'mini',
            onConfirm: () => handleRecordTypeDeletion(id)
        })
    )

    const handleRecordClick = (record) => (
        openBasicMessageDialog({
            title: 'Editar tipo de expediente',
            render: RecordTypeForm,
            renderProps: {
                onSubmit: (formData) => handleRecordTypeUpdate(record.id, formData),
                errors: formErrors,
                initialValues: {
                    ...record,
                    document_types: record.document_types.map((type) => type.id)
                }
            },
            size: 'tiny',
            scrolling: false,
            confirmSubmitsForm: RECORD_TYPE_FORM_ID,
            secondaryActions: (
                <SecondaryActionButton icon='trash' onClick={() => handleDeleteButtonClick(record.id)}>
                    Eliminar
                </SecondaryActionButton>
            ),
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    )

    const handleRecordTypeCreation = (formData) => {
        setConfirming(true)

        recordsService.createRecordType(formData)
            .then(fetchRecordTypes)
            .then(() => {
                setFormErrors(null)
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setConfirming(false))
    }

    const handleRecordTypeUpdate = (id, formData) => {
        setConfirming(true)

        recordsService.updateRecordType(id, formData)
            .then(fetchRecordTypes)
            .then(() => {
                setFormErrors(null)
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setConfirming(false))
    }

    const handleRecordTypeDeletion = (id) => {
        setConfirming(true)

        recordsService.deleteRecordType(id)
            .then(fetchRecordTypes)
            .then(closeDialog)
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al eliminar el tipo de expediente.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al eliminar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => setConfirming(false))
    }

    useEffect(() => {
        fetchRecordTypes()
    }, [])

    useEffect(() => {
        refreshData({ errors: formErrors })
    }, [formErrors])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.RECORD_TYPE_CREATED,
            eventCodes.RECORD_TYPE_DELETED,
            eventCodes.RECORD_TYPE_UPDATED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchRecordTypes({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Tipos de expediente - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='list alternate outline'
                header='Tipos de expediente'
            />
            <article className={styles.Content}>
                <div className={styles.MainPanel}>
                    <header className={styles.OptionsBar}>
                        <BasicButton
                            compact
                            icon
                            color='darkgreen'
                            labelPosition='right'
                            onClick={handleCreateButtonClick}
                        >
                            <Icon name='plus circle' />
                            Crear nuevo
                        </BasicButton>
                    </header>
                    <div className={`${styles.DataWrapper}${(results?.length === 0) ? ` ${styles.NoData}` : ''}`}>
                        {
                            (results?.length > 0) &&
                            <>
                                <CompactTable
                                    className={styles.RecordTypesTable}
                                    columns={columns}
                                    rows={formattedRows}
                                    noResultsRow={{
                                        className: styles.NoResultsRow,
                                        cell: {
                                            className: styles.NoResultsCell,
                                            content: (
                                                <strong><i>El filtro no ha devuelto ningún tipo de expediente</i></strong>
                                            )
                                        },
                                    }}
                                />
                                <div className={styles.PaginationManagement}>
                                    <TableFooterPagination
                                        currentPage={currentPage}
                                        lastPage={lastPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        pageSize={pageSize}
                                        totalItems={total}
                                        pageItems={results?.length}
                                        onPageChange={(page) => fetchRecordTypes({ page })}
                                        onPageSizeChange={(size) => fetchRecordTypes({ size })}
                                    />
                                </div>
                            </>
                        }
                        {
                            (results?.length === 0) &&
                            <InfoMessage
                                className={styles.NoItemsMessage}
                                title='No hay tipos de expediente'
                                content='Actualmente no hay ningún tipo de expediente registrado'
                            />
                        }
                    </div>
                </div>
            </article>
        </>
    )
}

export default RecordTypes