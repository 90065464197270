import { axiosInstance } from './config'


const communicationEndpoint = '/api/communication'

const getConversation = (recordId) => (
    axiosInstance.get(`${communicationEndpoint}/conversations/${recordId}`)
)

const sendMessage = (recordId, data) => (
    axiosInstance.post(`${communicationEndpoint}/conversations/${recordId}/new-message`, data)
)

const makeMessageConfidential = (messageId) => (
    axiosInstance.put(`${communicationEndpoint}/messages/${messageId}/make-confidential`)
)

const makeMessagePublic = (messageId) => (
    axiosInstance.put(`${communicationEndpoint}/messages/${messageId}/make-public`)
)

const deleteMessage = (messageId) => (
    axiosInstance.delete(`${communicationEndpoint}/messages/${messageId}`)
)

export default {
    getConversation,
    sendMessage,
    makeMessageConfidential,
    makeMessagePublic,
    deleteMessage,
}