import { buildUrlQuery } from 'assets/utils/common'
import { axiosInstance } from './config'


const businessEndpoint = '/api/business'

const getPartnersCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/partners-catalogue`)
)

const getInsuredsCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/insureds-catalogue`)
)

const getInsurancesCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/insurances-catalogue`)
)

const getFilterPartnersCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/filter/partners-catalogue`)
)

const getFilterInsuredsCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/filter/insureds-catalogue`)
)

const getFilterInsurancesCatalogue = () => (
    axiosInstance.get(`${businessEndpoint}/filter/insurances-catalogue`)
)

const getPartners = (pageNumber, pageSize) => {
    const queryUrl = buildUrlQuery({
        page: pageNumber,
        page_size: pageSize,
    })
    return axiosInstance.get(`${businessEndpoint}/partners${queryUrl}`)
}

const getInsureds = (pageNumber, pageSize) => {
    const queryUrl = buildUrlQuery({
        page: pageNumber,
        page_size: pageSize,
    })
    return axiosInstance.get(`${businessEndpoint}/insureds${queryUrl}`)
}

const getInsurances = (pageNumber, pageSize) => {
    const queryUrl = buildUrlQuery({
        page: pageNumber,
        page_size: pageSize,
    })
    return axiosInstance.get(`${businessEndpoint}/insurances${queryUrl}`)
}

const createPartner = (data) => (
    axiosInstance.post(`${businessEndpoint}/partner`, data)
)

const createInsured = (data) => (
    axiosInstance.post(`${businessEndpoint}/insured`, data)
)

const createInsurance = (data) => (
    axiosInstance.post(`${businessEndpoint}/insurance`, data)
)

const updatePartner = (id, data) => (
    axiosInstance.put(`${businessEndpoint}/partners/${id}`, data)
)

const updateInsured = (id, data) => (
    axiosInstance.put(`${businessEndpoint}/insureds/${id}`, data)
)

const updateInsurance = (id, data) => (
    axiosInstance.put(`${businessEndpoint}/insurances/${id}`, data)
)

const deletePartner = (id) => (
    axiosInstance.delete(`${businessEndpoint}/partners/${id}`)
)

const deleteInsured = (id) => (
    axiosInstance.delete(`${businessEndpoint}/insureds/${id}`)
)

const deleteInsurance = (id) => (
    axiosInstance.delete(`${businessEndpoint}/insurances/${id}`)
)

const getInsuredExists = (taxId) => {
    const queryUrl = buildUrlQuery({ tax_id: taxId })
    return axiosInstance.get(`${businessEndpoint}/insured/exists${queryUrl}`)
}

export default {
    getPartnersCatalogue,
    getInsuredsCatalogue,
    getInsurancesCatalogue,
    getFilterPartnersCatalogue,
    getFilterInsuredsCatalogue,
    getFilterInsurancesCatalogue,
    getPartners,
    getInsureds,
    getInsurances,
    createPartner,
    createInsured,
    createInsurance,
    updatePartner,
    updateInsured,
    updateInsurance,
    deletePartner,
    deleteInsured,
    deleteInsurance,
    getInsuredExists,
}