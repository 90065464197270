import React from 'react'

import { Table } from 'semantic-ui-react'

import styles from './LargeTable.module.scss'


const LargeTable = (props) => {
    const { className, rows, columns } = props

    return (
        <Table
            stackable
            striped
            basic='very'
            size='large'
            className={`${styles.LargeTable}${className ? ` ${className}` : ''}`}
        >
            <Table.Header>
                <Table.Row>
                    {columns?.map(({ content, width, className }, index) => (
                        <Table.HeaderCell width={width} key={index} className={className}>
                            {content}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows?.map(({ cells, className }, rowIndex) => (
                    <Table.Row key={rowIndex} className={className}>
                        {cells?.map(({ content, className }, cellIndex) => (
                            <Table.Cell key={cellIndex} className={className}>
                                {content}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

export default LargeTable