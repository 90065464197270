import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import { INSURED_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { INIT_PAGE_SIZE } from 'assets/constants/pagination'
import BasicButton from 'components/UI/BasicButton/BasicButton'
import CompactTable from 'components/UI/CompactTable/CompactTable'
import InfoMessage from 'components/UI/InfoMessage/InfoMessage'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import SecondaryActionButton from 'components/UI/SecondaryActionButton/SecondaryActionButton'
import TableFooterPagination from 'components/UI/TableFooterPagination/TableFooterPagination'
import InsuredForm from 'components/InsuredForm/InsuredForm'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import usePagination from 'hooks/usePagination'
import businessService from 'services/business'
import styles from './Insureds.module.scss'


const Insureds = () => {
    const [formattedRows, setFormattedRows] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [pageSize, setPageSize] = useState(INIT_PAGE_SIZE)
    const { openBasicMessageDialog, openErrorDialog, openWarningQuestionDialog, closeDialog, setConfirming, refreshData } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const navigate = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const [insuredsData, setInsuredsData] = usePagination()
    const currentPageRef = useRef()
    const pageSizeRef = useRef()

    const { currentPage, lastPage, nextPage, previousPage, results, total } = insuredsData
    currentPageRef.current = currentPage
    pageSizeRef.current = pageSize

    const columns = [
        { content: 'Nombre', width: 4 },
        { content: 'NIF/CIF', width: 2 },
        { content: 'Contacto', width: 3 },
        { content: 'Teléfono principal', width: 3 },
        { content: 'Email', width: 4 },
    ]

    const fetchInsureds = (params) => {
        const { avoidLoader, page, size } = params || {}

        !avoidLoader && showLoader('Cargando asegurados...')

        businessService.getInsureds((page || currentPageRef.current || 1), (size || pageSizeRef.current || INIT_PAGE_SIZE))
            .then((res) => {
                const rows = res.data.results.map((result) => ({
                    id: result.id,
                    onClick: () => handleRecordClick(result),
                    cells: [
                        { content: <strong>{result.name || '-'}</strong> },
                        { content: result.tax_id || '-' },
                        { content: result.contact || '-' },
                        { content: result.main_phone || '-' },
                        { content: result.email || '-' },
                    ],
                }))
                setInsuredsData(res.data)
                setFormattedRows(rows)
                size && setPageSize(size)

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                // 404 can happen due to wrong page number for high page sizes,
                // so we simply show the first page for the given page size, but
                // if we have a 404 after trying this we must show an error
                if ((error.response.status === 404) && (page !== 1)) {
                    fetchInsureds({ page: 1, size })
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los asegurados.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
    }

    const handleCreateButtonClick = () => (
        openBasicMessageDialog({
            title: 'Nuevo asegurado',
            render: InsuredForm,
            renderProps: {
                onSubmit: handleInsuredCreation,
                errors: formErrors,
            },
            confirmSubmitsForm: INSURED_FORM_ID,
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    )

    const handleDeleteButtonClick = (id) => (
        openWarningQuestionDialog({
            title: 'Eliminar asegurado',
            content: '¿Seguro que quieres eliminar este asegurado?',
            size: 'mini',
            onConfirm: () => handleInsuredDeletion(id)
        })
    )

    const handleRecordClick = (record) => (
        openBasicMessageDialog({
            title: 'Editar asegurado',
            render: InsuredForm,
            renderProps: {
                onSubmit: (formData) => handleInsuredUpdate(record.id, formData),
                errors: formErrors,
                initialValues: { ...record }
            },
            confirmSubmitsForm: INSURED_FORM_ID,
            secondaryActions: (
                <SecondaryActionButton icon='trash' onClick={() => handleDeleteButtonClick(record.id)}>
                    Eliminar
                </SecondaryActionButton>
            ),
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    )

    const handleInsuredCreation = (formData) => {
        setConfirming(true)

        businessService.createInsured(formData)
            .then(fetchInsureds)
            .then(() => {
                setFormErrors(null)
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setConfirming(false))
    }

    const handleInsuredUpdate = (id, formData) => {
        setConfirming(true)

        businessService.updateInsured(id, formData)
            .then(fetchInsureds)
            .then(() => {
                setFormErrors(null)
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setConfirming(false))
    }

    const handleInsuredDeletion = (id) => {
        setConfirming(true)

        businessService.deleteInsured(id)
            .then(fetchInsureds)
            .then(closeDialog)
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al eliminar el asegurado.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al eliminar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => setConfirming(false))
    }

    useEffect(() => {
        fetchInsureds()
    }, [])

    useEffect(() => {
        refreshData({ errors: formErrors })
    }, [formErrors])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.INSURED_CREATED,
            eventCodes.INSURED_DELETED,
            eventCodes.INSURED_UPDATED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchInsureds({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Asegurados - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='life ring outline'
                header='Asegurados'
            />
            <article className={styles.Content}>
                <div className={styles.MainPanel}>
                    <header className={styles.OptionsBar}>
                        <BasicButton
                            compact
                            icon
                            color='darkgreen'
                            labelPosition='right'
                            onClick={handleCreateButtonClick}
                        >
                            <Icon name='plus circle' />
                            Crear nuevo
                        </BasicButton>
                    </header>
                    <div className={`${styles.DataWrapper}${(results?.length === 0) ? ` ${styles.NoData}` : ''}`}>
                        {
                            (results?.length > 0) &&
                            <>
                                <CompactTable
                                    className={styles.InsuredsTable}
                                    columns={columns}
                                    rows={formattedRows}
                                    noResultsRow={{
                                        className: styles.NoResultsRow,
                                        cell: {
                                            className: styles.NoResultsCell,
                                            content: (
                                                <strong><i>El filtro no ha devuelto ningún asegurado</i></strong>
                                            )
                                        },
                                    }}
                                />
                                <div className={styles.PaginationManagement}>
                                    <TableFooterPagination
                                        currentPage={currentPage}
                                        lastPage={lastPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        pageSize={pageSize}
                                        totalItems={total}
                                        pageItems={results?.length}
                                        onPageChange={(page) => fetchInsureds({ page })}
                                        onPageSizeChange={(size) => fetchInsureds({ size })}
                                    />
                                </div>
                            </>
                        }
                        {
                            (results?.length === 0) &&
                            <InfoMessage
                                className={styles.NoItemsMessage}
                                title='No hay asegurados'
                                content='Actualmente no hay ningún asegurado registrado'
                            />
                        }
                    </div>
                </div>
            </article>
        </>
    )
}

export default Insureds