import React, { useMemo } from 'react'

import { Button, Dropdown, Icon, Table } from 'semantic-ui-react'

import { MAX_PAGE_SIZE } from 'assets/constants/pagination'
import styles from './TableFooterPagination.module.scss'


const TableFooterPagination = (props) => {
    const {
        className,
        currentPage,
        lastPage,
        previousPage,
        nextPage,
        pageSize,
        onPageChange,
        onPageSizeChange,
        totalItems,
        pageItems,
    } = props

    const resultsNumberInfo = useMemo(() => {
        const firstElementNumber = ((currentPage === 'last' ? lastPage : currentPage) - 1) * pageSize + 1
        const lastElementNumber = (firstElementNumber + pageItems) - 1
        return `${firstElementNumber}-${lastElementNumber} de ${totalItems}`
    }, [currentPage, pageSize, pageItems, totalItems])

    return (
        <Table className={styles.TableFooterPagination}>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell width={10}>
                        <strong>Resultados por página:</strong>
                        <Dropdown
                            inline
                            scrolling
                            upward
                            options={Array.from({length: MAX_PAGE_SIZE}, (_, i) => ({
                                key: i + 1, text: i + 1, value: i + 1, content: i + 1
                            }))}
                            value={pageSize}
                            onChange={(event, result) => {
                                const { value } = result || event.target
                                onPageSizeChange(value)
                            }}
                            className={styles.PageSizeDropdown}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3} className={styles.ResultsNumberInfo}>
                        <strong>{resultsNumberInfo}</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3} className={styles.SwitchPageButtons}>
                        <Button
                            basic
                            compact
                            icon
                            className={styles.SwitchPageButton}
                            disabled={previousPage === null}
                            onClick={() => (previousPage !== null) && onPageChange(previousPage)}
                        >
                            <Icon name='chevron left' />
                        </Button>
                        <Button
                            basic
                            compact
                            icon
                            className={styles.SwitchPageButton}
                            disabled={nextPage === null}
                            onClick={() => (nextPage !== null) && onPageChange(nextPage)}
                        >
                            <Icon name='chevron right' />
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}

export default TableFooterPagination