import React, { useEffect, useRef, useState } from 'react'

import { Form, Header, Icon, Image } from 'semantic-ui-react'

import { PICTURE_CROP_FORM_ID } from 'assets/constants/forms'
import { MAX_IMAGE_SIZE } from 'assets/constants/images'
import ImageCropper from 'components/UI/ImageCropper/ImageCropper'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import accountsService from 'services/accounts'
import styles from './ProfilePicturePanel.module.scss'


const ProfilePicturePanel = (props) => {
    const { className, onUpdate, user } = props

    const [emptyFileList, setEmptyFileList] = useState(null)
    const [imageSrc, setImageSrc] = useState(null)
    const { openErrorDialog, openSuccessDialog, openBasicMessageDialog, closeDialog, setConfirming } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const inputRef = useRef(null)

    const handleFileSelection = (event) => {
        if (!event.target.files?.length) {
            return
        }

        let newFile = event.target.files[0]
        event.target.files = emptyFileList

        if (newFile.size > MAX_IMAGE_SIZE) {
            openErrorDialog({
                title: 'Imagen demasiado grande',
                content: `El tamaño máximo permitido de la imagen es de 2MB.
                          Por favor, elige otra imagen y vuelve a intentarlo.`,
                size: 'mini',
            })
            return
        }

        const reader = new FileReader()
        reader.addEventListener('load', () => (
            setImageSrc(reader.result.toString() || '')
        ))
        reader.readAsDataURL(newFile)
    }

    const restoreDefaultPicture = () => {
        showLoader('Restaurando imagen por defecto...')

        accountsService.restoreDefaultPicture(user.id)
            .then((res) => {
                hideLoader()
                onUpdate?.(res.data)
                openSuccessDialog({
                    title: 'Imagen restaurada',
                    content: 'La imagen por defecto se ha restaurado correctamente.',
                    size: 'mini',
                })
            })
            .catch((error) => {
                hideLoader()

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al restaurar la imagen.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al restaurar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }

    const setNewPicture = (data) => {
        setConfirming(true)

        accountsService.uploadNewPicture(user.id, data)
            .then((res) => {
                setConfirming(false)
                onUpdate?.(res.data)
                openSuccessDialog({
                    title: 'Imagen cambiada',
                    content: 'La imagen de perfil se ha cambiado correctamente.',
                    size: 'mini',
                })
            })
            .catch((error) => {
                setConfirming(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cambiar la imagen.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cambiar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }

    useEffect(() => {
        if (inputRef.current && !emptyFileList) {
            setEmptyFileList(inputRef.current.files)
        }
    }, [inputRef])

    useEffect(() => {
        if (!imageSrc) {
            return
        }

        openBasicMessageDialog({
            title: 'Recortar imagen',
            render: ImageCropper,
            renderProps: {
                onCropSubmit: (imageData) => setNewPicture(imageData),
                className: styles.CroppableImage,
                src: imageSrc
            },
            scrolling: false,
            size: 'tiny',
            confirmSubmitsForm: PICTURE_CROP_FORM_ID,
        })
    }, [imageSrc])

    return (
        <article className={`${styles.ProfilePicturePanel}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.PanelHeader}>
                Imagen de perfil
            </Header>
            <div className={styles.EditingArea}>
                <Image
                    src={user?.picture}
                    className={styles.ProfilePicture}
                    size='small'
                    bordered
                />
                <span
                    className={styles.RestorePictureOption}
                    onClick={restoreDefaultPicture}
                >
                    Restablecer imagen por defecto
                </span>
                <label htmlFor='picture-input' className={styles.ChangePictureOption}>
                    Cambiar imagen
                </label>
                <input
                    id='picture-input'
                    type='file'
                    accept='image/*'
                    ref={inputRef}
                    onChange={handleFileSelection}
                />
            </div>
        </article>
    )
}

export default ProfilePicturePanel

