import React, { useEffect, useState } from 'react'

import { Routes, useLocation, useNavigate, useRoutes } from 'react-router-dom'

import { paths } from 'assets/constants/navigation'
import useAuth from 'hooks/useAuth'
import useLoader from 'hooks/useLoader'
import routes from 'routes'
import authService from 'services/auth'
import './App.scss'


const App = () => {
    const [nextUrl, setNextUrl] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const appRoutes = useRoutes(routes())
    const { isCheckInProgress, recoverUser, user } = useAuth()
    const { showLoader, hideLoader } = useLoader()

    // User recovery
    useEffect(() => {
        if (isCheckInProgress) {
            recoverUser()
        }
    }, [isCheckInProgress])

    // Storing/accessing next URL if login needed before
    useEffect(() => {
        const currentUrl = location.pathname

        if (isCheckInProgress !== false) {
            return
        }

        if (!user && paths.needsLogin(currentUrl) && !paths.isHome(currentUrl)) {
            setNextUrl(currentUrl)
            navigate(paths.LOGIN)
        } else if (user && nextUrl) {
            navigate(nextUrl)
            setNextUrl(null)
        }
    }, [isCheckInProgress, location.pathname, user])

    return (
        isCheckInProgress === false ? appRoutes : null
    )
}

export default App
