import React, { useEffect, useState } from 'react'

import { Form, Message } from 'semantic-ui-react'

import { RECORD_TYPE_FORM_ID } from 'assets/constants/forms'
import useDialog from 'hooks/useDialog'
import recordsService from 'services/records'
import styles from './RecordTypeForm.module.scss'


const RecordTypeForm = (props) => {
    const { initialValues, errors, onSubmit } = props
    const [documentTypes, setDocumentTypes] = useState([])
    const { openErrorDialog } = useDialog()

    const [data, setData] = useState({
        name: initialValues?.name || '',
        document_types: initialValues?.document_types || [],
    })

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    useEffect(() => {
        recordsService.getDocumentTypesCatalogue()
            .then((res) => {
                const options = res.data.map((type) => ({
                    key: type.id,
                    value: type.id,
                    text: type.name,
                }))
                setDocumentTypes(options)
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los tipos de documento.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }, [])

    return (
        <Form
            id={RECORD_TYPE_FORM_ID}
            className={styles.RecordTypeForm}
            onSubmit={() => onSubmit(data)}
            error={!!errors}
        >
            <Form.Input
                required
                disabled={!documentTypes.length}
                error={!!errors?.name}
                label='Nombre del tipo'
                placeholder='Nombre del tipo'
                name='name'
                value={data.name || ''}
                onChange={handleChange}
            />
            <Form.Select
                multiple
                search
                disabled={!documentTypes.length}
                loading={!documentTypes.length}
                options={documentTypes}
                label='Documentos a solicitar'
                placeholder='Documentos a solicitar'
                name='document_types'
                value={data.document_types || []}
                onChange={handleChange}
            />
            {
                errors &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(errors).map(([key, value], index) => (
                            <Message.Item key={index}>
                                {Array.isArray(value) ? value[0] : value}
                            </Message.Item>
                        ))
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default RecordTypeForm