import React from 'react'

import { Button } from 'semantic-ui-react'

import styles from './BasicButton.module.scss'


const BasicButton = (props) => {
    const { children, color, className, ...rest } = props

    const colorMap = {
        'darkgreen': styles.DarkGreen,
        'gray': styles.Gray,
        'red': styles.Red,
    }

    return (
        <Button {...rest} className={`${styles.BasicButton} ${colorMap[color] || colorMap.gray}${className ? ` ${className}` : ''}`}>
            {children}
        </Button>
    )
}

export default BasicButton