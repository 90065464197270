import React, { useEffect, useRef, useState } from 'react'

import { Header, Icon, Label, List, Segment } from 'semantic-ui-react'
import uuid from 'uuid'

import { areFilesEqual } from 'assets/utils/common'
import styles from './FileSelector.module.scss'


const FileSelector = (props) => {
    const { className, initialFiles, onChange } = props

    const [files, setFiles] = useState(initialFiles || null)
    const [emptyFileList, setEmptyFileList] = useState(null)
    const [isDraggingOver, setDraggingOver] = useState(false)
    const inputRef = useRef(null)

    const id = uuid.v4()

    const updateFiles = (selectedFiles) => {
        let newFiles = Array.from(selectedFiles)
        
        if (files) {
            newFiles = newFiles.filter((newFile) => {
                let include = true

                files.forEach((oldFile) => {
                    if (areFilesEqual(newFile, oldFile)) {
                        include = false
                    }
                })

                return include
            })
        }

        setFiles([
            ...(files || []),
            ...newFiles,
        ])
    }

    const handleDrag = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(true)
    }

    const handleDragOut = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(false)
    }

    // This function handles when files are 'dropped'
    const handleDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(false)
        updateFiles(event.dataTransfer.files)
    }

    // This function handles when files are manually selected
    const handleFileSelection = (event) => {
        updateFiles(event.target.files)

        // We set the empty FileList to the input so we do
        // not have problems with the input's value (this
        // solves the problem when selecting a file, deleting
        // it, and trying to select it again)
        inputRef.current.files = emptyFileList
    }

    useEffect(() => {
        if (inputRef.current) {
            setEmptyFileList(inputRef.current.files)
        }
    }, [inputRef])

    useEffect(() => {
        if (files) {
            onChange(files)
        }
    }, [files])

    useEffect(() => {
        setFiles(initialFiles)
    }, [initialFiles])

    return (
        <label
            htmlFor={id}
            className={`${styles.FileSelector}${className ? ` ${className}` : ''}${isDraggingOver ? ` ${styles.DraggedOver}` : ''}`}
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDragOut}
            onDrop={handleDrop}
        >
            <div>
                <span>Seleccionar archivos</span>
                <Icon name='folder open outline' className={styles.UploadIcon} />
            </div>
            <span>
                Haz clic para seleccionar archivos o arrástralos aquí directamente
            </span>
            <input
                multiple
                id={id}
                type='file'
                ref={inputRef}
                onChange={handleFileSelection}
            />
        </label>
    )
}

export default FileSelector