import React, { useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import ReactCalendar from 'react-calendar'
import { Icon } from 'semantic-ui-react'

import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { capitalize, isSameDay } from 'assets/utils/common'
import useNotifications from 'hooks/useNotifications'
import agendaService from 'services/agenda'
import styles from './Calendar.module.scss'
import './Calendar.scss'


const Calendar = (props) => {
    const { className } = props

    const [startDate, setStartDate] = useState(new Date())
    const [createdEventDates, setCreatedEventDates] = useState(null)
    const [invitedEventDates, setInvitedEventDates] = useState(null)
    const navigate = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const locale = navigator.language || navigator.userLanguage

    const activeMonth = startDate.toLocaleString(locale, { month: 'long' })
    const activeYear = startDate.toLocaleString(locale, { year: 'numeric' })

    const nextDate = useMemo(() => {
        const month = startDate.getMonth()
        const year = startDate.getFullYear()
        const nextMonth = (month === 11) ? 0 : (month + 1)
        const nextYear = (nextMonth === 0) ? (year + 1) : year
        return new Date(nextYear, nextMonth, 1)
    }, [startDate])

    const previousDate = useMemo(() => {
        const month = startDate.getMonth()
        const year = startDate.getFullYear()
        const previousMonth = (month === 0) ? 11 : (month - 1)
        const previousYear = (previousMonth === 11) ? (year - 1) : year
        return new Date(previousYear, previousMonth, 1)
    }, [startDate])

    const fetchOverview = () => {
        agendaService.getOverview()
            .then((res) => {
                const { created, invited } = res.data
                setCreatedEventDates(created.map((date) => new Date(date)))
                setInvitedEventDates(invited.map((date) => new Date(date)))
            })
    }

    const customDayClassName = ({ date }) => {
        let customClassName = ''

        if (createdEventDates?.filter((eventDate) => isSameDay(eventDate, date)).length) {
            customClassName = `${styles.CreatedEventDay}`
        }

        if (invitedEventDates?.filter((eventDate) => isSameDay(eventDate, date)).length) {
            customClassName = `${customClassName}${customClassName ? ' ' : ''}${styles.InvitedEventDay}`
        }

        return customClassName
    }

    const handleDaySelection = (value) => {
        const day = value.getDate()
        const month = value.getMonth() + 1
        const year = value.getFullYear()

        navigate(paths.CALENDAR_DAY_EVENTS.replace(':year', year).replace(':month', month).replace(':day', day))
    }

    useEffect(() => {
        fetchOverview()
    }, [])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.CALENDAR_EVENT_CREATED,
            eventCodes.CALENDAR_EVENT_UPDATED,
            eventCodes.CALENDAR_EVENT_DELETED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchOverview()
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <div className={`${styles.Calendar}${className ? ` ${className}` : ''}`}>
            <header className={styles.CalendarHeader}>
                <Icon
                    name='chevron circle left'
                    className={styles.ChangeActiveDateIcon}
                    onClick={() => setStartDate(previousDate)}
                />
                <div className={styles.ActiveDate}>
                    <strong>{capitalize(activeMonth)}</strong> | <strong>{activeYear}</strong>
                </div>
                <Icon
                    name='chevron circle right'
                    className={styles.ChangeActiveDateIcon}
                    onClick={() => setStartDate(nextDate)}
                />
            </header>
            <ReactCalendar
                activeStartDate={startDate}
                showNavigation={false}
                showFixedNumberOfWeeks={true}
                tileClassName={customDayClassName}
                onChange={handleDaySelection}
                value={null}
            />
        </div>
    )
}

export default Calendar