import React from 'react'

import { Table } from 'semantic-ui-react'

import styles from './CompactTable.module.scss'


const CompactTable = (props) => {
    const { className, noResultsRow, rows, columns } = props

    return (
        <Table
            stackable
            className={`${styles.CompactTable}${className ? ` ${className}` : ''}`}
        >
            <Table.Header>
                <Table.Row>
                    {columns?.map(({ content, width, className }, index) => (
                        <Table.HeaderCell width={width} key={index} className={className}>
                            {content}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows?.map(({ cells, className, onClick }, rowIndex) => (
                    <Table.Row
                        key={rowIndex}
                        onClick={onClick}
                        className={`${typeof onClick === 'function' ? `${styles.SelectableRow} ${className}` : className}`}
                    >
                        {cells?.map(({ content, className }, cellIndex) => (
                            <Table.Cell key={cellIndex} className={className}>
                                {content}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
                {
                    (!rows || !rows?.length) &&
                    <Table.Row
                        className={`${styles.NoResultsRow}${noResultsRow?.className ? ` ${noResultsRow?.className}` : ''}`}
                    >
                        <Table.Cell
                            colSpan={columns?.length || 1}
                            className={noResultsRow?.cell?.className}
                        >
                            {
                                noResultsRow?.cell?.content ||
                                <strong><i>Sin resultados</i></strong>
                            }
                        </Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    )
}

export default CompactTable