import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import { paths, pathsRegexes } from 'assets/constants/navigation'
import styles from './SideBarLink.module.scss'


const SideBarLink = (props) => {
    const { url, name, icon, activeForRegex } = props

    const location = useLocation()

    const isActive = () => {
        const regex = activeForRegex ? activeForRegex : pathsRegexes[url]
        return regex?.test(location.pathname)
    }

    return (
        <>
            <Link
                to={url}
                className={`${styles.SideBarLink}${isActive() ? ` ${styles.Active}` : ''}`}
            >
                <Icon name={icon} size='large' className={styles.Icon} />
                <span className={styles.Name}>{name}</span>
            </Link>
        </>
    )
}

export default SideBarLink