import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Icon, Image, Transition } from 'semantic-ui-react'

import { paths } from 'assets/constants/navigation'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import styles from './UserWidget.module.scss'


const UserWidget = (props) => {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const widgetRef = useRef()
    const navigate = useNavigate()
    const { logout, user } = useAuth()
    const { closeDialog, openWarningQuestionDialog } = useDialog()

    const handleClickOutside = (event) => {
        if (!widgetRef.current?.contains(event.target)) {
            setMenuOpen(false)
        }
    }

    const showLogoutConfirmationDialog = () => {
        setMenuOpen(false)
        openWarningQuestionDialog({
            title: 'Cerrar sesión',
            content: '¿Seguro que quieres cerrar la sesión?',
            closable: true,
            size: 'mini',
            onConfirm: () => {
                logout()
                closeDialog()
            },
        })
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className={`${styles.UserWidget}${isMenuOpen ? ` ${styles.Selected}` : ''}`} ref={widgetRef}>
            <div
                className={styles.UserInfo}
                onClick={() => setMenuOpen(!isMenuOpen)}
            >
                <span className={styles.FirstName}>
                    {user.first_name}
                </span>
                <span className={styles.LastName}>
                    {user.last_name}
                </span>
                <Image
                    circular
                    src={user.picture}
                />
            </div>
            <Transition.Group animation='slide down' duration={150}>
            {
                isMenuOpen &&
                <div className={styles.Menu}>
                    <div
                        className={styles.Option}
                        onClick={() => {
                            navigate(paths.MY_PROFILE)
                            setMenuOpen(false)
                        }}
                    >
                        <Icon name='user circle outline' className={styles.Icon} />
                        <span>Mi cuenta</span>
                    </div>
                    <div
                        className={styles.Option}
                        onClick={showLogoutConfirmationDialog}
                    >
                        <Icon name='sign-out' className={styles.Icon} />
                        <span>Cerrar sesión</span>
                    </div>
                </div>
            }
            </Transition.Group>
        </div>
    )
}

export default UserWidget