import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'

import { LOGIN_BACKGROUND_URL } from 'assets/constants/images'
import NewPasswordPanel from 'components/NewPasswordPanel/NewPasswordPanel'
import styles from './NewPassword.module.scss'


const NewPassword = () => {
    return (
        <>
            <Helmet>
                <title>Resetear contraseña - CenterIuris</title>
                <style>
                    {
                        `.${styles.Container} {
                            background-image: linear-gradient(
                                rgba(0, 0, 0, 0.1),
                                rgba(0, 0, 0, 0.1)),
                                url(${LOGIN_BACKGROUND_URL});
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }`
                    }
                </style>
            </Helmet>
            <section className={styles.Container}>
                <NewPasswordPanel />
            </section>
        </>
    )
}

export default NewPassword
