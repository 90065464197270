import React from 'react'

import { Icon, Pagination } from 'semantic-ui-react'

import styles from './RoundedPagination.module.scss'


const RoundedPagination = (props) => {
    const { className, currentPage, lastPage, previousPage, nextPage, onPageChange } = props

    return (
        <Pagination
            className={`${styles.RoundedPagination}${className ? ` ${className}` : ''}`}
            activePage={currentPage}
            totalPages={lastPage}
            siblingRange={3}
            onPageChange={(e, { activePage }) => onPageChange(activePage)}
            ellipsisItem={{
                content: '...',
                className: styles.EllipsisItem
            }}
            pageItem={{
                className: styles.PageItem
            }}
            prevItem={
                previousPage && {
                    content: <Icon name='left chevron' className={styles.PaginationIcon} />,
                    className: styles.PreviousItem,
                    onClick: () => onPageChange(previousPage)
                }
            }
            nextItem={
                nextPage && {
                    content: <Icon name='right chevron' className={styles.PaginationIcon} />,
                    className: styles.NextItem,
                    onClick: () => onPageChange(nextPage)
                }
            }
            firstItem={null}
            lastItem={null}
        />
    )
}

export default RoundedPagination