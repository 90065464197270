import { axiosInstance } from './config'


const notificationsEndpoint = '/api/notifications'

const markAllNotificationsAsRead = () => (
    axiosInstance.put(`${notificationsEndpoint}/`)
)

const markOneNotificationAsRead = (id) => (
    axiosInstance.put(`${notificationsEndpoint}/${id}`)
)

const getNotificationsPage = (pageNumber) => (
    axiosInstance.get(`${notificationsEndpoint}/?page=${pageNumber}`)
)

const deleteNotification = (id) => (
    axiosInstance.delete(`${notificationsEndpoint}/${id}`)
)

const deleteAllNotifications = () => (
    axiosInstance.delete(`${notificationsEndpoint}/`)
)

export default {
    markAllNotificationsAsRead,
    markOneNotificationAsRead,
    getNotificationsPage,
    deleteNotification,
    deleteAllNotifications,
}