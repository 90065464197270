import React from 'react'

import { Icon } from 'semantic-ui-react'

import styles from './SecondaryActionButton.module.scss'


const SecondaryActionButton = (props) => {
    const { children, icon, onClick, negative, className } = props

    return (
        <span
            className={`${styles.SecondaryActionButton} ${negative ? styles.Negative : styles.Positive}${className ? ` ${className}` : ''}`}
            onClick={onClick}
        >
            {icon && <Icon name={icon} className={styles.Icon} />}
            {children}
        </span>
    )
}

export default SecondaryActionButton