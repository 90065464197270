import React, { useEffect, useRef, useState } from 'react'

import { Button, Divider, Header, Icon, List, Loader, Message } from 'semantic-ui-react'

import { eventCodes } from 'assets/constants/notifications'
import { getFileIconProps, datetimeToStr } from 'assets/utils/common'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import recordsService from 'services/records'
import styles from './RecordDocumentsPane.module.scss'


const RecordDocumentsPane = (props) => {
    const { record, fileUploads } = props
    const { id } = record

    const [files, setFiles] = useState(null)
    const [isError, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [downloadingFiles, setDownloadingFiles] = useState({})
    const { openErrorDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const { receivedEvent, eventNumber } = useNotifications()

    const isNoFiles = (files?.length === 0)

    const fetchFiles = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && setLoading(true)

        recordsService.getRecordFiles(id)
            .then((res) => setFiles(res.data.files))
            .catch(() => setError(true))
            .finally(() => !avoidLoader && setLoading(false))
    }

    const downloadFile = (fileName) => {
        setDownloadingFiles({
            ...downloadingFiles,
            [fileName]: true
        })

        recordsService.downloadRecordFile(id, fileName)
            .then((res) => {
                const tempAnchor = document.createElement('a')

                if (fileName.endsWith('.pdf')) {
                    tempAnchor.setAttribute('rel', 'noopener')
                    tempAnchor.setAttribute('target', '_blank')
                } else {
                    tempAnchor.setAttribute('download', true)
                }

                tempAnchor.setAttribute('href', res.data.url)
                tempAnchor.style.display = 'none'
                
                document.body.appendChild(tempAnchor)
                tempAnchor.click()
                document.body.removeChild(tempAnchor)
            })
            .catch(() => {
                openErrorDialog({
                    title: 'Error al descargar archivo',
                    content: `Ha tenido lugar un error al descargar el archivo.
                              Por favor, inténtalo de nuevo más tarde o contacta
                              con el administrador de la plataforma.`,
                    size: 'tiny',
                })
            })
            .finally(() => setDownloadingFiles({
                ...downloadingFiles,
                [fileName]: false
            }))
    }

    const downloadAllFiles = () => {
        showLoader('Generando archivo comprimido...')

        recordsService.downloadRecordFiles(id)
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Documentos_${record.number.split('/').join('-')}.zip`)
                document.body.appendChild(link)
                link.click()
                link.remove()
                hideLoader()
            })
            .catch(() => {
                hideLoader()
                openErrorDialog({
                    title: 'Error al comprimir',
                    content: `Ha tenido lugar un error al generar el archivo comprimido.
                              Por favor, inténtalo de nuevo más tarde o contacta
                              con el administrador de la plataforma.`,
                    size: 'tiny',
                })
            })
    }

    useEffect(() => {
        fetchFiles()
    }, [fileUploads, id])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.NEW_BROKER_UPLOAD,
            eventCodes.NEW_CONSULTANT_UPLOAD,
        ]

        if (relevantEvents.includes(receivedEvent?.code) && (receivedEvent?.context?.record_id === id)) {
            fetchFiles({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <div className={styles.RecordDocumentsPane}>
            {
                isLoading &&
                <div className={styles.LoadingMessage}>
                    <Loader size='small' active inline />
                    <span>
                        Cargando documentos...
                    </span>
                </div>
            }
            {
                (!isLoading && (isNoFiles || isError)) &&
                <Message
                    info={isNoFiles}
                    error={isError}
                    header={isNoFiles ? 'Todavía no hay documentos' : 'Error al cargar'}
                    icon={
                        <Icon
                            name={isNoFiles ? 'info circle' : 'times circle outline'}
                            className={styles.MessageIcon}
                        />
                    }
                    size='large'
                    content={
                        <>
                            {
                                (isNoFiles && !!record?.type?.document_types?.length) &&
                                <>
                                    <p>
                                        Se recuerda que los documentos solicitados para este expediente son los siguientes:
                                    </p>
                                    <List bulleted className={styles.RequestedDocumentsList}>
                                        {record?.type?.document_types?.map(({ name }) => (
                                            <List.Item key={name}>{name}</List.Item>
                                        ))}
                                    </List>
                                </>
                            }
                            {
                                (isNoFiles && !record?.type?.document_types?.length) &&
                                <p>Aún no se han subido documentos en este expediente.</p>
                            }
                            {
                                isError &&
                                <p>
                                    Ha tenido lugar un error al cargar los documentos del expediente.
                                    Por favor, inténtalo de nuevo más tarde o contacta con el administrador
                                    de la plataforma.
                                </p>
                            }
                        </>
                    }
                />
            }
            {
                (!isLoading && !!files?.length && !!record?.type?.document_types?.length) &&
                <Message
                    info
                    content={
                        <>
                            <p>
                                Se recuerda que los documentos solicitados para este expediente son los siguientes:
                            </p>
                            <List bulleted className={`${styles.RequestedDocumentsList} ${styles.SmallList}`}>
                                {record?.type?.document_types?.map(({ name }) => (
                                    <List.Item key={name}>{name}</List.Item>
                                ))}
                            </List>
                        </>
                    }
                />
            }
            {
                (!isLoading && !!files?.length) &&
                <>
                    <div className={styles.FileList}>
                        {files.map(({ name, last_modified }, index) => {
                            let fileIconProps = getFileIconProps(name)

                            fileIconProps = {
                                ...fileIconProps,
                                className: `${fileIconProps.className} ${styles.FileIcon}`
                            }

                            return (
                                <div key={index} className={styles.FileItem} onClick={() => downloadFile(name)}>
                                    <div className={styles.FileItemHeader}>
                                        <div className={styles.FileData}>
                                            <Header size='small' className={styles.FileName}>
                                                {name}
                                            </Header>
                                            <Header sub className={styles.FileDate}>
                                                {datetimeToStr(last_modified)}
                                            </Header>
                                        </div>
                                        {
                                            downloadingFiles[name]
                                            ? <Icon loading name='spinner' color='grey' className={styles.LoadingIcon} />
                                            : <Icon {...fileIconProps} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.Actions}>
                        <Divider />
                        <div className={styles.ButtonsWrapper}>
                            <PrimaryButton
                                compact
                                icon
                                labelPosition='right'
                                onClick={downloadAllFiles}
                            >
                                Descargar todo
                                <Icon name='file archive outline' />
                            </PrimaryButton>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default RecordDocumentsPane
