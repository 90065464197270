import { useCallback, useContext, useEffect } from 'react'

import { NotificationsActions, NotificationsContext } from 'contexts/Notifications'
import useAuth from 'hooks/useAuth'
import notificationsService from 'services/notifications'


const useNotifications = () => {
    const context = useContext(NotificationsContext)

    if (context === undefined) {
        throw new Error('useNotifications must be used within a NotificationsProvider')
    }

    const { user } = useAuth()
    const { notificationsConfig, dispatch } = context
    const { socket, receivedEvent, eventNumber, pushNotifications } = notificationsConfig
    const webSocketUrl = process.env.REACT_APP_WEBSOCKET_BASE_URL

    const openConnection = () => {
        const newSocket = new WebSocket(
            `${webSocketUrl}/ws/notifications/?token=${user.single_use_token.token}`
        )

        newSocket.onmessage = (event) => {
            event = JSON.parse(event.data)
            const payload = { event }
            dispatch({ type: NotificationsActions.RECEIVE_EVENT, payload })
        }

        const payload = { socket: newSocket }
        dispatch({ type: NotificationsActions.OPEN_CONNECTION, payload })
    }

    const closeConnection = () => {
        socket?.close()
        dispatch({ type: NotificationsActions.CLOSE_CONNECTION })
    }

    const fetchPushNotifications = (pageNumber) => {
        if (!pageNumber) {
            pageNumber = pushNotifications?.pagination?.currentPage || 1
        }

        return notificationsService.getNotificationsPage(pageNumber)
                .then((res) => {
                    const { total_unread, results, current_page, previous_page, next_page, last_page } = res.data
                    const payload = {
                        unreadAmount: total_unread,
                        notifications: results,
                        currentPage: ((current_page === last_page) && (current_page > 1)) ? 'last' : current_page,
                        previousPage: previous_page,
                        nextPage: next_page,
                        lastPage: last_page,
                    }
                    dispatch({ type: NotificationsActions.FETCH_PUSH_NOTIFICATIONS, payload })
                })
    }

    const deletePushNotification = (id) => (
        notificationsService.deleteNotification(id)
    )

    const deletePushNotifications = () => (
        notificationsService.deleteAllNotifications()
    )

    const readPushNotification = (id) => (
        notificationsService.markOneNotificationAsRead(id)
    )

    const readPushNotifications = () => (
        notificationsService.markAllNotificationsAsRead()
    )

    return {
        ...notificationsConfig,
        openConnection,
        closeConnection,
        fetchPushNotifications,
        deletePushNotification,
        deletePushNotifications,
        readPushNotification,
        readPushNotifications,
    }
}

export default useNotifications