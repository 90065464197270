import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Icon, Transition } from 'semantic-ui-react'

import { eventCodes, pushMessages } from 'assets/constants/notifications'
import { datetimeToStr } from 'assets/utils/common'
import RoundIconButton from 'components/UI/RoundIconButton/RoundIconButton'
import useDialog from 'hooks/useDialog'
import useNotifications from 'hooks/useNotifications'
import styles from './NotificationsWidget.module.scss'


const NotificationItem = (props) => {
    const { notification, handleClick, handleDelete, isReadingAll, isDeletingAll } = props
    const { event, read, created_at } = notification
    const { code, data } = event
    const [isDeleting, setDeleting] = useState(false)
    const [isReading, setReading] = useState(false)
    const { closeDialog, openErrorDialog } = useDialog()

    const onClick = () => {
        setReading(true)
        handleClick()
            ?.catch(() => {
                setReading(false)
                openErrorDialog({
                    title: `Error al leer notificación`,
                    content: 'No ha podido marcarse la notificación como leída. Por favor, inténtalo de nuevo más tarde.',
                    size: 'mini',
                })
            })
    }

    const onDelete = () => {
        setDeleting(true)
        handleDelete()
            ?.catch(() => {
                setDeleting(false)
                openErrorDialog({
                    title: `Error al eliminar notificación`,
                    content: 'No ha podido eliminarse la notificación. Por favor, inténtalo de nuevo más tarde.',
                    size: 'mini',
                })
            })
    }

    return (
        <div className={`${styles.Notification}${(!read && !isReading && !isReadingAll) ? ` ${styles.Unread}` : ''}`}>
            {
                (!isDeleting && !isDeletingAll) &&
                <>
                    <div className={styles.ReadMarker} />
                    <div className={styles.DeleteButton} onClick={onDelete}>
                        <Icon name='times circle' color='red' className={styles.DeleteIcon} />
                    </div>
                    <div className={styles.Content} onClick={onClick}>
                        <span className={styles.Timestamp}>
                            {datetimeToStr(created_at)}
                        </span>
                        <span>
                            {pushMessages.build(code, data)}
                        </span>
                    </div>
                </>
            }
            {
                (isDeleting || isDeletingAll) &&
                <strong className={styles.DeletingMessage}>
                    Eliminando...
                </strong>
            }
        </div>
    )
}


const NotificationsWidget = (props) => {
    const { className } = props
    const [isListOpen, setListOpen] = useState(false)
    const [unreadLabel, setUnreadLabel] = useState(undefined)
    const [currentPageNumber, setCurrentPageNumber] = useState(null)
    const [refreshWithNextEvent, setRefreshWithNextEvent] = useState(true)
    const [isReadingAll, setReadingAll] = useState(false)
    const [isDeletingAll, setDeletingAll] = useState(false)
    const { closeDialog, openErrorDialog } = useDialog()
    const navigate = useNavigate()
    const {
        fetchPushNotifications,
        deletePushNotifications,
        deletePushNotification,
        readPushNotifications,
        readPushNotification,
        pushNotifications,
        receivedEvent,
        eventNumber,
    } = useNotifications()
    const widgetRef = useRef()

    const { currentPage, previousPage, nextPage, lastPage } = pushNotifications?.pagination || {}

    const formatUnreadLabel = (unread) => {
        if (!unread) {
            return
        }
        return unread <= 99 ? unread : '99+'
    }

    const handleNextPageClick = () => {
        if (currentPageNumber < lastPage) {
            fetchPushNotifications(nextPage)
        }
    }

    const handlePreviousPageClick = () => {
        if (currentPageNumber > 1) {
            fetchPushNotifications(previousPage)
        }
    }

    const handleClickOutside = (event) => {
        if (document.body.contains(event.target) && !widgetRef.current?.contains(event.target)) {
            setListOpen(false)
        }
    }

    const handleNotificationClick = (e, notification) => {
        const { id, event, read } = notification
        const { data } = event

        if (data?.url) {
            navigate(data?.url)
        }

        if (!read) {
            setRefreshWithNextEvent(false)
            return readPushNotification(id).then(() => fetchPushNotifications())
        }
    }

    const handleNotificationDelete = (e, notification) => {
        const { id } = notification
        setRefreshWithNextEvent(false)
        return deletePushNotification(id).then(() => fetchPushNotifications())
    }

    const handleReadAll = () => {
        setRefreshWithNextEvent(false)
        setReadingAll(true)
        readPushNotifications()
            .then(() => fetchPushNotifications())
            .then(() => setReadingAll(false))
            .catch(() => {
                setReadingAll(false)
                openErrorDialog({
                    title: `Error al leer notificaciones`,
                    content: 'No han podido marcarse las notificaciones como leídas. Por favor, inténtalo de nuevo más tarde.',
                    size: 'mini',
                })
            })
    }

    const handleDeleteAll = () => {
        setRefreshWithNextEvent(false)
        setDeletingAll(true)
        deletePushNotifications()
            .then(() => fetchPushNotifications())
            .then(() => setDeletingAll(false))
            .catch(() => {
                setDeletingAll(false)
                openErrorDialog({
                    title: `Error al eliminar notificaciones`,
                    content: 'No han podido eliminarse las notificaciones. Por favor, inténtalo de nuevo más tarde.',
                    size: 'mini',
                })
            })
    }

    useEffect(() => {
        fetchPushNotifications()

        // Handler for clicking outside the widget
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        const notificationEvents = [
            eventCodes.NOTIFICATION_READ,
            eventCodes.NOTIFICATION_DELETED,
        ]

        if (refreshWithNextEvent && (receivedEvent?.isPush || notificationEvents.includes(receivedEvent?.code))) {
            fetchPushNotifications()
        } else if (!refreshWithNextEvent) {
            setRefreshWithNextEvent(true)
        }
    }, [receivedEvent?.code, eventNumber])

    useEffect(() => {
        setUnreadLabel(pushNotifications?.unread ? formatUnreadLabel(pushNotifications?.unread) : undefined)
    }, [pushNotifications?.unread])

    useEffect(() => {
        if ((pushNotifications?.list?.length === 0) && isListOpen) {
            setListOpen(false)
        }
    }, [pushNotifications?.list?.length])

    useEffect(() => {
        if (!currentPage) {
            return
        }

        const pageNumber = (currentPage === 'last') ? lastPage : currentPage
        setCurrentPageNumber(pageNumber)
    }, [currentPage, lastPage])

    return (
        <div
            ref={widgetRef}
            className={`${styles.NotificationsWidget}${className ? ` ${className}` : ''}`}>
            <RoundIconButton
                icon='bell outline'
                className={styles.OpenMenuButton}
                label={unreadLabel}
                onClick={
                    pushNotifications?.list?.length
                        ? () => setListOpen(!isListOpen)
                        : undefined
                }
            />
            <Transition.Group animation='slide down' duration={150}>
            {
                isListOpen &&
                <div className={styles.Menu}>
                    <div className={styles.Actions}>
                        <div
                            className={`${styles.Action} ${styles.DeleteAll}`}
                            onClick={handleDeleteAll}
                        >
                            <Icon name='times circle' className={styles.ActionIcon} />
                            Eliminar todo
                        </div>
                        {
                            (pushNotifications?.unread > 0) &&
                            <div
                                className={`${styles.Action} ${styles.ReadAll}`}
                                onClick={handleReadAll}
                            >
                                <Icon name='check' className={styles.ActionIcon} />
                                Marcar todo como leído
                            </div>
                        }
                    </div>
                    <div className={styles.List}>
                        {pushNotifications.list.map((notification, index) => (
                            <NotificationItem
                                key={notification.id}
                                isReadingAll={isReadingAll}
                                isDeletingAll={isDeletingAll}
                                notification={notification}
                                handleClick={(e) => handleNotificationClick(e, notification)}
                                handleDelete={(e) => handleNotificationDelete(e, notification)}
                            />
                        ))}
                    </div>
                    {
                        (lastPage > 1) &&
                        <div className={styles.Pagination}>
                            <Icon
                                name='chevron circle left'
                                className={`${styles.PaginationIcon}${currentPageNumber === 1 ? ` ${styles.Disabled}` : ''}`}
                                onClick={handlePreviousPageClick}
                            />
                            <strong>
                                Página {currentPageNumber}/{lastPage}
                            </strong>
                            <Icon
                                name='chevron circle right'
                                className={`${styles.PaginationIcon}${currentPageNumber === lastPage ? ` ${styles.Disabled}` : ''}`}
                                onClick={handleNextPageClick}
                            />
                        </div>
                    }
                </div>
            }
            </Transition.Group>
        </div>
    )
}

export default NotificationsWidget