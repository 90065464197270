import React, { useEffect, useState } from 'react'

import { Form, Header, Icon, Message } from 'semantic-ui-react'

import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import authService from 'services/auth'
import styles from './PersonalInfoPanel.module.scss'


const PersonalInfoPanel = (props) => {
    const { className, onUpdate, user } = props

    const { openSuccessDialog } = useDialog()
    const [formData, setFormData] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const personalInfoFields = [
        { name: 'name', label: 'Nombre', icon: 'user circle' },
        { name: 'surname', label: 'Apellidos', icon: 'user circle' },
        { name: 'email', label: 'Correo electrónico', icon: 'envelope' }
    ]

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setFormErrors(null)

        authService.updateUserData(user.id, formData)
            .then(res => {
                onUpdate?.(res.data)
                openSuccessDialog({
                    title: 'Datos actualizados',
                    content: 'Los datos han sido actualizados correctamente.',
                    size: 'mini',
                })
            })
            .catch(error => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        setFormData({
            name: user?.first_name || '',
            surname: user?.last_name || '',
            email: user?.email || ''
        })
    }, [user?.first_name, user?.last_name, user?.email])

    return (
        <article className={`${styles.PersonalInfoPanel}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.PanelHeader}>
                Datos personales
            </Header>
            <Form
                error={!!formErrors}
                className={styles.PersonalInfoForm}
                onSubmit={handleSubmit}
            >
                {personalInfoFields.map((field, index) => (
                    <Form.Input
                        required
                        key={index}
                        error={!!formErrors?.[field.name]}
                        label={field.label}
                        placeholder={field.label}
                        icon={field.icon}
                        iconPosition='left'
                        name={field.name}
                        value={formData?.[field.name] || ''}
                        onChange={handleChange}
                    />
                ))}
                {
                    formErrors &&
                    <Message error>
                        <Message.List>
                        {
                            Object.entries(formErrors).map(([key, value], index) => (
                                <Message.Item key={index}>
                                    {value[0]}
                                </Message.Item>
                            ))
                        }
                        </Message.List>
                    </Message>
                }
                <div className={styles.Actions}>
                    <PrimaryButton
                        compact
                        icon
                        type='submit'
                        labelPosition='right'
                        disabled={isLoading}
                        loading={isLoading}
                        className={styles.ActionButton}
                    >
                        Guardar cambios
                        <Icon name='save' />
                    </PrimaryButton>
                </div>
            </Form>
        </article>
    )
}

export default PersonalInfoPanel

