import React, { useEffect, useState } from 'react'

import { Button, Divider, Form, Header, Image, Message, Segment } from 'semantic-ui-react'

import { CENTERIURIS_LOGO_URL } from 'assets/constants/images'
import { loginPanels } from 'assets/constants/login'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import useDialog from 'hooks/useDialog'
import accountsService from 'services/accounts'
import businessService from 'services/business'
import styles from './AccessRequestPanel.module.scss'


const AccessRequestPanel = (props) => {
    const { showPanel } = props
    const { LOGIN } = loginPanels

    const { closeDialog, openErrorDialog } = useDialog()
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(null)

    const handleSubmit = event => {
        event.preventDefault()

        setLoading(true)

        accountsService.createAccessRequest(formData)
            .then(res => setRequestSuccess(true))
            .catch(error => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => setLoading(false))
    }

    const handleChange = (event, result, isCheckbox) => {
        const { name, value, checked } = result || event.target
        setFormData({
            ...formData,
            [name]: isCheckbox ? checked : value
        })
    }

    return (
        <Segment className={styles.AccessRequestPanel}>
            <header className={styles.Header}>
                <Image
                    src={CENTERIURIS_LOGO_URL}
                    size='medium'
                    className={styles.Logo}
                />
            </header>
            <div className={styles.MainContent}>
                {
                    requestSuccess &&
                    <Message
                        positive
                        icon='check'
                        header='Acceso solicitado'
                        content='La solicitud de acceso ha sido enviada correctamente.
                                 Los asesores se pondrán en contacto contigo próximamente.'
                        className={styles.SuccessMessage}
                    />
                }
                {
                    !requestSuccess &&
                    <Form
                        id='access-request-form'
                        className={styles.AccessRequestForm}
                        onSubmit={handleSubmit}
                        error={!!formErrors}
                    >
                        <Header size='small' className={styles.Title} dividing>
                            Completa el formulario para solicitar acceso
                        </Header>
                        <Form.Input
                            error={!!formErrors?.name}
                            label='Nombre'
                            placeholder='Nombre'
                            icon='user circle'
                            iconPosition='left'
                            name='name'
                            onChange={handleChange}
                        />
                        <Form.Input
                            error={!!formErrors?.surname}
                            label='Apellidos'
                            placeholder='Apellidos'
                            icon='user circle'
                            iconPosition='left'
                            name='surname'
                            onChange={handleChange}
                        />
                        <Form.Input
                            error={!!formErrors?.email}
                            label='Correo electrónico'
                            placeholder='Correo electrónico'
                            icon='envelope'
                            iconPosition='left'
                            name='email'
                            onChange={handleChange}
                        />
                        <Divider hidden />
                        <Form.Checkbox
                            error={!!formErrors?.accepted_privacy_policy}
                            label={
                                <label>
                                    Acepto la política de privacidad. Consultar {' '}
                                    <a 
                                        href='https://centeriuris.s3.eu-central-1.amazonaws.com/public/docs/AvisoLegal.pdf'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        Aviso Legal
                                    </a>.
                                </label>
                            }
                            name='accepted_privacy_policy'
                            onChange={(e, r) => handleChange(e, r, true)}
                        />
                        {
                            formErrors &&
                            <Message error>
                                <Message.List>
                                {
                                    Object.entries(formErrors).map(([key, value], index) => (
                                        <Message.Item key={index}>
                                            {value[0]}
                                        </Message.Item>
                                    ))
                                }
                                </Message.List>
                            </Message>
                        }
                    </Form>
                }
                <Divider/>
                {
                    requestSuccess &&
                    <SecondaryButton fluid className={styles.Actions} onClick={() => showPanel?.(LOGIN)}>
                        Aceptar
                    </SecondaryButton>
                }
                {
                    !requestSuccess &&
                    <Button.Group fluid className={styles.Actions}>
                        <SecondaryButton onClick={() => showPanel?.(LOGIN)}>
                            Cancelar
                        </SecondaryButton>
                        <PrimaryButton form='access-request-form' type='submit'>
                            Enviar solicitud
                        </PrimaryButton>
                    </Button.Group>
                }
            </div>
        </Segment>
    )
}

export default AccessRequestPanel