import React, { createContext, useReducer } from 'react'

import { Icon, Modal } from 'semantic-ui-react'

import { defaultTitles, defaultMessages, dialogTypes, dialogStates } from 'assets/constants/dialog'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import { DialogActions, DialogReducer } from './reducer'

import styles from 'assets/styles/Common.module.scss'


const initialState = {
    type: null,
    state: null,
    scrolling: null,
    size: null,
    title: null,
    content: null,
    render: null,
    renderProps: null,
    onConfirm: null,
    onDeny: null,
    closable: null,
    visible: null,
    isConfirming: null,
    secondaryActions: null,
    confirmSubmitsForm: null,
}

const DialogContext = createContext()

const DialogProvider = ({ children }) => {
    const [dialogConfig, dispatch] = useReducer(DialogReducer, initialState)
    const value = { dialogConfig, dispatch }

    const { INFO, WARNING, ERROR, SUCCESS } = dialogStates
    const { OK, OK_CANCEL } = dialogTypes

    const defaultIcons = {
        [INFO]: <Icon color='blue' name='info circle' className={`${styles.DialogIcon} ${styles.InfoIcon}`}/>,
        [WARNING]: <Icon color='orange' name='warning circle' className={styles.DialogIcon}/>,
        [ERROR]: <Icon color='red' name='times circle' className={styles.DialogIcon}/>,
        [SUCCESS]: <Icon color='green' name='check circle' className={styles.DialogIcon}/>
    }

    const type = dialogConfig.type || OK
    const state = dialogConfig.state || INFO
    const AcceptButtonComponent = (type === OK) ? PrimaryButton : SecondaryButton

    const handleAcceptButtonClick = () => {
        if (dialogConfig.confirmSubmitsForm) {
            return
        }

        if (dialogConfig.onConfirm) {
            dialogConfig.onConfirm()
        } else {
            dispatch({ type: DialogActions.CLOSE_DIALOG })
        }
    }

    return (
        <DialogContext.Provider value={value}>
            {children}
            {
                dialogConfig &&
                <Modal
                    open={dialogConfig.visible}
                    size={dialogConfig.size}
                    closeIcon={dialogConfig.closable || false}
                    onClose={
                        dialogConfig.closable
                            ? () => dispatch({ type: DialogActions.CLOSE_DIALOG })
                            : undefined
                    }
                    dimmer='blurring'
                    className={styles.Dialog}
                >
                    <Modal.Header>
                        {defaultIcons[dialogConfig.state] || null}
                        {dialogConfig.title || defaultTitles[dialogConfig.state] || ''}
                    </Modal.Header>
                    <Modal.Content scrolling={dialogConfig.scrolling !== false}>
                        {
                            dialogConfig.render
                                ? <dialogConfig.render {...dialogConfig.renderProps} />
                                : dialogConfig.content || defaultMessages[dialogConfig.state] || ''
                        }
                    </Modal.Content>
                    <Modal.Actions className={`${styles.Actions} ${dialogConfig.secondaryActions ? styles.ExtraActions : ''}`}>
                        {
                            dialogConfig.secondaryActions &&
                            <div>
                                {dialogConfig.secondaryActions}
                            </div>
                        }
                        <div>
                            {
                                (type === OK_CANCEL) &&
                                <PrimaryButton
                                    compact
                                    className={styles.ActionButton}
                                    disabled={dialogConfig.isConfirming}
                                    onClick={
                                        dialogConfig.onDeny
                                          ? dialogConfig.onDeny
                                          : () => dispatch({ type: DialogActions.CLOSE_DIALOG })
                                    }
                                >
                                    Cancelar
                                </PrimaryButton>
                            }
                            <AcceptButtonComponent
                                compact
                                type={dialogConfig.confirmSubmitsForm ? 'submit' : undefined}
                                form={dialogConfig.confirmSubmitsForm ? dialogConfig.confirmSubmitsForm : undefined}
                                className={styles.ActionButton}
                                disabled={dialogConfig.isConfirming}
                                loading={dialogConfig.isConfirming}
                                onClick={handleAcceptButtonClick}
                            >
                                Aceptar
                            </AcceptButtonComponent>
                        </div>
                    </Modal.Actions>
                </Modal>
            }
        </DialogContext.Provider>
    )
}

export {
    DialogContext,
    DialogProvider
}