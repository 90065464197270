const ACCESS_REQUEST_APPROVAL_FORM_ID = 'access-request-approval-form'
const PARTNER_FORM_ID = 'partner-form'
const INSURED_FORM_ID = 'insured-form'
const INSURANCE_FORM_ID = 'insurance-form'
const RECORD_TYPE_FORM_ID = 'record-type-form'
const RECORD_FILTER_FORM_ID = 'record-filter-form'
const NEW_RECORD_FORM_ID = 'new-record-form'
const CALENDAR_EVENT_FORM_ID = 'calendar-event-form'
const PICTURE_CROP_FORM_ID = 'picture-crop-form'

export {
    ACCESS_REQUEST_APPROVAL_FORM_ID,
    PARTNER_FORM_ID,
    INSURED_FORM_ID,
    INSURANCE_FORM_ID,
    RECORD_TYPE_FORM_ID,
    RECORD_FILTER_FORM_ID,
    NEW_RECORD_FORM_ID,
    CALENDAR_EVENT_FORM_ID,
    PICTURE_CROP_FORM_ID,
}