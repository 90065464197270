import React, { useState } from 'react'

import { Form, Header, Icon, Message } from 'semantic-ui-react'

import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useDialog from 'hooks/useDialog'
import authService from 'services/auth'
import styles from './PasswordChangePanel.module.scss'


const PasswordChangePanel = (props) => {
    const { className } = props

    const { openSuccessDialog } = useDialog()
    const [formData, setFormData] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const [visiblePasswords, setVisiblePasswords] = useState({})
    const [isLoading, setLoading] = useState(false)

    const passwordChangeFields = [
        { name: 'current_password', label: 'Contraseña actual', icon: 'lock' },
        { name: 'new_password', label: 'Nueva contraseña', icon: 'lock', extraCheck: true },
        { name: 'repeat_password', label: 'Repetir la nueva contraseña', icon: 'lock', extraCheck: true }
    ]

    const handleSubmit = event => {
        event.preventDefault()

        setLoading(true)
        setFormErrors(null)

        authService.changePassword(formData)
            .then(() => {
                setFormData(null)
                openSuccessDialog({
                    title: 'Contraseña cambiada',
                    content: 'La contraseña ha sido cambiada correctamente.',
                    size: 'mini',
                })
            })
            .catch(error => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <article className={`${styles.PasswordChangePanel}${className ? ` ${className}` : ''}`}>
            <Header dividing className={styles.PanelHeader}>
                Cambio de contraseña
            </Header>
            <Form
                error={!!formErrors}
                className={styles.PasswordChangeForm}
                onSubmit={handleSubmit}
            >
                <Form.Group widths='equal'>
                    {passwordChangeFields.map((field, index) => (
                        <Form.Input
                            required
                            key={index}
                            error={
                                !!formErrors?.[field.name] ||
                                (field.extraCheck && !!formErrors?.non_field_errors)
                            }
                            label={field.label}
                            placeholder={field.label}
                            icon={field.icon}
                            iconPosition='left'
                            name={field.name}
                            type={visiblePasswords?.[field.name] ? 'text' : 'password'}
                            value={formData?.[field.name] || ''}
                            onChange={handleChange}
                            action={{
                                icon: 'eye',
                                type: 'button',
                                className: styles.ShowPasswordButton,
                                onClick: () => setVisiblePasswords({
                                    ...visiblePasswords,
                                    [field.name]: !visiblePasswords?.[field.name]
                                })
                            }}
                        />
                    ))}
                </Form.Group>
                {
                    formErrors &&
                    <Message error>
                        <Message.List>
                        {
                            Object.entries(formErrors).map(([key, value], index) => (
                                <Message.Item key={index}>
                                    {value[0]}
                                </Message.Item>
                            ))
                        }
                        </Message.List>
                    </Message>
                }
                <div className={styles.Actions}>
                    <PrimaryButton
                        compact
                        icon
                        type='submit'
                        labelPosition='right'
                        disabled={isLoading}
                        loading={isLoading}
                        className={styles.ActionButton}
                    >
                        Cambiar contraseña
                        <Icon name='exchange' />
                    </PrimaryButton>
                </div>
            </Form>
        </article>
    )
}

export default PasswordChangePanel

