import React from 'react'

import { Navigate } from 'react-router-dom'

import { authGroups } from 'assets/constants/auth'
import { loginPanels } from 'assets/constants/login'
import { paths } from 'assets/constants/navigation'
import useAuth from 'hooks/useAuth'
import {
    AccessRequests,
    AccountsManagement,
    CalendarDayEvents,
    Insurances,
    Insureds,
    LoggedPage,
    Login,
    MyProfile,
    NewPassword,
    NewRecord,
    NotFound,
    PartnerUsers,
    UserManagement,
    Partners,
    RecordManagement,
    RecordTypes,
    Records,
    Reports,
    UsersPerPartner,
} from 'pages'


const routes = () => {
    const { isUserInAnyGroup, user } = useAuth()

    return [
        {
            path: paths.HOME,
            element: user ? <LoggedPage /> : <Navigate to={paths.LOGIN} />,
            children: [
                { path: paths.HOME, element: <Navigate to={paths.RECORDS} /> },
                { path: paths.RECORDS, element: <Records /> },
                { path: paths.RECORD_MANAGEMENT, element: <RecordManagement /> },
                { path: paths.RECORD_COMMUNICATIONS, element: <RecordManagement initialTab={2} /> },
                { path: paths.RECORD_FILES, element: <RecordManagement initialTab={3} /> },
                { path: paths.NEW_RECORD, element: <NewRecord /> },
                { path: paths.MY_PROFILE, element: <MyProfile /> },
                { path: paths.ACCOUNTS_MANAGEMENT, element: <AccountsManagement /> },
                { path: paths.ACCESS_REQUESTS, element: <AccessRequests /> },
                { path: paths.USERS_PER_PARTNER, element: <UsersPerPartner /> },
                { path: paths.PARTNER_USERS, element: <PartnerUsers /> },
                { path: paths.USER_MANAGEMENT, element: <UserManagement /> },
                { path: paths.PARTNERS, element: <Partners /> },
                { path: paths.INSUREDS, element: <Insureds /> },
                { path: paths.INSURANCES, element: <Insurances /> },
                { path: paths.REPORTS, element: <Reports /> },
                { path: paths.RECORD_TYPES, element: <RecordTypes /> },
                { path: paths.CALENDAR_DAY_EVENTS, element: <CalendarDayEvents /> },
            ]
        },
        {
            path: paths.LOGIN,
            element: !user ? <Login /> : <Navigate to={paths.RECORDS} />
        },
        {
            path: paths.REQUEST_ACCESS,
            element: (
                !user
                    ? <Navigate to={paths.LOGIN} state={{initialPanel: loginPanels.ACCESS_REQUEST}} />
                    : <Navigate to={paths.RECORDS} />
            )
        },
        {
            path: paths.PASSWORD_RESET,
            element: !user ? <NewPassword /> : <Navigate to={paths.RECORDS} />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ]
}

export default routes