import React from 'react'

import styles from './RoundedActionButton.module.scss'


const RoundedActionButton = (props) => {
    const { children, className, negative, onClick } = props

    return (
        <div
            className={`${styles.RoundedActionButton} ${negative ? styles.Negative : styles.Positive}${className ? ` ${className}` : ''}`}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default RoundedActionButton