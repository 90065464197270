import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import PersonalInfoPanel from 'components/PersonalInfoPanel/PersonalInfoPanel'
import ProfilePicturePanel from 'components/ProfilePicturePanel/ProfilePicturePanel'
import UserActionsPanel from 'components/UserActionsPanel/UserActionsPanel'
import UserPermissionsPanel from 'components/UserPermissionsPanel/UserPermissionsPanel'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import accountsService from 'services/accounts'
import styles from './UserManagement.module.scss'


const UserManagement = () => {
    const [user, setUser] = useState(null)
    const { openErrorDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const navigate = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const { userId } = useParams()

    const fetchUser = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && showLoader('Cargando usuario...')

        accountsService.getUser(userId)
            .then((res) => {
                if (!res.data) {
                    navigate(paths.USERS_PER_PARTNER)
                } else {
                    setUser(res.data)
                }

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                if (error.response.status === 404) {
                    navigate(paths.NOT_FOUND)
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar el usuario.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.USERS_PER_PARTNER)
                        closeDialog()
                    }
                })
            })
    }

    useEffect(() => {
        fetchUser()
    }, [userId])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.USER_UPDATED,
            eventCodes.USER_DELETED,
            eventCodes.USER_PERMISSIONS_UPDATED,
        ]

        if (receivedEvent?.context?.user?.id === user?.id) {
            if ([eventCodes.USER_UPDATED, eventCodes.USER_PERMISSIONS_UPDATED].includes(receivedEvent?.code)) {
                fetchUser({ avoidLoader: true })
            } else if (receivedEvent?.code === eventCodes.USER_DELETED) {
                navigate(paths.PARTNER_USERS.replace(':partnerId', user?.partner?.id))
            }
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Gestión de usuario - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='user circle outline'
                header={
                    <>
                        Gestión de usuario
                        {
                            user &&
                            <> de <span>{user?.partner?.name}</span></>
                        }
                    </>
                }
            />
            <div className={styles.PersonalPanels}>
                <PersonalInfoPanel
                    className={styles.ProfilePanel}
                    user={user}
                />
                <ProfilePicturePanel
                    className={styles.ProfilePicturePanel}
                    user={user}
                />
            </div>
            <div className={styles.AdministrationPanels}>
                <UserPermissionsPanel
                    className={styles.PermissionsPanel}
                    user={user}
                />
                <UserActionsPanel
                    className={styles.ActionsPanel}
                    user={user}
                />
            </div>
        </>
    )
}

export default UserManagement
