import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Header, Image } from 'semantic-ui-react'

import { ACCESS_REQUEST_APPROVAL_FORM_ID } from 'assets/constants/forms'
import { SUCCESS_IMAGE_URL } from 'assets/constants/images'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { datetimeToStr } from 'assets/utils/common'
import AccessRequestApprovalForm from 'components/AccessRequestApprovalForm/AccessRequestApprovalForm'
import LargeTable from 'components/UI/LargeTable/LargeTable'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import RoundedActionButton from 'components/UI/RoundedActionButton/RoundedActionButton'
import RoundedPagination from 'components/UI/RoundedPagination/RoundedPagination'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import usePagination from 'hooks/usePagination'
import accountsService from 'services/accounts'
import businessService from 'services/business'
import styles from './AccessRequests.module.scss'


const AccessRequests = () => {
    const [refreshWithNextEvent, setRefreshWithNextEvent] = useState(true)
    const [rows, setRows] = useState(null)
    const [formErrors, setFormErrors] = useState(null)
    const { closeDialog, openErrorDialog, openWarningQuestionDialog, refreshData, setConfirming } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [requestsData, setRequestsData] = usePagination()
    const naviagte = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const currentPageRef = useRef()

    const { currentPage, lastPage, nextPage, previousPage, results, total } = requestsData

    const columns = [
        { className: styles.RequestsColumn, content: 'Administrador' },
        { className: styles.RequestsColumn, content: 'Correo electrónico' },
        { className: styles.RequestsColumn, content: 'Fecha de solicitud' },
        { className: styles.RequestsColumn, content: 'Acciones' },
    ]

    currentPageRef.current = currentPage

    const handleActionError = (error, isAccept) => {
        let errorMessage = error?.response?.data?.error

        if (!errorMessage) {
            errorMessage = `Ha tenido lugar un error al ${isAccept ? 'aceptar' : 'rechazar'}
                            la solicitud de acceso. Por favor, inténtalo de nuevo más tarde
                            o contacta con el administrador de la plataforma.`
        }

        openErrorDialog({
            title: `Error al ${isAccept ? 'aceptar' : 'rechazar'} la solicitud`,
            content: errorMessage,
            size: 'tiny'
        })
    }

    const acceptRequest = (requestId, formData) => {
        setConfirming(true)
        setFormErrors(null)
        setRefreshWithNextEvent(false)

        accountsService.acceptAccessRequest(requestId, formData)
            .then(() => {
                closeDialog()
                fetchAccessRequests()
            })
            .catch((error) => {
                if (error.response) {
                    setFormErrors(error.response.data)
                }
                setRefreshWithNextEvent(true)
            })
            .finally(() => setConfirming(false))
    }

    const rejectRequest = (requestId) => {
        setRefreshWithNextEvent(false)
        closeDialog()
        showLoader('Rechazando solicitud...', true)

        accountsService.rejectAccessRequest(requestId)
            .then(() => fetchAccessRequests())
            .catch((error) => {
                hideLoader()
                handleActionError(error, false)
            })
    }

    const handleAcceptButtonClick = (requestId) => {
        openWarningQuestionDialog({
            title: 'Aceptar solicitud',
            render: AccessRequestApprovalForm,
            renderProps: {
                onSubmit: (formData) => acceptRequest(requestId, formData),
                errors: formErrors,
                className: styles.ApproveRequestForm,
            },
            scrolling: false,
            size: 'tiny',
            confirmSubmitsForm: ACCESS_REQUEST_APPROVAL_FORM_ID,
            onDeny: () => {
                setFormErrors(null)
                closeDialog()
            }
        })
    }

    const handleRejectButtonClick = (requestId) => {
        openWarningQuestionDialog({
            title: 'Rechazar solicitud',
            content: '¿Seguro que quieres rechazar la solicitud de acceso?',
            size: 'mini',
            onConfirm: () => rejectRequest(requestId)
        })
    }

    const formatRows = (requests) => {
        const newRows = requests.map(({ id, name, surname, email, created_at }) => ({
            className: styles.RequestsRow,
            cells: [
                { className: styles.RequestsCell, content: <strong>{name} {surname}</strong> },
                { className: styles.RequestsCell, content: `${email}` },
                { className: styles.RequestsCell, content: datetimeToStr(created_at) },
                {
                    className: styles.RequestsCell,
                    content: (
                        <div className={styles.ActionButtons}>
                            <RoundedActionButton
                                className={styles.ActionButton}
                                onClick={() => handleAcceptButtonClick(id)}
                            >
                                Aceptar
                            </RoundedActionButton>
                            <RoundedActionButton
                                negative
                                className={styles.ActionButton}
                                onClick={() => handleRejectButtonClick(id)}
                            >
                                Rechazar
                            </RoundedActionButton>
                        </div>
                    )
                },
            ],
        }))

        setRows(newRows)
    }

    const fetchAccessRequests = (params) => {
        let { pageNumber, avoidLoader } = params || {}

        !avoidLoader && showLoader('Cargando solicitudes de acceso...')

        if (!pageNumber) {
            pageNumber = currentPageRef.current || 1
        }

        accountsService.getAccessRequests(pageNumber)
            .then((res) => {
                setRequestsData(res.data)
                formatRows(res.data.results)
                !avoidLoader && hideLoader()
            })
            .catch(() => {
                !avoidLoader && hideLoader()
                openErrorDialog({
                    title: 'Error al cargar solicitudes',
                    content: `Ha tenido lugar un error al cargar las solicitudes de acceso.
                              Por favor, inténtalo de nuevo más tarde o contacta con el
                              administrador de la plataforma.`,
                    size: 'tiny',
                    onConfirm: () => {
                        naviagte(paths.ACCOUNTS_MANAGEMENT)
                        closeDialog()
                    }
                })
            })
    }

    useEffect(() => {
        fetchAccessRequests()
    }, [])

    useEffect(() => {
        refreshData({ errors: formErrors })
    }, [formErrors])

    useEffect(() => {
        const accessRequestEvents = [
            eventCodes.ACCESS_REQUEST_CREATED,
            eventCodes.ACCESS_REQUEST_APPROVED,
            eventCodes.ACCESS_REQUEST_REJECTED,
        ]

        if (refreshWithNextEvent && accessRequestEvents.includes(receivedEvent?.code)) {
            fetchAccessRequests({ avoidLoader: true })
        } else if (!refreshWithNextEvent) {
            setRefreshWithNextEvent(true)
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Solicitudes de acceso - CenterIuris</title>
            </Helmet>
            <PageTitle
                hasWideIcon
                icon='user plus'
                header='Solicitudes de acceso pendientes de tramitar'
            />
            <article className={styles.Content}>
                {
                    (rows?.length > 0) &&
                    <>
                        <LargeTable
                            className={styles.RequestsTable}
                            columns={columns}
                            rows={rows}
                        />
                        {
                            (lastPage > 1) &&
                            <RoundedPagination
                                className={styles.RequestsPagination}
                                currentPage={currentPage === 'last' ? lastPage : currentPage}
                                lastPage={lastPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                onPageChange={(pageNumber) => fetchAccessRequests({ pageNumber })}
                            />
                        }
                    </>
                }
                {
                    (rows?.length === 0) &&
                    <div className={styles.NoRequestsMessage}>
                        <Image src={SUCCESS_IMAGE_URL} size='big' centered />
                        <Header size='huge' className={styles.MessageHeader}>
                            <span className={styles.MessageTitle}>¡Todo al día!</span>
                            <Header.Subheader className={styles.MessageSubtitle}>
                                No hay ninguna solicitud de acceso pendiente de tramitar
                            </Header.Subheader>
                        </Header>
                    </div>
                }
            </article>
        </>
    )
}

export default AccessRequests 