import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import CompactTable from 'components/UI/CompactTable/CompactTable'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import SearchPanel from 'components/UI/SearchPanel/SearchPanel'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import accountsService from 'services/accounts'
import styles from './PartnerUsers.module.scss'


const PartnerUsers = () => {
    const [rows, setRows] = useState(null)
    const [filteredRows, setFilteredRows] = useState(null)
    const [users, setUsers] = useState(null)
    const [partner, setPartner] = useState(null)
    const [searchString, setSearchString] = useState('')
    const { openErrorDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const { receivedEvent, eventNumber } = useNotifications()
    const navigate = useNavigate()
    const { partnerId } = useParams()

    const columns = [
        { className: styles.UserDataColumn, content: 'Nombre del corredor' },
        { className: styles.UserDataColumn, content: 'Correo electrónico' },
    ]

    const formatRows = (users) => {
        const newRows = users.map((user) => ({
            id: user.id,
            className: styles.UserDataRow,
            onClick: () => navigate(paths.USER_MANAGEMENT.replace(':userId', user.id)),
            cells: [
                { className: styles.UserDataCell, content: <strong>{user.first_name} {user.last_name}</strong> },
                { className: styles.UserDataCell, content: user.email },
            ],
        }))

        return newRows
    }

    const fetchPartnerUsers = (params) => {
        const { avoidLoader } = params || {}

        !avoidLoader && showLoader('Cargando usuarios...')

        accountsService.getPartnerUsers(partnerId)
            .then((res) => {
                if (!res.data?.users?.length) {
                    navigate(paths.USERS_PER_PARTNER)
                } else {
                    setPartner(res.data?.partner)
                    setUsers(res.data?.users)
                }

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                if (error.response.status === 404) {
                    navigate(paths.NOT_FOUND)
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los usuarios.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.USERS_PER_PARTNER)
                        closeDialog()
                    }
                })
            })
    }

    const handleSearchChange = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    useEffect(() => {
        fetchPartnerUsers()
    }, [partnerId])

    useEffect(() => {
        if (users) {
            const newRows = formatRows(users)
            setRows(newRows)
        }
    }, [users])

    useEffect(() => {
        if (!searchString.trim()) {
            setFilteredRows(rows)
        } else {
            const filteredUsers = users.filter(({ first_name, last_name, email }) => {
                const matches = [`${first_name} ${last_name}`?.toLowerCase(), email?.toLowerCase()].filter((text) => (
                    text.includes(searchString.toLowerCase())
                ))
                return (matches.length > 0)
            })
            const filteredRows = formatRows(filteredUsers)
            setFilteredRows(filteredRows)
        }
    }, [rows, searchString])

    useEffect(() => {
        if (!partner) {
            return
        }

        const relevantEvents = [
            eventCodes.ACCESS_REQUEST_APPROVED,
            eventCodes.USER_UPDATED,
            eventCodes.USER_DELETED,
        ]

        if (relevantEvents.includes(receivedEvent?.code) &&
            ((receivedEvent?.context?.user?.partner?.id === partner.id) || (receivedEvent?.context?.partner_id === partner.id))) {
            fetchPartnerUsers({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Usuarios de asociado - CenterIuris</title>
            </Helmet>
            {
                partner &&
                <>
                    <PageTitle
                        hasWideIcon
                        icon='users'
                        header={<>Usuarios del asociado <span>{partner.name}</span></>}
                    />
                    <article className={styles.Content}>
                        <SearchPanel
                            searchPlaceholder='Buscar usuario'
                            onSearchChange={handleSearchChange}
                        >
                            <CompactTable
                                className={styles.UsersTable}
                                columns={columns}
                                rows={filteredRows}
                                noResultsRow={{
                                    className: styles.NoResultsRow,
                                    cell: {
                                        className: styles.NoResultsCell,
                                        content: (
                                            <strong><i>No hay usuarios que coincidan con la búsqueda</i></strong>
                                        )
                                    },
                                }}
                            />
                        </SearchPanel>
                    </article>
                </>
            }
            
        </>
    )
}

export default PartnerUsers
