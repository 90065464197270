import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import { paths } from 'assets/constants/navigation'
import LinkCard from 'components/UI/LinkCard/LinkCard'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import accountsService from 'services/accounts'
import styles from './AccountsManagement.module.scss'


const AccountsManagement = () => {
    const { openErrorDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()

    const downloadAccountsReport = () => {
        showLoader('Generando informe...')

        accountsService.exportAccountsXlsx()
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Informe de usuarios.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
                hideLoader()
            })
            .catch(() => {
                hideLoader()
                openErrorDialog({
                    title: 'Error al descargar informe',
                    content: `Ha tenido lugar un error al descargar el informe de usuarios.
                              Por favor, inténtalo de nuevo más tarde o contacta con el
                              administrador de la plataforma.`,
                    size: 'tiny'
                })
            })
    }

    return (
        <>
            <Helmet>
                <title>Usuarios - CenterIuris</title>
            </Helmet>
            <PageTitle
                hasWideIcon
                icon='users'
                header='Usuarios'
            />
            <article className={styles.Content}>
                <LinkCard
                    className={styles.ManagementLink}
                    text='Altas pendientes de tramitar'
                    link={paths.ACCESS_REQUESTS}
                />
                <LinkCard
                    className={styles.ManagementLink}
                    text='Usuarios por asociado'
                    link={paths.USERS_PER_PARTNER}
                />
                <LinkCard
                    className={styles.ManagementLink}
                    text='Exportar datos de usuarios'
                    onClick={downloadAccountsReport}
                />
            </article>
        </>
    )
}

export default AccountsManagement
