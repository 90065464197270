import { axiosInstance } from './config'


const accountsEndpoint = '/api/accounts'

const createAccessRequest = (data) => (
    axiosInstance.post(`${accountsEndpoint}/access-request`, data)
)

const getAccessRequests = (pageNumber) => (
    axiosInstance.get(`${accountsEndpoint}/access-requests${pageNumber ? `?page=${pageNumber}` : ''}`)
)

const acceptAccessRequest = (requestId, data) => (
    axiosInstance.put(`${accountsEndpoint}/access-request/${requestId}`, data)
)

const rejectAccessRequest = (requestId) => (
    axiosInstance.delete(`${accountsEndpoint}/access-request/${requestId}`)
)

const getUsersPerPartner = () => (
    axiosInstance.get(`${accountsEndpoint}/users-per-partner`)
)

const getPartnerUsers = (partnerId) => (
    axiosInstance.get(`${accountsEndpoint}/users-per-partner/${partnerId}`)
)

const getBrokerUsers = () => (
    axiosInstance.get(`${accountsEndpoint}/users/brokers`)
)

const getUser = (userId) => (
    axiosInstance.get(`${accountsEndpoint}/users/${userId}`)
)

const restoreDefaultPicture = (userId) => (
    axiosInstance.delete(`${accountsEndpoint}/users/${userId}/picture`)
)

const uploadNewPicture = (userId, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CIbyLZZ"'
    }

    return axiosInstance.post(`${accountsEndpoint}/users/${userId}/picture`, data, { headers })
}

const exportAccountsXlsx = () => (
    axiosInstance.get(`${accountsEndpoint}/export`, {responseType: 'blob'})
)

export default {
    acceptAccessRequest,
    createAccessRequest,
    getAccessRequests,
    rejectAccessRequest,
    getUsersPerPartner,
    getPartnerUsers,
    getBrokerUsers,
    getUser,
    restoreDefaultPicture,
    uploadNewPicture,
    exportAccountsXlsx,
}