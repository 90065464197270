import React, { useEffect, useState } from 'react'

import { Form, Message } from 'semantic-ui-react'

import { ACCESS_REQUEST_APPROVAL_FORM_ID } from 'assets/constants/forms'
import { eventCodes } from 'assets/constants/notifications'
import useDialog from 'hooks/useDialog'
import useNotifications from 'hooks/useNotifications'
import businessService from 'services/business'
import styles from './AccessRequestApprovalForm.module.scss'


const AccessRequestApprovalForm = (props) => {
    const { className, errors, onSubmit } = props

    const { openErrorDialog } = useDialog()
    const { receivedEvent, eventNumber } = useNotifications()
    const [data, setData] = useState({})
    const [partners, setPartners] = useState(null)

    const handleChange = (event, result, isCheckbox) => {
        const { name, value, checked } = result || event.target
        setData({
            ...data,
            [name]: isCheckbox ? checked : (value || null)
        })
    }

    const fetchPartners = () => {
        businessService.getPartnersCatalogue()
            .then((res) => {
                const options = res.data.map((elem) => ({
                    key: elem.id,
                    value: elem.id,
                    text: `${elem.name}${elem.tax_id ? ` (${elem.tax_id})` : ''}`,
                }))
                setPartners(options)
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los asociados.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
    }

    useEffect(() => {
        fetchPartners()
    }, [])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.PARTNER_CREATED,
            eventCodes.PARTNER_DELETED,
            eventCodes.PARTNER_UPDATED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchPartners()
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <p>
                Estás a punto de aceptar la solicitud de acceso. Esta acción creará
                un usuario con acceso a la plataforma.
            </p>
            <Form
                id={ACCESS_REQUEST_APPROVAL_FORM_ID}
                className={`${styles.AccessRequestApprovalForm}${className ? ` ${className}` : ''}`}
                onSubmit={() => onSubmit(data)}
                error={!!errors}
            >
                <Form.Select
                    required
                    clearable
                    search
                    disabled={!partners}
                    loading={!partners}
                    options={partners || []}
                    error={!!errors?.partner}
                    label='Correduría'
                    placeholder='Seleccionar correduría'
                    name='partner'
                    value={data.partner || ''}
                    onChange={handleChange}
                />
                <Form.Checkbox
                    disabled={!partners}
                    name='is_chief'
                    checked={data.is_chief || false}
                    label={
                        <label>
                            <strong>
                                El usuario es el administrador de la correduría
                            </strong>
                        </label>
                    }
                    onChange={(e, r) => handleChange(e, r, true)}
                />
                {
                    errors &&
                    <Message error>
                        <Message.List>
                        {
                            Object.entries(errors).map(([key, value], index) => (
                                <Message.Item key={index}>
                                    {Array.isArray(value) ? value[0] : value}
                                </Message.Item>
                            ))
                        }
                        </Message.List>
                    </Message>
                }
            </Form>
        </>
    )
}


export default AccessRequestApprovalForm